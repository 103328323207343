import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { ListQueryResult } from 'src/services/types';
import DraggableReorderableVariableHeightList from 'src/components/draggable-reorderable-variable-height-list';
import { updateItemSequence } from 'src/lib/utils/draggable-list-utils';
import { ListsProps } from './user-lists';
import {
    EditableDraggableItem,
    ReadOnlyDraggableItem,
    EditableItem,
    ReadOnlyItem,
    ListInteractionType,
} from './list-items';

const useStyles = makeStyles({
    container: {
        marginTop: '1.5rem',
    },
    heading: {
        textAlign: 'left',
        marginLeft: '1.5rem',
        marginBottom: -15,
    },
});

function SharedLists({
    beginListEditing,
    beginListDeleting,
    updateListSequence,
    lists,
    editMode,
}: ListsProps) {
    const classes = useStyles();
    const mutateDraggedItem = (
        originalItems: ListQueryResult,
        destinationIndex: number
    ) =>
        updateItemSequence(
            originalItems,
            destinationIndex,
            (item: any, newSequenceNumber: number) =>
                updateListSequence(item.id, newSequenceNumber)
        );
    function itemInteractionHandler(
        item: any,
        interactionType: ListInteractionType
    ) {
        if (interactionType === 'edit-item' && beginListEditing) {
            beginListEditing(item.id, item.productListName, item.description);
        } else if (interactionType === 'delete-item' && beginListDeleting) {
            beginListDeleting(item.id, item.productListName);
        }
    }

    return (
        <>
            {lists && lists?.length > 0 && (
                <div className={classes.container}>
                    <Typography className={classes.heading} variant="h6">
                        Shared with me
                    </Typography>
                    <DraggableReorderableVariableHeightList
                        items={lists}
                        disabled={!editMode}
                        itemProps={{ itemInteractionHandler }}
                        Item={editMode ? EditableItem : ReadOnlyItem}
                        mutateDraggedItem={mutateDraggedItem}
                        DraggableItem={
                            editMode
                                ? EditableDraggableItem
                                : ReadOnlyDraggableItem
                        }
                    />
                </div>
            )}
        </>
    );
}

export default SharedLists;
