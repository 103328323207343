/* eslint-disable import/prefer-default-export */
import { Position } from 'src/lib/types';
import { validateZipCode } from '../staples-stores';

type GoogleGeolocationResponseBody = {
  location: {
    lat: number;
    lng: number;
  };
  accuracy: number;
};

/**
 * Uses IP Address to estimate latitude and longitude.
 *
 * Useful in the case where the user does not grant us location permissions.
 */
export async function getEstimatedLatLong(): Promise<Position | null> {
  // We cannot use our custom axios instance due to incompatible headers.
  // using fetch instead
  const geolocationInfo = await fetch(
    // eslint-disable-next-line max-len
    `https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY}`,
    { method: 'post', body: '{"considerIp":true}' }
  );
  if (geolocationInfo && geolocationInfo.body) {
    const body =
      (await geolocationInfo.json()) as GoogleGeolocationResponseBody;
    if (body?.location) {
      return {
        longitude: body.location.lng,
        latitude: body.location.lat,
      };
    }
  }
  return null;
}


const getCoordinates = async () => {
  try {
    if ('geolocation' in navigator) {
      const location: any = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject)
      })
      const { latitude, longitude } = location.coords
      return { latitude, longitude }
    }
    console.error('Geolocation not supported by this browser.')
    return null;
  } catch (error) {
    console.error("~ error:", error)
  }
  return null;
}

export async function getEstimatedZipCode(): Promise<any> {
  // const coordinates = await getEstimatedLatLong()
  const coordinates: any = await getCoordinates();
  if (coordinates) {
    let latlng = '';
    latlng += String(coordinates?.latitude).substring(0, 6);
    latlng += ',';
    latlng += String(coordinates?.longitude).substring(0, 6);
    const exactLocation = await fetch(
      // eslint-disable-next-line
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&key=${process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY}`,
      { method: 'post' }
    ).then(res => res.json()).then(async res => {
      const postalCode = res?.results?.find((x: any) => x.types[0] === "postal_code")
        ?.address_components?.find((y: any) => y.types
        [0] === 'postal_code')?.long_name || '';
      let isValidPostalCode = false;
      if (postalCode) {
        await validateZipCode(postalCode).then((data) => {
          if (data?.isValidZipCode) {
            isValidPostalCode = true;
          } else {
            isValidPostalCode = false;
          }
        });
      }
      if (isValidPostalCode) {
        return { postalCode, ...coordinates };
      }
      return null
    })
    console.log("🚀 ~ file: geolocation.ts:85 ~ getEstimatedZipCode ~ exactLocation:", exactLocation)
    return exactLocation;
  }
  return null;
}

/**
 * Uses the Google Geocoding API to determine zip code.
 * @param latitude e.g. 42.2591708
 * @param longitude e.g. -71.0141064
 * @returns an approximate zip code of the given lat/long values,
 * or null if there was an error
 */
export async function getZipCode(
  latitude: number | undefined,
  longitude: number | undefined
): Promise<string | null> {
  if (!latitude || !longitude) {
    return null;
  }
  // We cannot use our custom axios instance due to incompatible headers.
  // using fetch instead
  const locationInfo = await fetch(
    // eslint-disable-next-line max-len
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY}`
  );
  if (locationInfo) {
    const body = await locationInfo.json();
    if (body.results && body.results.length > 0) {
      const { address_components: addressComponents } = body.results[0];
      if (addressComponents && addressComponents.length > 0) {
        const possibleZipCodeAddressComponent = addressComponents.find(
          (a: any) => a.types && a.types.indexOf('postal_code') >= 0
        );
        if (possibleZipCodeAddressComponent) {
          const { long_name: possibleZipCode } =
            possibleZipCodeAddressComponent;
          if (possibleZipCode) {
            return possibleZipCode;
          }
        }
      }
    }
  }

  return null;
}
