/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import {
    getOrUpdateCurrentStore,
    getStores,
} from 'src/lib/api-client/staples-stores';
import { Store } from 'src/services/types';
import Log from 'src/lib/logging';
import { generatePlaceholderData } from 'src/lib/utils/placeholder-utils';
import { guestMode } from 'src/lib/utils/app-utils';
import Cookies from 'js-cookie';
import useUserInfo from './useUserInfo';
import useLocalUserInfo from './useLocalUserInfo';

const MAX_STORES = 5;
/** we should always prefer fetching by storeId if we have one */
function determineFetchingPreference(storeId?: string, zipCode?: string) {
    if (storeId) {
        return 'storeId';
    }
    if (zipCode) {
        return 'zipCode';
    }
    // we do not yet know our fething preference
    return null;
}

const ONE_MINUTE = 60000;

const placeholderStoresData = generatePlaceholderData(
    { id: '[UUID]' },
    MAX_STORES
);

/**
 * Returns commonly needed store data.
 * We gather store data in multiple ways
 * depending on what information we have on the user.
 */
export const SELECTED_STORE_OBJ_KEY = 'persistent_selectedStoreData';
export default function useStoreData() {
    // we currently get store id / zipcode from user info in local storage
    // this may be replaced with its own useQuery
    const { userInfo: localUserInfo, patchLocalUserInfo } = useLocalUserInfo();
    const { userInfo, setUserInfo } = useUserInfo();

    const [storeFindErrorMessage, setStoreFindErrorMessage] = useState('');
    const inferredZipCode =
        userInfo?.currentLocationZipCode || localUserInfo?.zipCode;
    const inferredStoreId =
        userInfo?.preferredStoreId || localUserInfo?.preferredStoreId;
    const [storeCounter, updateStoreCounter] = useState(0);
    const {
        data: mainStoreData,
        error: mainStoreError,
        isFetching: mainStoreIsFetching,
    } = useQuery(
        [
            'mainStore',
            inferredZipCode,
            inferredStoreId,
            userInfo?.preferredStoreId,
            storeCounter,
        ],
        async () =>
            getOrUpdateCurrentStore(inferredZipCode, inferredStoreId, userInfo),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: true,
            cacheTime: ONE_MINUTE,
            staleTime: ONE_MINUTE,
        }
    );

    const [mainStore, setMainStore] = useState<Store | undefined>(
        mainStoreData as Store
    );

    const [zipCodeForStoresQuery, setZipCodeForStoresQuery] =
        useState(inferredZipCode);
    useEffect(() => {
        setZipCodeForStoresQuery(
            mainStore?.address?.postal_code || zipCodeForStoresQuery
        );
    }, [inferredZipCode, mainStore?.address?.postal_code]);

    const {
        data: storesData,
        error: storesError,
        isFetching: storesFetching,
    } = useQuery(
        ['currentStores', zipCodeForStoresQuery],
        async () => getStores(zipCodeForStoresQuery),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            cacheTime: ONE_MINUTE,
            staleTime: ONE_MINUTE,
            enabled: !!mainStoreData,
        }
    );

    const [additionalStores, setAdditionalStores] = useState<Store[]>(
        (storesData || []) as Store[]
    );

    const setTheMainStoreDataToCookies = (storeData: Store) => {
        const storeDataChange = {
            address1: storeData?.address?.address_1,
            city: storeData?.address?.city,
            state: storeData?.address?.region,
            zipCode: storeData?.address?.postal_code,
            storeNumber: storeData?.storeNumber,
            persists: true,
            radius: '20mi',
        };

        const encodedStoreData: any = JSON.stringify(storeDataChange);
        Cookies.set('YourStore', encodedStoreData, {
            domain: process.env.REACT_APP_Domain,
        });
    };
    // assuming we're always fetching something initially
    const [isFetching, setIsFetching] = useState(true);
    useEffect(() => {
        setIsFetching(mainStoreIsFetching || storesFetching);
    }, [mainStoreIsFetching, storesFetching]);

    // process main store result
    useEffect(() => {
        const errorData: any = mainStoreData;
        if (mainStoreError || errorData === 'Processing Error') {
            Log.log('error fetching main store');
            Log.log(mainStoreError);
            if (errorData === 'Processing Error') {
                setStoreFindErrorMessage(errorData);
            }
        } else if (mainStoreData) {
            setMainStore(mainStoreData as Store);
            setTheMainStoreDataToCookies(mainStoreData);
        }
    }, [mainStoreData, mainStoreError]);

    // process stores results
    useEffect(() => {
        if (storesError) {
            Log.log('error fetching stores');
            Log.log(storesError);
        } else if (storesData && storesData.length > 0) {
            const stores = storesData as Store[];
            const fetchingPreference = determineFetchingPreference(
                inferredStoreId,
                inferredZipCode
            );
            if (mainStore || fetchingPreference !== 'zipCode') {
                setAdditionalStores(
                    stores
                        .filter((s) => s.id !== mainStore?.id)
                        .slice(0, MAX_STORES)
                );
            } else {
                // Only update main store if we're sure we don't have it
                setMainStore(stores[0]);
                setAdditionalStores(stores.slice(1, 1 + MAX_STORES));
            }
        }
    }, [
        mainStore,
        storesData,
        storesError,
        inferredZipCode,
        inferredStoreId,
        localUserInfo,
    ]);

    /** Updates the user info and re-triggers API calls (if needed) */
    function updateMainStore(store: Store) {
        const isGuestModeOn = guestMode();

        updateStoreCounter(storeCounter + 1);
        setMainStore(store);
        patchLocalUserInfo({ preferredStoreId: store.storeNumber });
        setUserInfo({
            preferredStoreId: store.storeNumber || inferredStoreId,
            currentLocationZipCode:
                store?.address?.postal_code || inferredZipCode,
        });

        if (isGuestModeOn) {
            patchLocalUserInfo({
                preferredStoreId: store.storeNumber,
                zipCode: store?.address?.postal_code,
            });
        }

        if (store.address.postal_code !== mainStore?.address?.postal_code) {
            setZipCodeForStoresQuery(store.address.postal_code);
            // setting zip code will trigger another load of additional stores if we need them
        }
    }

    useEffect(() => {
        if (mainStore) {
            localStorage.setItem(
                SELECTED_STORE_OBJ_KEY,
                JSON.stringify(mainStore)
            );
        }
    }, [mainStore]);

    return {
        isFetching,
        mainStoreId: inferredStoreId,
        mainStore,
        additionalStores,
        placeholderStoresData,
        error: mainStoreError || storesError,
        updateMainStore,
        storeFindErrorMessage,
    };
}
