import React, { useEffect, useState } from 'react';
import ScrollContainer from 'src/components/screen-scroll';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import { useToastMessageContext } from 'src/lib/contexts/toast-message-context';
import { useCreateImportListContext } from 'src/lib/contexts/create-import-list-context';
import { useQuery, useQueryClient } from 'react-query';
import ListCreateEditDialog from 'src/components/list-create-edit-dialog';
import LoadingOverlay from 'src/components/loading-overlay';
import useUserInfo from 'src/hooks/useUserInfo';
import {
    deleteList,
    getFeaturedLists,
    getUserLists,
    updateListSequence,
    getSharedLists,
} from 'src/lib/api-client/lists';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import IndividualList from 'src/screens/lists/individual-list/individual-list';
import ErrorDialog from 'src/components/error-dialog';
import ScreenWidth from 'src/components/screen-width';
import GenericDeleteItemDialog from 'src/components/generic-delete-item-dialog';
import { NO_REFETCH } from 'src/lib/utils/react-query-utils';
import useEventsReporter from 'src/hooks/useEventsReporter';
import ROUTES from 'src/lib/routes';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useRouter from 'src/hooks/useRouter';
import { ListResult } from 'src/services/types';
import UserList from './user-lists';
import FeaturedList from './featured-list';
import SharedItemsList from './shared-lists';
import { ListModalMode, ListMode } from './list.types';
import ListNavTop from './list-nav-top';

/** responsible for the baseline common data fetching */
function ListsBase() {
    const {
        userListUpdateCount,
        featuredListUpdateCount,
        notifyUserListUpdate,
    } = useUserUpdateContext();
    const queryClient = useQueryClient();
    const { userInfo, isFetchingUserInfo } = useUserInfo();
    const { push: goto } = useHistory();
    const { pathname } = useRouter();
    const { url } = useRouteMatch();
    const { data: lists, isFetching } = useQuery(
        ['getUserLists', userListUpdateCount],
        () => getUserLists(),
        { ...NO_REFETCH }
    );
    const { data: featuredLists } = useQuery(
        ['getFeaturedLists', featuredListUpdateCount],
        getFeaturedLists
    );
    const { data: sharedLists } = useQuery(['getSharedLists'], getSharedLists);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [listMode, setListMode] = useState<ListModalMode>({
        mode: null,
    });
    const [error, setError] = useState<string | null>();
    const { setToastMessage } = useToastMessageContext();
    const { customEvent, analyticsScreenCustomNames } = useEventsReporter();
    useEffect(() => {
        if (pathname === ROUTES.lists) {
            notifyUserListUpdate();
        }
    }, [pathname]);

    useEffect(() => {
        if (userInfo?.rewardsNumber) {
            analyticsScreenCustomNames({
                rewards_number: userInfo?.rewardsNumber,
            });
        } else {
            analyticsScreenCustomNames({
                rewards_number: '',
            });
        }
        if (userInfo?.preferredStoreId) {
            analyticsScreenCustomNames({
                store_id: userInfo?.preferredStoreId,
            });
        } else {
            analyticsScreenCustomNames({
                store_id: '',
            });
        }
    }, []);

    function createNewList() {
        setListMode({
            mode: 'create',
            editDetails: undefined,
        });
    }
    function beginListEditing(
        listId: number,
        listName: string,
        listDescription?: string
    ) {
        setListMode({
            mode: 'edit',
            editDetails: {
                listId,
                listName,
                listDescription,
            },
        });
    }
    function beginListDeleting(listId: number, listName: string) {
        setListMode({
            mode: 'delete',
            editDetails: {
                listId,
                listName,
            },
        });
    }
    const { goToPage } = useCreateImportListContext();

    function onComplete(success: boolean, mode: ListMode, data?: ListResult) {
        if (success) {
            queryClient.invalidateQueries('getUserLists');
            queryClient.invalidateQueries('getFeaturedLists');
            queryClient.invalidateQueries('getSharedLists');

            if (mode === 'create') {
                setToastMessage({ title: 'List created!' });
                if (data?.id) {
                    goto(`${url}/${data.id}`, {
                        newList: true,
                    });
                }
            } else if (mode === 'edit') {
                setToastMessage({
                    title: 'List rename successful!',
                    details: 'Your list has been renamed.',
                });
            }
            customEvent(eventMetricsMap.list_created);
            setListMode({
                mode: null,
            });
            setError(null);
        } else {
            setError(mode);
        }
    }

    function importList() {
        goToPage({ type: 'select-school' });
    }
    function handleListModalClose() {
        setListMode((state) => ({ ...state, mode: null }));
    }
    function handleErrorClose() {
        setError(null);
    }

    const [listSequenceUpdates, setListSequenceUpdates] = useState<any[]>([]);
    function pushListUpdate(newUpdate: any) {
        setListSequenceUpdates((currentUpdates) => [
            ...currentUpdates,
            newUpdate,
        ]);
    }
    function removeOldestListUpdate() {
        setListSequenceUpdates((currentUpdates) => currentUpdates.slice(1));
    }
    useQuery(
        [
            'updateListSequence',
            listSequenceUpdates?.length ? listSequenceUpdates[0] : null,
        ],
        () => {
            if (listSequenceUpdates[0]) {
                const { sequence, id } = listSequenceUpdates[0];
                return updateListSequence({
                    id,
                    sequence,
                });
            }
            return null;
        },
        {
            enabled: listSequenceUpdates.length > 0,
            onSuccess: () => {
                removeOldestListUpdate();
            },
        }
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function queueListSequenceUpdate(listId: number, sequence: number) {
        pushListUpdate({ id: listId, sequence });
        // setLists((oldLists) =>
        //   (oldLists || []).map((l) => {
        //     if (l.id === listId) {
        //       return { ...l, sequence };
        //     }
        //     return l;
        //   })
        // );
    }

    const [listIdtoDelete, setListToDelete] = useState<any>(undefined);
    const { isFetching: deleteListIsFetching } = useQuery(
        ['deleteList', listIdtoDelete],
        () => deleteList({ listId: listIdtoDelete }),
        {
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            enabled: !!listIdtoDelete,
            onError: () => {
                onComplete(false, 'delete');
            },
            onSuccess: () => {
                onComplete(true, 'delete');
            },
        }
    );
    function handleDeleteList() {
        setListToDelete(listMode.editDetails?.listId);
    }

    if (isFetching) {
        return (
            <LoadingOverlay variant="wordmark" theme="transparent-light" open />
        );
    }
    const lowestValue: number =
        lists && lists?.length > 0
            ? (listSequenceUpdates.reduce(
                  (lastLowest, u) =>
                      u?.sequence && u?.sequence < lastLowest
                          ? u.sequence
                          : lastLowest,
                  lists[0].sequence as number
              ) as number)
            : 0;
    const listLength =
        lists && sharedLists ? lists?.length + sharedLists?.length : 0;
    const noListsView = !(
        lists?.length ||
        sharedLists?.length ||
        featuredLists?.length
    );
    return (
        <ScreenWidth auto grow>
            <ListNavTop
                disableModeToggle={!!listSequenceUpdates.length}
                importList={importList}
                createNewList={createNewList}
                editMode={editMode}
                setEditMode={setEditMode}
                noListsView={noListsView}
                userListLength={lists?.length || 0}
                totalListLength={listLength}
            />
            {lists && (
                <UserList
                    lists={lists}
                    beginListEditing={beginListEditing}
                    beginListDeleting={beginListDeleting}
                    updateListSequence={queueListSequenceUpdate}
                    editMode={editMode}
                />
            )}
            {sharedLists && (
                <SharedItemsList
                    lists={sharedLists}
                    beginListEditing={beginListEditing}
                    beginListDeleting={beginListDeleting}
                    updateListSequence={queueListSequenceUpdate}
                    editMode={editMode}
                />
            )}
            {featuredLists && <FeaturedList lists={featuredLists} />}
            <ListCreateEditDialog
                onBasePage
                open={
                    !!(listLength > 0 && listMode.mode === 'edit') ||
                    listMode.mode === 'create'
                }
                mode={listMode.mode}
                editDetails={listMode.editDetails}
                handleClose={handleListModalClose}
                onComplete={onComplete}
                lowestValue={lowestValue}
            />
            <ErrorDialog open={!!error} handleClose={handleErrorClose} />
            <GenericDeleteItemDialog
                topTitle="Remove list?"
                open={listMode.mode === 'delete'}
                title={listMode?.editDetails?.listName || ''}
                iconSrc={require('../../assets/icons/iconLists.svg')}
                handleClose={handleListModalClose}
                handlePrimaryBtnClick={handleDeleteList}
                handleSecondaryBtnClick={handleListModalClose}
                primaryBtnLoading={deleteListIsFetching && !!listIdtoDelete}
            />
        </ScreenWidth>
    );
}

const ListBaseRouter = () => {
    const match = useRouteMatch();
    const { replace } = useHistory();
    const goBack = () => replace(ROUTES.lists);
    return (
        <Switch>
            <Route path={`${match.path}/:listId`}>
                <IndividualList onSave={goBack} onBack={goBack} />
            </Route>
            <Route path={match.path}>
                <>
                    <ScrollContainer>
                        <ListsBase />
                    </ScrollContainer>
                </>
            </Route>
        </Switch>
    );
};

// The combination of react-oidc and our routes is somehow throwing an error
// saying that we are not in a function component and trying to declare hooks.
// Wrapping in one more functional component appears to fix it.
const WrappedListsBase = () => <ListBaseRouter />;
export default WrappedListsBase;
