/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { SwipeableDrawer, Typography } from '@material-ui/core';
import useUserInfo from 'src/hooks/useUserInfo';
import useCardCanvas from 'src/hooks/useCardCanvas';
import { getCustomerPriceTypes } from 'src/lib/api-client/wallet-activities';
import { Body } from 'src/components/UI';
import { NO_REFETCH } from 'src/lib/utils/react-query-utils';
import { Player } from '@lottiefiles/react-lottie-player';
import { IconEasyRewardsHorizontal } from 'src/components/Icons';
import styles from './styles.module.scss';


interface UserRewardsDigitalCardDrawerProps {
    open: boolean;
    toggleOpen: (value: boolean) => void;
    rewardstiercode: string;
}


function UserRewardsDigitalCardDrawer({
    open,
    toggleOpen,
    rewardstiercode,
}: UserRewardsDigitalCardDrawerProps) {
    const { userInfo } = useUserInfo();
    const { canvasRef } = useCardCanvas(userInfo?.rewardsNumber);
    const { data: customerPriceTypeData } = useQuery(
        ['CustomerPriceTypes', userInfo?.rewardsNumber],
        () => getCustomerPriceTypes(userInfo?.rewardsNumber),
        {
            ...NO_REFETCH,
            enabled: !!userInfo?.rewardsNumber,
        }
    );

    return (
        <SwipeableDrawer
            anchor="bottom"
            open={open}
            onClose={() => toggleOpen(false)}
            onOpen={() => toggleOpen(true)}
        >
            <div className={styles.contentWrapper}>
            <div  role="button" aria-label="Close drawer"  className={styles.indicator} />
                <Body className={styles.title}>
                    <span tabIndex={0} aria-label="Easy Rewards digital card" style={{textAlign: 'center', margin: "13px 0px -9px 0px" }}>
                        <IconEasyRewardsHorizontal
                            width={110}
                            height={25}
                            fillColor="fillRed"
                        />  
                    </span>  
                    <b tabIndex={0} aria-label={`Your Easy Rewards # is ${userInfo?.rewardsNumber}`}className={styles.rewardsNumber}>
                        {userInfo?.rewardsNumber}
                    </b>
                </Body>
                <canvas tabIndex={0} aria-label="Your personal Easy Rewards barcode is here to scan at checkout when you are in a Staples store"
                ref={canvasRef} className={styles.renderedCardNumber} />

                <div className={styles.container}>
                    <Body tabIndex={0} aria-label={`${userInfo?.firstName} ${userInfo?.lastName}`} className={styles.memberName} size={26} weight='bold' margin='30px 0px 15px 0'>
                        {userInfo?.firstName} {userInfo?.lastName}
                    </Body>
                    {customerPriceTypeData?.data?.customerPriceType ===
                        'Chamber' ? (
                        <Body tabIndex={0} aria-label="You are a CHAMBER MEMBER" size={16} weight='bold' margin="0 0 38px 0" className={styles.memberTypeContainer}>
                            CHAMBER MEMBER
                        </Body>
                    ) : (
                        <Body tabIndex={0} size={16} weight='bold' margin="0 0 38px 0" className={styles.memberTypeContainer}>
                            {userInfo?.rewardsMemberType === 'Associate' || rewardstiercode === 'ARW'
                                ? 
                                <>
                                    <img
                                    src={require("../../../assets/icons/memberStaplesLogo.svg")}
                                    alt={`You are a ${userInfo?.rewardsMemberType === 'Associate' || rewardstiercode === 'ARW'
                                    ? 'VALUED ASSOCIATE' : 'MEMBER' }`} 
                                    className={styles.memberNameIcon}
                                    />
                                    {'VALUED ASSOCIATE'}
                                </>
                                : 'MEMBER' }
                        </Body>
                    )}
                    <Body tabIndex={0} aria-label="How do I use my card?" className={styles.subtitle} size={13} margin='12px 0 25px 0'>
                        How do I use my card? 
                        <a
                            tabIndex={0}
                            aria-label="Learn more"
                            href="https://www.staples.com/grs/rewards/programoverview"
                            className={styles.learnMore}
                        >
                            Learn more
                        </a>
                    </Body>
                    <Player autoplay loop style={{ marginRight: '-4px' }}
                        className={styles.cardCornerPill} src={require('../../../assets/lotties/rewards-enrollment/sm_digital_card_corner_pills.json')} />
                </div>
                {userInfo?.rewardsMemberType === 'Associate' && (
                    <Body className={styles.benefitWrapper}>
                        <a
                            href="https://associateconnection.staples.com/psp/psext/EXTRANET2/ASSOCIATE/c/STP_EXTR_LINK_MENU.STP_RWD_ASSOC_RWRD.GBL?Page=STP_RWD_API_EMPLOY&Action=U"
                            className={styles.benefitText}
                        >
                            View my associate benefits
                        </a>
                    </Body>
                )}
            </div>
        </SwipeableDrawer>
    );
}

export default UserRewardsDigitalCardDrawer;

