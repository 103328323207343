const COLORS = {
    textBlack: '#000',
    secondaryNeutralBlack: '#101820',
    secondaryNeutralCharcoal: '#444444',
    secondaryNeutralLightGray: '#CCCCCC',
    onboardingTextGray: '#D9D9D6',
    greyMetallic: '#333333',
    gray80: '#CCCCCC', // TODO: replace gray80 with secondaryNeutralLightGray
    brandRed: '#CC0000',
    brandRedOpacity: 'rgba(204, 0, 0, 0.8)',
    vividRed: '#FF3B30',
    darkRed: '#9E0000',
    brandCyan: '#086DD2',
    primaryWhite: '#FFF',
    primaryWhitePartialOpacity: 'rgba(225, 225, 225, 0.75)',
    transparentPrimaryWhite: 'rgba(225, 225, 225, 0)',
    whiteSmoke: '#f2f2f2', // used for backgrounds
    whiteSmokeTransparent: 'rgba(242, 242, 242, 0)', // used for gradients
    rewardsYellow: '#ffc916',
    homeBlack: '#0D1812',
    fordGray: '#979797',
    disabledBackgroundGray: '#00000033',
    disabledTextGray: '#ffc916',
    successGreen: '#34c759',
    whiteBackgroundWithOpacity: '#ffffffc2',
    linkText: '#086dd2',
    purple: '#5b0069',
    lightGrey: '#f1f1f2',
    darkGray: '#343434',
};

export default COLORS;
