/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import NavTop from 'src/components/nav-top';
import { Box, InputAdornment } from '@material-ui/core';
import FullWidthCard from 'src/components/cards/full-width-card';
import clsx from 'clsx';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useUserLoginUpdateContextState } from 'src/lib/contexts/user-login-context';
import ValidationTextField from 'src/components/validation-text-field';
import LoadingButton from 'src/components/loading-button';
import EmailAutoComplete from 'react-autocomplete-email';
import { SessionManager } from 'src/lib/api-client/sessionManager';
import { v4 as uuidv4 } from 'uuid';
import { loginUser } from 'src/lib/api-client/sign-on';
import { atom, useAtom } from 'jotai';
import {
    IconPasswordClose,
    IconPasswordOpen,
    IconStaplesLogo,
} from 'src/components/Icons';
import { Button, Body, Heading } from 'src/components/UI';
import { COACH_SCREENS_ROUTES, LOGIN_ROUTES } from 'src/routers/routes';
import {
    useRouter,
    hardReplace,
    useUserState,
    eventMetricsMap,
    useEventsReporter, useStoreData,
} from 'src/hooks';
import ROUTES from 'src/lib/routes';
import CommonDialog from 'src/components/common-dialog';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import Captcha from 'src/components/login-flow/captcha';
import { obfuscateUserEmail } from 'src/utils/userUtils';
// import TermCondition from './TermCondition';
import useAdobeAnalytics from 'src/hooks/use-adobe-analytics';
import { useCartCountContext } from 'src/lib/contexts/cart-count-context';
import { mergeCartDetails } from 'src/lib/api-client/cart-api';
import styles from './styles.module.scss';
import ShopAsGuest from './ShopAsGuest';

const customDomains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com'];
//  eslint-disable-next-line max-len
const passwordReg =
    /(?=.{8,50})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/;
const passwordRegxValidationMsg =
    '8 characters minimum, case sensitive, no spaces and a special character or number.';
const validationSchemaFields = yup.object({
    email: yup
        .string()
        .required('This field is required')
        .min(1, 'Username/email must contain at least 1 character.'),
    password: yup
        .string()
        .required('Password is a required field.')
        .min(8, 'Password must contain at least 8 characters.'),
});

const payload = { value: '', type: '' };

function AccountValid({ showInModal, setNextScreen, postLoginBehavior }: any) {
    const { replace } = useRouter();
    const [isLoading, setLoading] = useState(false);
    const initialRef: any = null;
    const [userState, setUserState] = useUserState();
    const { analyticsCustomEvent, analyticsScreenCustomNames } =
        useEventsReporter();

    const { push } = useRouter();
    const { mainStore } = useStoreData();

    const emailAutoCompleteRef = useRef(initialRef);
    const { errorMessage } = useUserLoginUpdateContextState();
    // const { userInfo, isFetchingUserInfo } = useUserInfo();
    const [isPasswordShow, setPasswordShow] = useState(false);
    const [captchaHtml, setCaptchaHtml] = useState('');
    const { activeIframeId } = useCartCountContext();

    const [nuSessionId] = useState(uuidv4());
    const [loginErrorMessage, setLoginErrorMessage] = useState(
        errorMessage || ''
    );
    const { setLoginCaptchaResData, loginCaptchaRes } = useUserUpdateContext();
    const { loginFailedDataToAdobe, loginSuccessDataToAdobe } =
        useAdobeAnalytics();

    const [attemptedAccountCredential, setAttemptedAccountCredential] =
        useState<any>(payload);

    const formikFieldValue = useFormik({
        initialValues: {
            email: userState?.email || '',
            password: '',
        },
        validationSchema: validationSchemaFields,
        validateOnMount: true,
        onSubmit: (values) => {
            setAttemptedAccountCredential(payload);
            // eslint-disable-next-line
            login();
        },
    });
    const [userEmailId, setUserEmailId] = useState(
        formikFieldValue.values.email
    );
    const { handleSubmit } = formikFieldValue;
    const emailInputHasError =
        formikFieldValue.touched.email &&
        Boolean(formikFieldValue.errors.email);

    useEffect(() => {
        if (loginCaptchaRes?.email) {
            formikFieldValue.setValues({
                email: loginCaptchaRes?.email,
                password: loginCaptchaRes?.key,
            });
            setCaptchaHtml(loginCaptchaRes?.captcha);
            setLoginCaptchaResData({
                email: '',
                key: '',
                captcha: '',
            });
        }
    }, [loginCaptchaRes]);

    const handleForgotPassword = () => {
        const pathname = '/forgot-pwd';
        if (userState?.email) {
            localStorage.setItem('pwdEmail', userState?.email);
        }
        if (showInModal) {
            setNextScreen(pathname);
        } else {
            push(pathname);
        }
        analyticsCustomEvent(eventMetricsMap.sign_in_page, {
            click_text: 'forgot password',
            element_location: 'account sign-in',
        });
    };
    const handleOnBlur = (e: string) => {
        if (loginErrorMessage) {
            setLoginErrorMessage('');
        }
        if (e) {
            formikFieldValue.setTouched({
                ...formikFieldValue.touched,
                [e]: true,
            });
        }
    };
    const refreshWebView = () => {
        const documentData = document?.getElementById?.(
            activeIframeId
        ) as HTMLIFrameElement;
        if (activeIframeId && documentData) {
            documentData?.contentWindow?.location?.reload();
        }
    };
    const login = async () => {
        const { email, password } = formikFieldValue.values;
        const stplSessionId = nuSessionId;
        const nPayload = (
            document?.getElementsByName('nds-pmd')[0] as HTMLInputElement
        )?.value;

        const nuCaptchaAnswer = (document.getElementById(
            'nucaptcha-answer'
        ) as HTMLInputElement)
            ? (document.getElementById('nucaptcha-answer') as HTMLInputElement)
                .value
            : '';
        const nuCaptchaToken = (document.getElementById(
            'nucaptcha-token'
        ) as HTMLInputElement)
            ? (document.getElementById('nucaptcha-token') as HTMLInputElement)
                .value
            : '';
        if (!email || !password) {
            return null;
        }
        setLoading(true);
        SessionManager.session?.killSession();

        const response: any = await loginUser({
            userName: email,
            key: password,
            rememberMe: true,
            payload: nPayload,
            nuCaptchaAnswer,
            nuCaptchaToken,
            stplSessionId,
        });
        setLoading(false);
        const captchaInRes =
            response?.data?.captchaResponse?.captchaResponseHtml;
        if (captchaInRes) {
            setLoading(false);
            return setCaptchaHtml(
                response?.data?.captchaResponse?.captchaResponseHtml
            );
        }
        if (!response.status) {
            if (response.message === 'User Not Migrated') {
                // router.history.push(ROUTES.passwordReset);
                return null;
            }
            analyticsCustomEvent(eventMetricsMap.sign_in_page, {
                click_text: 'Login - Failure',
                element_location: 'account sign-in',
            });
            setLoginErrorMessage(response.message || 'Unable to log in.Please try again');
            loginFailedDataToAdobe(
                response?.error?.response?.status,
                response?.message
            );
        } else if (response.status) {
            analyticsCustomEvent(eventMetricsMap.sign_in_page, {
                click_text: 'Login - Success',
                element_location: 'account sign-in',
            });
            analyticsScreenCustomNames({
                logged_in: true,
            });
            analyticsScreenCustomNames({
                user_id: response?.data?.sub,
            });
            loginSuccessDataToAdobe();
            sessionStorage.removeItem('guest');
            if (showInModal) {
                await mergeCartDetails();
                if (postLoginBehavior === 'go-to-cart') {
                    hardReplace(ROUTES.cart);
                } else if (postLoginBehavior === 'onboarding') {
                    replace(COACH_SCREENS_ROUTES.location);
                } else if (activeIframeId) {
                    refreshWebView();
                }
                setNextScreen?.('closeInModal');
            } else {
                hardReplace(COACH_SCREENS_ROUTES.location);
                setNextScreen?.('closeInModal');
            }
        }
        return null;
    };
    const editEmail = () => {
        if (!showInModal) {
            replace(LOGIN_ROUTES.lookup);
        } else {
            setNextScreen(LOGIN_ROUTES.lookup);
        }
    };

    const TextMaskCustom = (props: any) => {
        const { ...other } = props;
        return (
            <p
                className={`${styles.maskValWidth} ${other?.value && styles.maskVal
                    }`}
            >
                {obfuscateUserEmail(other?.value)}
            </p>
        );
    };

    return (
        <Box
            className={styles.screen}
            style={{
                justifyContent: 'flex-start',
                height: showInModal ? '85vh' : 'auto',
            }}
        >
            <NavTop
                minHeight="17rem"
                bgImageSrc={require('../../assets/bgs/loginBg.svg')}
                id="login-screen"
            >
                <div className={styles.headerLogoWrapper}>
                    <IconStaplesLogo />
                    <Heading
                        type="h3"
                        size={26}
                        weight="bold"
                        className={styles.headerSubTitle}
                    >
                        Ready to explore
                    </Heading>
                </div>
            </NavTop>
            <FullWidthCard
                cardContainerClassName={styles.accountContainer}
                noPadding
                id="user-found-screen"
            >
                <div className={styles.confirmContainer}>
                    <Heading
                        size={18}
                        weight="bold"
                        type="h6"
                        className={styles.title}
                    >
                        Sign in
                    </Heading>
                    <form
                        id="signInForm"
                        onSubmit={handleSubmit}
                        className={styles.form}
                    >
                        <Box onClick={editEmail}>
                            <ValidationTextField
                                fullWidth
                                id="email"
                                name="email"
                                label="Email / Username"
                                classes={{
                                    root: clsx(
                                        styles.textFieldLabel,
                                        emailInputHasError &&
                                        styles.textFieldLabelError
                                    ),
                                }}
                                disabled
                                value={formikFieldValue.values.email}
                                InputProps={{
                                    inputComponent: TextMaskCustom,
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            {/* eslint-disable-next-line jsx-a11y/aria-props */}
                                            <button
                                                type="button"
                                                aria-label="Edit"
                                                aria-describedby="Edit email or username"
                                                className={styles.accessibleBtn}
                                            >
                                                <img
                                                    src={require('../../assets/icons/iconEditNew.svg')}
                                                    alt="icon"
                                                />
                                            </button>
                                        </InputAdornment>
                                    ),
                                    classes: {
                                        underline: styles.disableEmailTextField,
                                    },
                                }}
                            />
                        </Box>
                        <div className={styles.textImageContainer}>
                            <ValidationTextField
                                fullWidth
                                id="password"
                                name="password"
                                type={!isPasswordShow ? 'password' : 'text'}
                                label="Password"
                                aria-label="Password"
                                autoComplete="current-password"
                                classes={{
                                    root: clsx(
                                        styles.textFieldLabel,
                                        formikFieldValue.touched.password &&
                                        formikFieldValue.errors.password &&
                                        styles.textFieldLabelError
                                    ),
                                }}
                                value={formikFieldValue.values.password}
                                onChange={formikFieldValue.handleChange}
                                onBlur={() => handleOnBlur('password')}
                                onFocus={() => handleOnBlur('password')}
                                error={
                                    formikFieldValue.touched.password &&
                                    !!formikFieldValue.errors.password
                                }
                                helperText={
                                    formikFieldValue.touched.password &&
                                    formikFieldValue.errors.password
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button
                                                ariaLabel="Show password"
                                                onClick={() =>
                                                    setPasswordShow(
                                                        !isPasswordShow
                                                    )
                                                }
                                                variant="icon"
                                            >
                                                {isPasswordShow ? (
                                                    <IconPasswordOpen />
                                                ) : (
                                                    <IconPasswordClose />
                                                )}
                                            </Button>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <Button
                            className={styles.mt2}
                            align="alignSelfStart"
                            variant="text"
                            color="textLinkBlue"
                            onClick={handleForgotPassword}
                            ariaLabel="Don't know your password ?"
                        >
                            <Body size={14} weight="normal">
                                Don’t know your password?
                            </Body>
                        </Button>
                        <LoadingButton
                            id="loginButton"
                            variant="contained"
                            fullWidth
                            loading={isLoading}
                            classes={{
                                root: clsx(styles.loginButton),
                            }}
                            type="submit"
                            disabled={
                                formikFieldValue.errors &&
                                Object.keys(formikFieldValue.errors).length !==
                                0
                            }
                            ariaLabel="Sign in"
                        >
                            Sign in
                        </LoadingButton>
                    </form>
                    {!showInModal && <ShopAsGuest referrer="Sign In" />}
                </div>
            </FullWidthCard>
            <Captcha
                capthaHtml={captchaHtml}
                sessionId={nuSessionId}
                onCaptchaSumbit={login}
                placement="LoginWebSC"
            />
            <CommonDialog
                open={!!loginErrorMessage}
                onClose={() => setLoginErrorMessage('')}
                title="We couldn't sign you in"
                message={loginErrorMessage}
                buttonText="Try again"
            />
        </Box>
    );
}

export default AccountValid;
