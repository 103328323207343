/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
// login-create-new-user.jsx

import React, { ChangeEvent, useRef, useState, FocusEvent } from 'react';
import {
    Tabs,
    Checkbox,
    Tab as MUITab,
    withStyles,
    InputAdornment,
    Box,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import LoadingButton from 'src/components/loading-button';
import { Button, Heading, Body, SwipeableDrawer } from 'src/components/UI';
import {
    IconRightBackArrow,
    IconPasswordClose,
    IconPasswordOpen,
    IconVector,
} from 'src/components/Icons';
import { LOGIN_CREATE_NEW_USER } from 'src/utils/stringConstants';
import { createAccount } from 'src/lib/api-client/sign-on';
import {
    useRouter,
    useUserState,
    useEventsReporter,
    eventMetricsMap,
} from 'src/hooks';
import { COACH_SCREENS_ROUTES, LOGIN_ROUTES } from 'src/routers/routes';
import InfoButton from 'src/components/info-button';
import { obfuscateUserEmail } from 'src/utils/userUtils';
import Captcha from 'src/components/login-flow/captcha';
import { v4 as uuidv4 } from 'uuid';
import { mergeCartDetails } from 'src/lib/api-client/cart-api';
import { useCartCountContext } from 'src/lib/contexts/cart-count-context';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { customDomains } from 'src/lib/constants';
import ROUTES from 'src/lib/routes';
import FullWidthCard from '../../components/cards/full-width-card';
import ValidationTextField from '../../components/validation-text-field';
import LoadingOverlay from '../../components/loading-overlay';
import styles from './styles.module.scss';

const Tab = withStyles(() => ({
    root: {
        fontSize: 18,
        textTransform: 'none',
        fontFamily: 'StaplesNormsRegular',
        '&$selected': {
            fontFamily: 'StaplesNormsBold',
        },
    },
    selected: {},
}))((props: { label: string }) => <MUITab {...props} />);

//  eslint-disable-next-line max-len,no-useless-escape
const passwordReg =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\-])\S{8,}$/;

const phoneRegex = /^\(\d{3}\)\s\d{3}\s\d{4}/;

const passwordRegxValidationMsg =
    '8 characters minimum, a letter, a special character, and a number. No spaces.';

const validationSchema = yup.object({
    firstName: yup.string()
        .required('First name is a required field.')
        .matches(/^[^\s]*$/, 'First name cannot contain spaces.'),
    lastName: yup.string()
        .required('Last name is a required field.')
        .matches(/^[^\s]*$/, 'Last name cannot contain spaces.'),
    phoneNumber: yup
        .string()
        .required('Phone number is a required field.')
        .length(10, 'Please enter a valid phone number.'),
    // .matches(phoneRegex, 'Please enter valid phone number'),
    email: yup
        .string()
        .required('Email is a required field.')
        .email('Enter a valid email address.'),
    confirmEmail: yup
        .string()
        .required('Email is a required field.')
        .email('Enter a valid email address.')
        .transform((value) => value.toLocaleLowerCase())
        .oneOf([yup.ref('email'), null], 'Email does not match.'),
    password: yup
        .string()
        .required('Password is a required field.')
        .matches(passwordReg, passwordRegxValidationMsg),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords do not match.'),
});

const CreateAccountScreen = ({
    showInModal,
    setNextScreen,
    postLoginBehavior,
}: any) => {
    const { push, replace } = useRouter();
    const [userState, setUserState] = useUserState();
    const [tosSource, setTosSource] = useState<string | null>(null);
    const [tosTabIndex, setTosTabIndex] = useState(0);
    const [createUserError, setCreateuserError] = useState('');
    const [isLoading, setLoading] = useState(false);
    const initialRef: any = null;
    const emailAutoCompleteRef = useRef(initialRef);
    const confirmEmailAutoCompleteRef = useRef(initialRef);
    const [isPasswordShow, setPasswordShow] = useState(false);
    const [isTerms, setTermsChecked] = useState(false);
    const [showTabs, setShowTabs] = useState(false);
    const [captchaHtml, setCaptchaHtml] = useState('');
    const [fieldFocus, setFieldFoucs] = useState('');
    const [nuSessionId] = useState(uuidv4());
    const { activeIframeId } = useCartCountContext();
    const { analyticsCustomEvent, analyticsScreenCustomNames } =
        useEventsReporter();
    const { setLoginCaptchaResData } = useUserUpdateContext();
    const [showList, setShowList] = useState(false);
    const [queryText, setQuery] = useState('');

    const ariaDetails = {
        tabIndexText: 0,
        ariaSelectedText: true,
        ariaLabelText:
            "We'll use your phone number to help verify your account in the future.",
        tabIndexButton: 1,
        ariaSelectedButton: true,
        ariaLabelButton: 'Got it',
    };
    const handleCloseBtn = () => {
        if (!showInModal) {
            push(LOGIN_ROUTES.lookup);
        } else {
            setNextScreen(LOGIN_ROUTES.lookup);
        }
    };

    const refreshWebView = () => {
        const documentData = document?.getElementById?.(
            activeIframeId
        ) as HTMLIFrameElement;
        if (activeIframeId && documentData) {
            documentData?.contentWindow?.location?.reload();
        }
    };

    const { values, handleSubmit, handleChange, errors, touched, setTouched } =
        useFormik({
            initialValues: {
                firstName: '',
                lastName: '',
                email: userState?.email?.toLocaleLowerCase() || '',
                confirmEmail: '',
                password: '',
                confirmPassword: '',
                phoneNumber: '',
                shopFor: 'self',
                organization: '',
            },
            validationSchema,
            // enableReinitialize: true,
            validateOnMount: true,
            onSubmit: async (currentValues) => {
                const stplSessionId = nuSessionId;
                const nPayload = (
                    document?.getElementsByName(
                        'nds-pmd'
                    )[0] as HTMLInputElement
                )?.value;

                const nuCaptchaAnswer = (document.getElementById(
                    'nucaptcha-answer'
                ) as HTMLInputElement)
                    ? (
                        document.getElementById(
                            'nucaptcha-answer'
                        ) as HTMLInputElement
                    ).value
                    : '';
                const nuCaptchaToken = (document.getElementById(
                    'nucaptcha-token'
                ) as HTMLInputElement)
                    ? (
                        document.getElementById(
                            'nucaptcha-token'
                        ) as HTMLInputElement
                    ).value
                    : '';

                // patchLocalUserInfo({ zipCode: '' })
                console.log(currentValues);
                setLoading(true);

                const requestBody = {
                    user: {
                        username: currentValues.email,
                        contact: {
                            email: currentValues.email,
                            firstName: currentValues.firstName,
                            lastName: currentValues.lastName,
                            phone2: currentValues.phoneNumber,
                        },
                        tenant: 'StaplesDotCom',
                        nudataPayload: '',
                        requestUrl: 'https://app.staplesconnect.com/',
                        stplSessionId: uuidv4(),
                    },
                    tenant: 'StaplesDotCom',
                    nudataPayload: '',
                    password: currentValues.password,
                    requestUrl: 'app/createAccount',
                    accountType: 'consumer',
                    stplSessionId: uuidv4(),
                    placement: 'CreateAccountSC',
                    channel: 'SWeb',
                    captchaAnswer: nuCaptchaAnswer,
                    nuCaptchaToken,
                    enrollOrigin: 'MOBILE',
                };

                const responseData: any = await createAccount(
                    requestBody,
                    setLoading
                );
                setLoading(false);
                console.log(responseData);
                console.log('🚀 ~ onSubmit: ~ responseData:', responseData);
                const captchaInRes =
                    responseData?.data?.captchaResponse?.captchaResponseHtml;
                if (captchaInRes) {
                    setCaptchaHtml(
                        responseData?.data?.captchaResponse?.captchaResponseHtml
                    );
                }
                if (responseData.status) {
                    analyticsCustomEvent(eventMetricsMap.account_creation, {
                        click_text: 'create account - success',
                        element_location: 'create an account',
                    });
                    analyticsScreenCustomNames({
                        user_id: responseData?.loginRes?.data?.sub,
                    });
                    setUserState({
                        ...userState,
                        userType: 'new',
                    });

                    if (
                        responseData?.loginRes?.captchaResponse
                            ?.captchaResponseHtml
                    ) {
                        const loginRes = {
                            email: currentValues.email,
                            key: currentValues.password,
                            captcha:
                                responseData?.loginRes?.captchaResponse
                                    ?.captchaResponseHtml,
                        };
                        setLoginCaptchaResData(loginRes);
                        setUserState({
                            ...userState,
                            email: currentValues.email,
                        });
                        push(LOGIN_ROUTES.login);
                    } else {
                        analyticsCustomEvent(eventMetricsMap.login_flow, {
                            click_text: `sign in`,
                            element_location: `new account flow`,
                        });
                        analyticsScreenCustomNames({
                            logged_in: true,
                        });
                        sessionStorage.removeItem('guest');
                        if (showInModal) {
                            await mergeCartDetails();
                            if (activeIframeId) {
                                refreshWebView();
                            }
                            if (postLoginBehavior === 'go-to-cart') {
                                replace(ROUTES.cart);
                            } else if (postLoginBehavior === 'onboarding') {
                                replace(COACH_SCREENS_ROUTES.location);
                            }
                            setNextScreen?.('closeInModal');
                        } else {
                            replace(COACH_SCREENS_ROUTES.location);
                            setNextScreen?.('closeInModal');
                        }
                    }
                } else {
                    analyticsCustomEvent(eventMetricsMap.account_creation, {
                        click_text: 'create account - failure',
                        element_location: 'create an account',
                    });
                }

                /* if (!responseData.status) {
                 setCreateuserError(responseData.message);
                 return;
             }
              router.push({
                 pathname: '/login/loginSrEnrollScreen',
                 search: '',
             }) */
            },
        });
    const handleOnBlur = (
        e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
    ) => {
        if (e.target.name) {
            setTouched({ ...touched, [e.target.name]: true });
            setFieldFoucs('');
        }
    };
    const handleChangeEmail = (val: string) => {
        handleChange({ target: { value: val, name: 'email' } });
    };

    const handlePhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
        // Strip out non-digit characters
        const digitsOnly = e.target.value.replace(/\D/g, '');

        // Check if the cleaned input value length is less than or equal to 10
        if (digitsOnly.length <= 10) {
            handleChange({
                target: { value: digitsOnly, name: 'phoneNumber' },
            });
        }
    };

    const handleTerms = (e: ChangeEvent<HTMLInputElement>) => {
        setTermsChecked(e.target.checked);
    };
    const handleSuggetion = (val: any) => {
        if (val?.includes('@')) {
            setShowList(true);
            const index = val.indexOf('@');
            const domainName = val.substring(index);
            setQuery(domainName);
            /*  if (val.includes('.')) {
                 setShowList(false);
             } */
        } else {
            setShowList(false);
        }
    };

    const emailList = (
        <ul className={styles.emailAutoComplete}>
            {customDomains
                .filter((email) =>
                    email.toLowerCase().includes(queryText.toLowerCase())
                )
                .map((email) => (
                    // eslint-disable-next-line
                    <li
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                        role="link"
                        className={styles.emailItem}
                        onClick={(e) => {
                            // e.preventDefault();
                            setShowList(false);
                            const emailFieldVal = values.confirmEmail;
                            const index = emailFieldVal.indexOf('@');
                            const clearedEmail = emailFieldVal.substring(
                                0,
                                index
                            );
                            // eslint-disable-next-line
                            handleChange({
                                target: {
                                    value: clearedEmail + email,
                                    name: 'confirmEmail',
                                },
                            });
                        }}
                    >
                        {email}
                    </li>
                ))}
        </ul>
    );

    console.log({ fieldFocus });
    return (
        <>
            <FullWidthCard
                noPadding
                id="LoginCreateNewUser"
                className={styles.createUserContainer}
            >
                <Button
                    onClick={handleCloseBtn}
                    variant="icon"
                    align="alignSelfStart"
                    className={styles.mb4}
                    tabIndex={2}
                    role="button"
                    ariaLabel="Go back"
                >
                    <IconRightBackArrow />
                </Button>
                <Heading
                    className={styles.title}
                    type="h3"
                    size={26}
                    weight="bold"
                    tabIndex={1}
                >
                    Create an account
                </Heading>
                {createUserError ? (
                    <div style={{ color: 'red', textAlign: 'center' }}>
                        {createUserError}
                    </div>
                ) : null}
                <form onSubmit={handleSubmit} className={styles.textContainer}>
                    <div>
                        <Box onClick={() => handleCloseBtn()}>
                            <ValidationTextField
                                fullWidth
                                id="email"
                                name="email"
                                label="Email Address"
                                disabled
                                value={obfuscateUserEmail(values.email)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <img
                                                src={require('../../assets/icons/iconEditNew.svg')}
                                                alt="icon"
                                                tabIndex={3}
                                                role="button"
                                                aria-label="Edit"
                                                aria-roledescription="Edit email or username Button."
                                                
                                            />
                                        </InputAdornment>
                                    ),
                                    classes: {
                                        underline: styles.disableEmailTextField,
                                    },
                                }}
                            />
                        </Box>
                    </div>
                    <ValidationTextField
                        fullWidth
                        id="confirmEmail"
                        name="confirmEmail"
                        label="Confirm Email*"
                        autoComplete="username"
                        tabIndex={4}
                        aria-selected="true"
                        aria-required="true"
                        aria-label={
                            touched.confirmEmail
                                ? errors.confirmEmail
                                    ? 'Enter a valid email address.'
                                    : 'Confirm email is a required field.'
                                : 'Confirm email'
                        }
                        className={
                            fieldFocus !== 'confirmEmail' &&
                                touched.confirmEmail &&
                                errors.confirmEmail
                                ? styles.root
                                : ''
                        }
                        onChange={(e) => {
                            handleChange(e);
                            confirmEmailAutoCompleteRef?.current?.change(e);
                            handleSuggetion(e.target.value);
                        }}
                        onBlur={(e: any) => {
                            handleOnBlur(e);
                        }}
                        onFocus={(e: any) =>
                            setTouched({ ...touched, confirmEmail: true })
                        }
                        value={values.confirmEmail}
                        error={
                            fieldFocus !== 'confirmEmail' &&
                            touched.confirmEmail &&
                            Boolean(errors.confirmEmail)
                        }
                        helperText={
                            fieldFocus !== 'confirmEmail' &&
                            touched.confirmEmail &&
                            errors.confirmEmail
                        }
                    />
                    {showList && emailList}
                    <ValidationTextField
                        fullWidth
                        id="firstName"
                        name="firstName"
                        onChange={handleChange}
                        label="First Name*"
                        tabIndex={5}
                        aria-selected="true"
                        aria-required="true"
                        aria-label={
                            errors.firstName && touched.firstName
                                ? 'First name is a required field.'
                                : 'First name'
                        }
                        value={values.firstName}
                        className={
                            fieldFocus !== 'firstName' &&
                                touched.firstName &&
                                errors.firstName
                                ? styles.root
                                : ''
                        }
                        inputProps={{ maxLength: 15 }}
                        onBlur={(e) => handleOnBlur(e)}
                        onFocus={(e: any) => setFieldFoucs(e.target.name)}
                        error={
                            fieldFocus !== 'firstName' &&
                            touched.firstName &&
                            Boolean(errors.firstName)
                        }
                        helperText={
                            fieldFocus !== 'firstName' &&
                            touched.firstName &&
                            errors.firstName
                        }
                    />
                    <ValidationTextField
                        fullWidth
                        id="lastName"
                        name="lastName"
                        value={values.lastName}
                        label="Last Name*"
                        tabIndex={6}
                        aria-selected="true"
                        aria-required="true"
                        aria-label={
                            errors.lastName && touched.lastName
                                ? 'Last name is a required field.'
                                : 'Last name'
                        }
                        onChange={handleChange}
                        className={
                            fieldFocus !== 'lastName' &&
                                touched.lastName &&
                                errors.lastName
                                ? styles.root
                                : ''
                        }
                        inputProps={{ maxLength: 20 }}
                        onBlur={(e) => handleOnBlur(e)}
                        onFocus={(e: any) => setFieldFoucs(e.target.name)}
                        error={
                            fieldFocus !== 'lastName' &&
                            touched.lastName &&
                            Boolean(errors.lastName)
                        }
                        helperText={
                            fieldFocus !== 'lastName' &&
                            touched.lastName &&
                            errors.lastName
                        }
                    />
                    <div className={styles.textImageContainer}>
                        <ValidationTextField
                            fullWidth
                            id="password"
                            name="password"
                            label="Password*"
                            tabIndex={7}
                            aria-selected="true"
                            aria-required="true"
                            aria-label={
                                touched.password
                                    ? errors.password
                                        ? `8 characters minimum, a letter, a special character, and a number. No spaces.`
                                        : 'Password is a required field.'
                                    : 'Password'
                            }
                            className={
                                fieldFocus !== 'password' &&
                                    touched.password &&
                                    errors.password
                                    ? styles.root
                                    : ''
                            }
                            autoComplete="new-password"
                            value={values.password}
                            onChange={handleChange}
                            type={!isPasswordShow ? 'password' : 'text'}
                            onFocus={(e) => {
                                handleOnBlur(e);
                                setFieldFoucs(e.target.name);
                            }}
                            onBlur={(e) => handleOnBlur(e)}
                            error={
                                fieldFocus !== 'password' &&
                                touched.password &&
                                Boolean(errors.password)
                            }
                            helperText={
                                fieldFocus !== 'password' && touched.password
                                    ? errors.password
                                    : passwordRegxValidationMsg
                            }
                            inputProps={{ maxLength: 50 }}
                        />
                        {touched.password && !errors.password && (
                            <div
                                className={styles.checkedImageAlign}
                                id="checked-button"
                                tabIndex={8}
                                role="button"
                                aria-label="Show password"
                            >
                                <IconVector />
                            </div>
                        )}
                        <Button
                            onClick={() => setPasswordShow(!isPasswordShow)}
                            className={styles.eyeImageAlign}
                        // id="close-button"
                        >
                            {isPasswordShow ? (
                                <div
                                    tabIndex={8}
                                    role="button"
                                    aria-label="Show password"
                                >
                                    <IconPasswordOpen />
                                </div>
                            ) : (
                                <div
                                    tabIndex={8}
                                    role="button"
                                    aria-label="Show password"
                                >
                                    <IconPasswordClose />
                                </div>
                            )}
                        </Button>
                    </div>
                    <div className={styles.selectWrapper}>
                        <ValidationTextField
                            fullWidth
                            id="phoneNumber"
                            name="phoneNumber"
                            inputMode="numeric"
                            label="Phone Number*"
                            tabIndex={9}
                            aria-selected="true"
                            aria-required="true"
                            aria-label={
                                touched.phoneNumber
                                    ? errors.phoneNumber
                                        ? 'Please enter a valid phone number.'
                                        : 'Phone number is a required field.'
                                    : 'Phone number'
                            }
                            onChange={handlePhoneNumber}
                            type="tel"
                            value={values.phoneNumber}
                            className={
                                fieldFocus !== 'phoneNumber' &&
                                    touched.phoneNumber &&
                                    errors.phoneNumber
                                    ? styles.root
                                    : ''
                            }
                            onBlur={(e) => handleOnBlur(e)}
                            onFocus={(e: any) => setFieldFoucs(e.target.name)}
                            error={
                                fieldFocus !== 'phoneNumber' &&
                                touched.phoneNumber &&
                                Boolean(errors.phoneNumber)
                            }
                            helperText={
                                fieldFocus !== 'phoneNumber' &&
                                touched.phoneNumber &&
                                errors.phoneNumber
                            }
                        />
                        <div
                            className={styles.chevron}
                            tabIndex={10}
                            role="button"
                            aria-label="Question"
                            aria-roledescription="Why do we need your phone number? Button"
                        >
                            <InfoButton
                                tooltipTitle="We'll use your phone number to help verify your account in the future."
                                tooltipCta="Got it"
                                buttonId="create-user-tooltip-button"
                                buttonSize="1.8rem"
                                tooltipPlacement="top-end"
                                ariaDetails={ariaDetails}
                            />
                        </div>
                    </div>
                    <div
                        id="checkboxContainer"
                        className={styles.checkboxContainer}
                    >
                        <Checkbox
                            id="termsCheckbox"
                            className={styles.checkBoxAlign}
                            style={{
                                color: '#101820',
                                padding: 0,
                            }}
                            checked={isTerms}
                            tabIndex={11}
                            aria-required="true"
                            role="checkbox"
                            aria-label="Required Terms and Conditions Checkbox"
                            onChange={handleTerms}
                        />
                        <Body
                            size={12}
                            weight="light"
                            tabIndex={12}
                        >
                        <p>
                            By creating an account, you agree to Staples and
                            Easy Rewards{' '}
                            <Button
                                tabIndex={-1}
                                ariaLabel={`${LOGIN_CREATE_NEW_USER.TERM_CONDITION} link`}
                                variant="text"
                                display="inlineFlex"
                                className={styles.subLink}
                                fontSize={12}
                                onClick={() => {
                                    setShowTabs(true);
                                    setTosSource(
                                        `${process.env.REACT_APP_DOTCOM_URL}/hc?id=52e40651-0852-4ad7-a532-45017c287d50`
                                    );
                                }}
                            // href="https://www.staples.com/hc?id=52e40651-0852-4ad7-a532-45017c287d50"
                            >
                                {LOGIN_CREATE_NEW_USER.TERM_CONDITION}
                            </Button>
                            ,{' '}
                            <Button
                                tabIndex={-1}
                                ariaLabel={`${LOGIN_CREATE_NEW_USER.PRIVACY_POLICY} link`}
                                variant="text"
                                display="inlineFlex"
                                className={styles.subLink}
                                fontSize={12}
                                onClick={() => {
                                    setTosSource(
                                        `${process.env.REACT_APP_DOTCOM_URL}/hc?id=dbb94c10-973c-478b-a078-00e58f66ba32`
                                    );
                                }}
                            // href="https://www.staplesconnect.com/privacypolicy"
                            >
                                {LOGIN_CREATE_NEW_USER.PRIVACY_POLICY}
                            </Button>
                            , and{' '}
                            <Button
                                tabIndex={-1}
                                ariaLabel={`${LOGIN_CREATE_NEW_USER.CALIFORNIA} link`}
                                variant="text"
                                display="inlineFlex"
                                fontSize={12}
                                className={styles.subLink}
                                onClick={() => {
                                    setTosSource(
                                        `${process.env.REACT_APP_DOTCOM_URL}/hc?id=d0641e76-dd9f-4d69-80d3-ff148ddcd188`
                                    );
                                }}
                            // href="https://www.staplesconnect.com/californianotice"
                            >
                                {LOGIN_CREATE_NEW_USER.CALIFORNIA}
                            </Button>
                            . You also agree to receive Staples promotional
                            communications. You may unsubscribe at any time.
                            </p>
                        </Body>
                    </div>
                    <LoadingButton
                        fullWidth
                        id="step2-next-button"
                        variant="contained"
                        classes={{
                            root: styles.createAccountButton,
                        }}
                        type="submit"
                        tabIndex={13}
                        role="button"
                        aria-label="Continue"
                        loading={false}
                        disabled={
                            (errors && Object.keys(errors).length !== 0) ||
                            !isTerms
                        }
                    >
                        {LOGIN_CREATE_NEW_USER.CREATE_ACCOUNT}
                    </LoadingButton>

                    <br />
                    <br />
                </form>
                {isLoading ? (
                    <LoadingOverlay
                        variant="wordmark"
                        theme="transparent-light"
                        open={isLoading}
                    />
                ) : null}
            </FullWidthCard>
            <Captcha
                capthaHtml={captchaHtml}
                sessionId={nuSessionId}
                onCaptchaSumbit={handleSubmit}
                placement="CreateAccountWebSC"
            />
            <SwipeableDrawer
                anchor="bottom"
                open={!!tosSource}
                onClose={() => {
                    setTosSource(null);
                    setShowTabs(false);
                    setTosTabIndex(0);
                }}
                onOpen={() => console.log('open')}
            >
                {showTabs && (
                    <Tabs
                        className={styles.tosTabs}
                        value={tosTabIndex}
                        onChange={(_e, value) => {
                            setTosTabIndex(value);
                            if (value === 0) {
                                setTosSource(
                                    `${process.env.REACT_APP_DOTCOM_URL}/hc?id=52e40651-0852-4ad7-a532-45017c287d50`
                                );
                            } else {
                                setTosSource(
                                    `${process.env.REACT_APP_DOTCOM_URL}/lp/easy-rewards-terms-conditions`
                                );
                            }
                        }}
                        aria-label="simple tabs example"
                    >
                        <Tab label="Staples" />
                        <Tab label="Easy Rewards" />
                    </Tabs>
                )}
                {tosTabIndex === 0 && (
                    <iframe
                        id="privacy-link"
                        title="privacy link iFrame"
                        style={{ height: '90vh', width: '100%', marginTop: 16 }}
                        src={tosSource || '#'}
                    />
                )}
                {tosTabIndex === 1 && (
                    <iframe
                        id="privacy-link"
                        title="privacy link iFrame"
                        style={{ height: '90vh', width: '100%', marginTop: 16 }}
                        src={tosSource || '#'}
                    />
                )}
            </SwipeableDrawer>
        </>
    );
};

export default CreateAccountScreen;
