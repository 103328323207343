/* eslint-disable no-useless-escape */
/* eslint-disable max-len */

import React, { useState, useLayoutEffect, useEffect } from 'react';
import { Tabs, Tab as MUITab, withStyles, Fade } from '@material-ui/core';
import { Player } from '@lottiefiles/react-lottie-player';
import {
    useUserInfo,
    useRouter,
    useLocalStorage,
    LOCAL_STORAGE_KEYS,
    useUserState,
    eventMetricsMap,
    useEventsReporter,
} from 'src/hooks';
import { enrollInRewards } from 'src/services/easyRewardsServices';
import { REWARDS_ENROLLMENT_ROUTES } from 'src/routers/routes';
import { RewardsEnrollErrorDialog } from 'src/components/EasyRewards';
import { Body, Button, Heading, SwipeableDrawer } from 'src/components/UI';
import { IconEasyRewardsHorizontal } from 'src/components/Icons';
import ROUTES from 'src/lib/routes';
import { validateUserInfo } from 'src/utils/userUtils';
import { REWARDS_ENROLLMENT_TRACKER } from '../types';
import styles from './styles.module.scss';
import IconTopDesign from './Icons';
import '../../../assets/animate/animate.css';

const Tab = withStyles(() => ({
    root: {
        fontSize: 18,
        textTransform: 'none',
        fontFamily: 'StaplesNormsRegular',
        '&$selected': {
            fontFamily: 'StaplesNormsBold',
        },
    },
    selected: {},
}))((props: { label: string }) => <MUITab {...props} />);

function WelcomeScreen() {
    const [enrollmentError, setEnrollmentError] = useState<{
        errorCode: string;
        errorDialogMessage: string;
    } | null>(null);
    const [tosSource, setTosSource] = useState<string | null>(null);
    const [showTabs, setShowTabs] = useState(false);
    const [tosTabIndex, setTosTabIndex] = useState(0);
    const [animationToPlay, setAnimationToPlay] = useState(0);
    const [{ userType }] = useUserState();
    const isUserNonmigrated = userType === 'non-migrated';
    const [animationRefs, setAnimationRefs] = useState<[any, any, any]>([
        null,
        null,
        null,
    ]);
    const { push, replace } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const [storedValue, setEnrollmentSteps] =
        useLocalStorage<REWARDS_ENROLLMENT_TRACKER>(
            LOCAL_STORAGE_KEYS.ENROLLMENT_TRACKER,
            {
                completedEnrollmentSteps: {},
                skippedEnrollmentSteps: {},
            }
        );

    const { userInfo, isFetchingUserInfo, refetchUserInfo } = useUserInfo();

    const { mutateAsync: enroll, isLoading: isMutatingEnrollment } =
        enrollInRewards({
            onSuccess: () => refetchUserInfo(),
        });

    useLayoutEffect(() => {
        if (
            storedValue?.completedEnrollmentSteps?.[
            REWARDS_ENROLLMENT_ROUTES.welcome
            ]
        ) {
            push(REWARDS_ENROLLMENT_ROUTES.favorites);
        }
    }, []);

    useEffect(() => {
        if (animationRefs[animationToPlay]) {
            animationRefs[animationToPlay].play();
        }
    }, [animationToPlay, animationRefs]);

    if (isFetchingUserInfo || !userInfo) {
        // TODO add splash screen
        return null;
    }

    const handleLink = (e: any) => {
        console.log('🚀 ~ file: WelcomeScreen.tsx:56 ~ handleLink ~ e:', e);
    };

    const primaryCta =
        userType === 'non-migrated'
            ? () => {
                if (validateUserInfo(userInfo)) {
                    enroll({
                        FirstName: userInfo.firstName,
                        LastName: userInfo.lastName,
                        PhoneNumber: userInfo.phone,
                        EmailId: userInfo.email,
                    })
                        .then((res: any) => {
                            if (res?.data?.success) {
                                analyticsCustomEvent(eventMetricsMap.easy_rewards_enroll, {
                                    click_text: 'enroll in easy rewards',
                                    element_location: 'non-migrated',
                                    event_rewards_number: userInfo?.rewardsNumber,
                                    logged_in_user: true, // Set the logged-in user property to true
                                });
                                setEnrollmentSteps({
                                    skippedEnrollmentSteps: {
                                        ...(storedValue?.skippedEnrollmentSteps || {}),
                                    },
                                    completedEnrollmentSteps: {
                                        ...(storedValue?.completedEnrollmentSteps || {}),
                                        [REWARDS_ENROLLMENT_ROUTES.welcome]: true,
                                    },
                                });
                                replace(REWARDS_ENROLLMENT_ROUTES.welcomeAnimation, {
                                    onCompleteNavigateTo: REWARDS_ENROLLMENT_ROUTES.favorites,
                                });
                            } else {
                                analyticsCustomEvent(eventMetricsMap.pop_up, {
                                    click_text: 'We were unable to enroll you in Easy Rewards at this time.',
                                    element_location: 'easy_rewards',
                                });
                                setEnrollmentError({
                                    errorCode: res?.data.code,
                                    errorDialogMessage: res?.data.msg,
                                });
                            }
                        })
                        .catch((error) => {
                            analyticsCustomEvent(eventMetricsMap.pop_up, {
                                click_text: 'We were unable to enroll you in Easy Rewards at this time.',
                                element_location: 'easy_rewards',
                            });
                            setEnrollmentError({
                                errorCode: error?.response?.data?.code || 'default',
                                errorDialogMessage: error?.response?.data?.msg || 'Please try again',
                            });
                        });
                } else {
                    push(REWARDS_ENROLLMENT_ROUTES.missingInfo);
                }
            }
            : () => {
                setEnrollmentSteps({
                    skippedEnrollmentSteps: {
                        ...(storedValue?.skippedEnrollmentSteps || {}),
                    },
                    completedEnrollmentSteps: {
                        ...(storedValue?.completedEnrollmentSteps || {}),
                        [REWARDS_ENROLLMENT_ROUTES.welcome]: true,
                    },
                });
                analyticsCustomEvent(eventMetricsMap.easy_rewards_enroll, {
                    click_text: 'get started',
                    element_location: 'introducing easy rewards',
                });
                push(REWARDS_ENROLLMENT_ROUTES.favorites);
            };

    const secondaryCta =
        userType === 'non-migrated'
            ? () => {
                if (validateUserInfo(userInfo)) {
                    push(ROUTES.home);
                } else {
                    push(REWARDS_ENROLLMENT_ROUTES.missingInfoConfimation);
                }
            }
            : () => {
                setEnrollmentSteps({
                    skippedEnrollmentSteps: {
                        ...(storedValue?.skippedEnrollmentSteps || {}),
                    },
                    completedEnrollmentSteps: {
                        ...(storedValue?.completedEnrollmentSteps || {}),
                        [REWARDS_ENROLLMENT_ROUTES.welcome]: true,
                    },
                });
                replace(ROUTES.home);
            };

    return (
        <Fade in>
            <div>
                <span className={styles.topRightDesign}>
                    <IconTopDesign />
                </span>
                <div
                    className={styles.container}
                    style={{ justifyContent: 'space-around' }}
                >
                    {/* <IntroCarousel
                    variant={
                        userType === 'non-migrated'
                            ? 'non-migrated-onboarding'
                            : 'base'
                    }
                    primaryCta={primaryCta}
                    secondaryCta={secondaryCta}
                 /> */}
                    <div>
                        <div className={styles.header}>
                            <Heading type="h6" size={18}>
                                Welcome to
                            </Heading>
                            <IconEasyRewardsHorizontal />
                        </div>
                        <div className={styles.infoList}>
                            <div className={styles.infoListItem}>
                                <div className={styles.infoListIcon}>
                                    <Player
                                        lottieRef={(lottieRef) =>
                                            setAnimationRefs((oldVal) => {
                                                const newVal: [any, any, any] =
                                                    [...oldVal];
                                                newVal[0] = lottieRef;
                                                return newVal;
                                            })
                                        }
                                        onEvent={(event) => {
                                            if (event === 'complete') {
                                                setAnimationToPlay(1);
                                            }
                                        }}
                                        src={require('../../../assets/lotties/rewards-enrollment/benefits-points-1.json')}
                                        style={{
                                            height: 90,
                                        }}
                                    />
                                </div>
                                <Heading type="h3" weight="light" size={18}>
                                    Earn
                                    <Body
                                        display="inline"
                                        color="textPurple"
                                        weight="bold"
                                        size={18}
                                    >
                                        {' '}
                                        1 point per $1 spent.{' '}
                                    </Body>
                                    In store, online and in the app*
                                </Heading>
                            </div>
                            <div className={styles.infoListItem}>
                                <div className={styles.infoListIcon}>
                                    <Player
                                        lottieRef={(lottieRef) =>
                                            setAnimationRefs((oldVal) => {
                                                const newVal: [any, any, any] =
                                                    [...oldVal];
                                                newVal[1] = lottieRef;
                                                return newVal;
                                            })
                                        }
                                        onEvent={(event) => {
                                            if (event === 'complete') {
                                                setAnimationToPlay(2);
                                            }
                                        }}
                                        src={require('../../../assets/lotties/rewards-enrollment/benefits-points-2.json')}
                                        style={{
                                            height: 90,
                                        }}
                                    />
                                </div>
                                <Heading type="h3" weight="light" size={18}>
                                    Plus earn
                                    <Body
                                        display="inline"
                                        color="textPurple"
                                        weight="bold"
                                        size={18}
                                    >
                                        {' '}
                                        2x or more points{' '}
                                    </Body>
                                    on 3 bonus points categories you choose
                                </Heading>
                            </div>
                            <div className={styles.infoListItem}>
                                <div className={styles.infoListIcon}>
                                    <Player
                                        lottieRef={(lottieRef) =>
                                            setAnimationRefs((oldVal) => {
                                                const newVal: [any, any, any] =
                                                    [...oldVal];
                                                newVal[2] = lottieRef;
                                                return newVal;
                                            })
                                        }
                                        onEvent={(event) => {
                                            if (event === 'complete') {
                                                setAnimationToPlay(0);
                                            }
                                        }}
                                        src={require('../../../assets/lotties/rewards-enrollment/benefits-points-3.json')}
                                        style={{
                                            height: 90,
                                        }}
                                    />
                                </div>
                                <Heading type="h3" weight="light" size={18}>
                                    <Body
                                        display="inline"
                                        color="textPurple"
                                        weight="bold"
                                        size={18}
                                    >
                                        Points never expire{' '}
                                    </Body>
                                    for active members*
                                </Heading>
                            </div>
                        </div>
                    </div>
                    <div className={styles.bottomContainer}>
                        <div className={styles.disclaimerContainer}>
                            <Body size={12} weight="light">
                                *Excludes gift cards, phone cards, postage
                                stamps, clearance items, cash, taxes, shipping
                                fees/charges, protection/service plans, travel
                                services, promotional products and certain
                                special orders and online marketplace purchases.
                                For full details, visit staples.com/easy.
                            </Body>
                            <Body size={12} weight="light">
                                *Members must earn or redeem at least 1 point in
                                18 months to remain active.
                            </Body>
                            {isUserNonmigrated && (
                                <Body size={12} weight="light">
                                    By enrolling in Easy Rewards, you agree to
                                    Staples and Easy Rewards{' '}
                                    <Button
                                        variant="text"
                                        display="inlineFlex"
                                        onClick={() =>
                                            setTosSource(
                                                `${process.env.REACT_APP_DOTCOM_URL}/lp/easy-rewards-terms-conditions`
                                            )
                                        }
                                        fontSize={12}
                                        color="textLinkBlue"
                                    >
                                        Terms & Conditions,
                                    </Button>{' '}
                                    <Button
                                        variant="text"
                                        display="inlineFlex"
                                        onClick={() => {
                                            setShowTabs(false);
                                            setTosSource(
                                                `${process.env.REACT_APP_DOTCOM_URL}/hc?id=dbb94c10-973c-478b-a078-00e58f66ba32`
                                            );
                                        }}
                                        fontSize={12}
                                        color="textLinkBlue"
                                    >
                                        Privacy Notice
                                    </Button>{' '}
                                    and{' '}
                                    <Button
                                        variant="text"
                                        display="inlineFlex"
                                        onClick={() => {
                                            setShowTabs(false);
                                            setTosSource(
                                                `${process.env.REACT_APP_DOTCOM_URL}/hc?id=d0641e76-dd9f-4d69-80d3-ff148ddcd188`
                                            );
                                        }}
                                        fontSize={12}
                                        color="textLinkBlue"
                                    >
                                        California Notice.
                                    </Button>{' '}
                                    You also agree to receive Staples
                                    promotional communications. You may
                                    unsubscribe at any time.
                                </Body>
                            )}
                        </div>
                        <div className={styles.buttonContainer}>
                            <Button
                                fullWidth
                                disabled={isMutatingEnrollment}
                                loading={isMutatingEnrollment}
                                onClick={primaryCta}
                            >
                                {isUserNonmigrated
                                    ? 'Enroll in Easy Rewards'
                                    : 'Get started'}
                            </Button>
                            {isUserNonmigrated && (
                                <Button
                                    color="textLinkBlue"
                                    variant="text"
                                    onClick={secondaryCta}
                                >
                                    I'm not interested
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
                <RewardsEnrollErrorDialog
                    error={enrollmentError}
                    setError={setEnrollmentError}
                />
                <SwipeableDrawer
                    anchor="bottom"
                    open={!!tosSource}
                    onClose={() => {
                        setTosSource(null);
                        setShowTabs(false);
                    }}
                    onOpen={() => console.log('open')}
                >
                    {showTabs && (
                        <Tabs
                            className={styles.tosTabs}
                            value={tosTabIndex}
                            onChange={(_e, value) => setTosTabIndex(value)}
                            aria-label="simple tabs example"
                        >
                            <Tab label="Staples" />
                            <Tab label="Easy Rewards" />
                        </Tabs>
                    )}
                    {tosTabIndex === 0 && (
                        <iframe
                            id="privacy-link"
                            title="privacy link iFrame"
                            style={{
                                height: '90vh',
                                width: '100%',
                                marginTop: 16,
                            }}
                            src={tosSource || '#'}
                        />
                    )}
                    {tosTabIndex === 1 && (
                        <iframe
                            id="privacy-link"
                            title="privacy link iFrame"
                            style={{
                                height: '90vh',
                                width: '100%',
                                marginTop: 16,
                            }}
                            src={tosSource || '#'}
                        />
                    )}
                </SwipeableDrawer>
            </div>
        </Fade>
    );
}

export default WelcomeScreen;
