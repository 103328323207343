/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useState } from 'react';
import {
    Box,
    Button,
    ButtonBase,
    Link,
    makeStyles,
    Typography,
} from '@material-ui/core';
import axios, { AxiosInstance } from 'axios';
import COLORS from 'src/lib/colors';
import ROUTES from 'src/lib/routes';
import useRouter from 'src/hooks/useRouter';
import LoadingButton from 'src/components/loading-button';
import { SessionManager } from 'src/lib/api-client/sessionManager';
import { useUserState } from 'src/hooks';
import { useFormik } from 'formik';
import clsx from 'clsx';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { LOGIN_ROUTES } from 'src/routers/routes';
import { sendEmailToUser } from './pwd-reset';
import FullWidthCard from '../cards/full-width-card';
import LoadingOverlay from '../loading-overlay';
import NavTop from '../nav-top';
import ValidationTextField from '../validation-text-field';
import Captcha from './captcha';

const useStyles = makeStyles({
    screenload: {
        flexGrow: 1,
        background: COLORS.whiteSmoke,
        '& #pwd-screen': {
            display: 'flex',
            alignItems: 'baseline',
            borderRadius: 0,
            height: '17px',
        },
    },
    createAccountButton: {
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
        fontWeight: 500,
    },
    container: {
        minHeight: '20.5rem',
        borderRadius: '15px',
        padding: '24px',
        margin: '-5rem 1.5rem 0px',
    },
    headerSubTitleFound: {
        width: '242px',
        margin: '15px 28px 15px 28px',
        color: COLORS.textBlack,
        fontSize: '17px',
        lineHeight: '22px',
        letterspacing: '-0.22499999403953552px',
        textAlign: 'center',
    },
    headerTitle: {
        color: COLORS.whiteSmoke,
        fontSize: '24px',
        fontWeight: 700,
        marginBottom: '100px',
        lineHeight: '22px',
        letterspacing: '-0.22499999403953552px',
        textAlign: 'center',
    },
    textFieldLabel: {
        '& label': {
            textAlign: 'center',
            top: 'unset',
            bottom: '2rem',
        },
        '& label.Mui-focused': {
            width: '150%',
            textAlign: 'left',
        },
        marginBottom: '20px',
    },
    textFieldLabelError: {
        '& label': {
            bottom: '3.4rem',
        },
    },
    contentContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
    },
    backIcon: {
        marginLeft: '24px',
        marginTop: '2.4rem',
        marginBottom: '1.8rem',
        alignSelf: 'self-start',
        color: '#ffffff',
    },
    backButton: {
        alignSelf: 'start',
    },
    backToLogin: {
        fontSize: '14px',
        margin: '1.7rem 0 1rem 0',
        color: COLORS.brandCyan,
        textAlign: 'center',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: '10px 0px 17px',
    },
    headerErrorText: {
        width: '70%',
        margin: '15px',
        color: COLORS.whiteSmoke,
        fontSize: '17px',
        lineHeight: '22px',
        letterspacing: '-0.22499999403953552px',
        textAlign: 'center',
    },
});

const validationSchemaFields = yup.object({
    email: yup
        .string()
        .required('This field is required')
        // eslint-disable-next-line
        .email('Enter a valid email'),
});
const ForgotPassword = ({ showInModal, setNextScreen }: any) => {
    const classes = useStyles();
    const { history, push } = useRouter();
    const [isLoading, setIsLoading] = useState(false);
    const [failedStatus, setFailedStatus] = useState(false);

    const [captchaHtml, setCaptchaHtml] = useState('');
    const [nuSessionId] = useState(uuidv4());
    const [userState, setUserState] = useUserState();
    const emailId = localStorage.getItem('pwdEmail');

    const sendMail = async (values: any) => {
        const stplSessionId = nuSessionId;
        setIsLoading(true);
        SessionManager.userName = values.email;
        SessionManager.isForgotPassword = true;

        const nPayload = (
            document?.getElementsByName('nds-pmd')[0] as HTMLInputElement
        )?.value;

        const nuCaptchaAnswer = (document.getElementById(
            'nucaptcha-answer'
        ) as HTMLInputElement)
            ? (document.getElementById('nucaptcha-answer') as HTMLInputElement)
                .value
            : '';
        const nuCaptchaToken = (document.getElementById(
            'nucaptcha-token'
        ) as HTMLInputElement)
            ? (document.getElementById('nucaptcha-token') as HTMLInputElement)
                .value
            : '';

        const { status, message } = await sendEmailToUser(
            values.email,
            stplSessionId,
            nPayload,
            nuCaptchaAnswer,
            nuCaptchaToken
        );
        const captchaInRes =
            message?.body?.captchaResponse?.captchaResponseHtml;
        if (captchaInRes) {
            setCaptchaHtml(captchaInRes);
        }
        setIsLoading(false);
        if (!status) {
            setIsLoading(false);
            setFailedStatus(true);
            return;
            // eslint-disable-next-line
        }
        setIsLoading(false);
        setFailedStatus(false);
        if (!captchaInRes) {
            if (!showInModal) {
                history.push(ROUTES.passwordResetEmail);
            }
            else {
                setNextScreen(ROUTES.passwordResetEmail)
            }
        }
    };
    const formikFieldValue = useFormik({
        initialValues: {
            email: emailId || '',
        },
        validationSchema: validationSchemaFields,
        validateOnMount: true,
        onSubmit: (values) => {
            sendMail(values);
            localStorage.setItem('pwdEmail', values.email);
        },
    });
    const handleOnBlur = (e: string) => {
        if (e) {
            formikFieldValue.setTouched({
                ...formikFieldValue.touched,
                [e]: true,
            });
        }
    };
    const { handleSubmit } = formikFieldValue;
    const goBack = () => {
        if (formikFieldValue.values.email) {
            setUserState({
                ...userState,
                email: formikFieldValue.values.email,
            });
            if (!showInModal) {
                push(LOGIN_ROUTES.login);
            }
            else {
                setNextScreen(LOGIN_ROUTES.login)
            }

        } else if (emailId) {
            setUserState({ ...userState, email: emailId });
            if (!showInModal) {
                push(LOGIN_ROUTES.login);
            }
            else {
                setNextScreen(LOGIN_ROUTES.login)
            }
        } else if (!showInModal) {
            push(LOGIN_ROUTES.lookup);
        }
        else {
            setNextScreen(LOGIN_ROUTES.lookup)
        }
    };
    const clearError = (e: any) => {
        const fieldName = e.type === 'focus' ? e.target.name : '';
        formikFieldValue.setFieldError(fieldName, '');
    };
    const emailInputHasError =
        formikFieldValue.touched.email &&
        Boolean(formikFieldValue.errors.email);
    return (
        <div style={{ height: showInModal ? '85vh' : 'auto' }}>
            {isLoading ? (
                <LoadingOverlay
                    variant="wordmark"
                    theme="transparent-light"
                    open={isLoading}
                />
            ) : null}
            <NavTop
                maxHeight="17rem"
                bgImageSrc={require('../../assets/bgs/loginBg.svg')}
                id="pwd-screen"
            >
                <ButtonBase
                    tabIndex={5}
                    role='button'
                    aria-label='Go back'
                    onClick={() => goBack()}
                    className={classes.backButton}
                >
                    <img
                        className={classes.backIcon}
                        src={require('../../assets/icons/iconBackWhite.svg')}
                        alt="staples connect logo"
                    />
                </ButtonBase>
                {failedStatus && (
                    <Typography className={classes.headerErrorText}>
                        we're unable to send the password reset email. please
                        make sure you have entered correct email.
                    </Typography>
                )}
                <Typography className={classes.headerTitle}>
                    Password Reset
                </Typography>
                <FullWidthCard
                    cardContainerClassName={classes.container}
                    noPadding
                    hasManualShadow
                    id="password-reset-card"
                >
                    <div
                        className={classes.contentContainer}
                        id="content-container"
                    >
                        <Typography tabIndex={1} aria-label="Send password reset instructions to your email" className={classes.headerSubTitleFound}>
                            <b>Send password reset instructions to:</b>
                            <br />
                        </Typography>
                        <form onSubmit={handleSubmit} className={classes.form}>
                            <ValidationTextField
                                // label#email-label
                                tabIndex={2}
                                aria-selected
                                aria-required
                                aria-label="Email"
                                InputLabelProps={{ style: { width: '100%' } }}
                                fullWidth
                                id="email"
                                name="email"
                                label="Email Address"
                                classes={{
                                    root: clsx(
                                        classes.textFieldLabel,
                                        emailInputHasError &&
                                        classes.textFieldLabelError
                                    ),
                                }}
                                disabled={false}
                                value={formikFieldValue.values.email}
                                onChange={formikFieldValue.handleChange}
                                onBlur={(e) => handleOnBlur('email')}
                                onFocus={(e) => clearError(e)}
                                error={emailInputHasError}
                                helperText={
                                    formikFieldValue.touched.email &&
                                    formikFieldValue.errors.email
                                }
                            />

                            <Captcha
                                capthaHtml={captchaHtml}
                                sessionId={nuSessionId}
                                onCaptchaSumbit={handleSubmit}
                                placement="PasswordResetWebSC"
                            />
                            <LoadingButton
                                tabIndex={3}
                                aria-selected
                                aria-label="Send"
                                fullWidth
                                id="step2-next-button"
                                variant="contained"
                                classes={{
                                    root: classes.createAccountButton,
                                }}
                                type="submit"
                                loading={false}
                                disabled={
                                    formikFieldValue.errors &&
                                    Object.keys(formikFieldValue.errors)
                                        .length !== 0
                                }
                            >
                                Send
                            </LoadingButton>
                            <Link
                                tabIndex={4}
                                aria-selected
                                aria-label="Back to Sign in"
                                onClick={goBack}
                                className={classes.backToLogin}
                            >
                                Back to Sign in
                            </Link>
                        </form>
                    </div>
                </FullWidthCard>
            </NavTop>
        </div>
    );
};
export default ForgotPassword;
