/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useState } from 'react';
import { } from '@material-ui/core';
import { DownChevron, IconPlusThin } from 'src/components/Icons';
import { Body, Button, Heading, SwipeableDrawer } from 'src/components/UI';
import { FavoriteSelectionCarousel } from 'src/screens/RewardsEnrollment/FavoriteSelectionScreen';
import {
    FavoriteOffersList,
    getFavoriteOffers,
} from 'src/services/easyRewardsServices';
import { mergeClasses } from 'src/stylesheets/utils';
import { InfoDrawer } from 'src/components/EasyRewards';
import { eventMetricsMap, useEventsReporter, useUserInfo } from 'src/hooks';
import moment from 'moment';
import { motion } from 'framer-motion';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { Player } from '@lottiefiles/react-lottie-player';
import IconRoundThin from 'src/components/Icons/IconRoundThin';
import IconDownCoachMark from 'src/components/Icons/IconDownCoachMark';
import styles from './styles.module.scss';

interface FavoriteCardProps {
    title: string;
    subtitle: string | null;
    img: string | null;
    onClick: () => void;
}
function FavoriteCard({ title, subtitle, img, onClick }: FavoriteCardProps) {
    return (
        <div className={styles.cardContainer} onClick={onClick}>
            <div className={styles.infoIcon}>
                {/* TODO move this to icons */}
                <img
                    id="onboarding-swipeable-elem-image"
                    src={require('../../../assets/screenshots/infoIcon.svg')}
                    alt="onboarding introduction"
                />
            </div>
            <img className={styles.cardImage} src={img || ''} alt="category" />
            <div className={styles.cardDetailsContainer}>
                <Heading
                    textAlign="textCenter"
                    type="h6"
                    className={styles.title}
                >
                    {title}
                </Heading>
                {subtitle && (
                    <p className={styles.cardSubheading}>{subtitle}</p>
                )}
            </div>
        </div>
    );
}

interface FavoriteCardAddProps {
    onClick: () => void;
}
function FavoriteCardAdd({ onClick }: FavoriteCardAddProps) {
    return (
        <div role="button" onClick={onClick} className={styles.cardContainer} style={{ padding: "15px 13px" }}>
            <div className={styles.cardPlusIconContainer} style={{ border: 'none' }}>
                <IconRoundThin />
            </div>
            <div>
                <Heading textAlign="textCenter" type="h6">
                    No category
                </Heading>
            </div>
        </div>
    );
}

interface SelectedOffersProps {
    subscribedList: FavoriteOffersList | undefined;
    isSubscribedOffersListEmpty: boolean;
    setIsFavoriteSelectionDrawerOpen: (arg: boolean) => void;
}

function SelectedOffers({
    subscribedList,
    isSubscribedOffersListEmpty,
    setIsFavoriteSelectionDrawerOpen,
}: SelectedOffersProps) {
    const { analyticsCustomEvent } = useEventsReporter();

    const { userInfo } = useUserInfo();

    return isSubscribedOffersListEmpty ? null : (
        <>
            {new Array(3).fill(null).map((_v, index) => {
                const offer = subscribedList?.[index];
                return offer ? (
                    <FavoriteCard
                        key={offer.id}
                        title={offer.name}
                        subtitle={offer.alternativedescription}
                        img={offer.digitalviewimage}
                        onClick={() => {
                            analyticsCustomEvent(eventMetricsMap.easy_rewards, {
                                click_text: offer.name,
                                element_location:
                                    'Your bonus points categories',
                                event_rewards_number:
                                    userInfo?.rewardsNumber || '',
                            });
                        }}
                    />
                ) : (
                    <FavoriteCardAdd
                        key={index}
                        onClick={() => {
                            analyticsCustomEvent(eventMetricsMap.easy_rewards, {
                                click_text: 'Add',
                                element_location:
                                    'Your bonus points categories',
                                event_rewards_number:
                                    userInfo?.rewardsNumber || '',
                            });
                            setIsFavoriteSelectionDrawerOpen(true);
                        }}
                    />
                );
            })}
        </>
    );
}

function ManageFavoritesCoachScreen({ setFlagForFavoriteList }: {
    setFlagForFavoriteList: (data: boolean) => void
}) {
    const { analyticsCustomEvent } = useEventsReporter();
    const { isNotifyChooseCategory, openChooseCategory } =
        useUserUpdateContext();
    const { userInfo } = useUserInfo();
    const [isFavoriteSelectionDrawerOpen, setIsFavoriteSelectionDrawerOpen] =
        useState<boolean>(false);
    const [expandCategory, setExpandCategory] = useState<boolean>(false);

    // Info drawer state management
    const [infoDrawerState, setInfoDrawerState] = useState<{
        open: boolean;
        selectedOffer: string | null;
    }>({
        open: false,
        selectedOffer: null,
    });
    const toggleInfoDrawerOpen = (isOpen: boolean) =>
        setInfoDrawerState((oldState) => ({ ...oldState, open: isOpen }));

    const { data: offerList, isFetching } = getFavoriteOffers({
        initialData: [] as unknown as FavoriteOffersList,
    });

    const subscribedList = useMemo(
        () => offerList?.filter((offer) => offer.subscribed),
        [offerList]
    );
    const isSubscribedOffersListEmpty = subscribedList?.length === 0;
    useEffect(() => {
        if (isNotifyChooseCategory) {
            setIsFavoriteSelectionDrawerOpen(true);
        }
    }, [isNotifyChooseCategory]);

    useEffect(() => {
        setFlagForFavoriteList(isSubscribedOffersListEmpty)
    }, [isSubscribedOffersListEmpty])

    useEffect(() => {
        console.log('subscribedList', subscribedList)
        if (!subscribedList?.length) {
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `app_coachmarks no bpc selected`,
                firebase_screen_class: `app_coachmarks no bpc selected`,
            });
        } else if (subscribedList?.length > 0 && subscribedList?.length < 3) {
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `app_coachmarks bpc selection`,
                firebase_screen_class: `app_coachmarks bpc selection`,
            });
        } else if (subscribedList?.length === 3) {
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `app_coachmarks bpc`,
                firebase_screen_class: `app_coachmarks bpc`,
            });
        }
    }, [])

    return (
        <div>
            <div className={mergeClasses([styles.p4, styles.textContainer])}
                style={{ background: "#F2F2F2", top: isSubscribedOffersListEmpty ? "200px" : "49px" }}>
                <div className={mergeClasses([styles.flex, styles.flexCol, styles.rowGap2])}>
                    <div className={mergeClasses([styles.flex, styles.flexCol, styles.btnContainer])}>
                        <Heading
                            color="textDarkgray"
                            type="h6"
                            weight="bold"
                            size={22}
                        >
                            Your bonus points categories
                        </Heading>
                        {subscribedList && subscribedList.length > 0 && (
                            <Body size={10} weight='bold' color='$mediumGray'>
                                {`Renews on ${moment(subscribedList[0]?.subscriptionenddate).format('MM/DD/YY')}`}
                            </Body>
                        )}
                        {isSubscribedOffersListEmpty && (
                            <p className={mergeClasses([styles.m0, styles.textDarkgray])}>
                                Earn double or more points on what you buy most.
                            </p>
                        )}
                    </div>
                    <div className={styles.cardRow}>
                        <SelectedOffers
                            subscribedList={subscribedList}
                            isSubscribedOffersListEmpty={isSubscribedOffersListEmpty}
                            setIsFavoriteSelectionDrawerOpen={setIsFavoriteSelectionDrawerOpen}
                        />
                    </div>
                </div>
                {isSubscribedOffersListEmpty && (
                    <Button
                        variant="outlined-rounded"
                        borderColor="borderPurple"
                        color="textPurple"
                        bgColor="bgWhite"
                        align="alignSelfStart"
                        onClick={() => {
                            analyticsCustomEvent(eventMetricsMap.easy_rewards, {
                                click_text: 'Add',
                                element_location: 'Your bonus points categories',
                                event_rewards_number: userInfo?.rewardsNumber || '',
                            });
                            setIsFavoriteSelectionDrawerOpen(true);
                        }}
                    >
                        Make a selection
                    </Button>
                )}
            </div>

            <SwipeableDrawer
                anchor="bottom"
                open={isFavoriteSelectionDrawerOpen}
                onClose={() => setIsFavoriteSelectionDrawerOpen(false)}
                onOpen={() => setIsFavoriteSelectionDrawerOpen(true)}
            >
                <div
                    className={mergeClasses(
                        styles.p8,
                        styles.favoriteSelectionCarouselContainer
                    )}
                >
                    <FavoriteSelectionCarousel
                        variant="manage-favorites"
                        handleClose={() => setIsFavoriteSelectionDrawerOpen(false)}
                        selectionFromCoachSCreen
                    />
                </div>
            </SwipeableDrawer>
        </div>
    );
}

export default ManageFavoritesCoachScreen;
