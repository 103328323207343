import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { ListQueryResult } from 'src/services/types';
import { ReadOnlyItem } from './list-items';

const useStyles = makeStyles({
    container: {
        marginTop: '1.5rem',
    },
    heading: {
        textAlign: 'left',
        marginLeft: '1.5rem',
        marginBottom: -15,
    },
});

function FeaturedList(props: { lists: ListQueryResult }) {
    const classes = useStyles();
    const { lists } = props;
    return (
        <>
            {lists && lists.length > 0 && (
                <div className={classes.container}>
                    <Typography className={classes.heading} variant="h6">
                        Featured Lists
                    </Typography>
                    {lists.map((item) => (
                        <ReadOnlyItem
                            key={item.id}
                            item={item}
                            index={item.id}
                        />
                    ))}
                </div>
            )}
        </>
    );
}

export default FeaturedList;
