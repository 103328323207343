/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useCartCountContext } from 'src/lib/contexts/cart-count-context';
import COLORS from 'src/lib/colors';
import { Player } from '@lottiefiles/react-lottie-player';
import useRouter from 'src/hooks/useRouter';
import ROUTES from 'src/lib/routes';
import { setPreviousPath } from 'src/lib/utils/setPreviousPath';
import Cookies from 'js-cookie';
import { SessionManager } from 'src/lib/api-client/sessionManager';
import { getDCNNumber } from 'src/lib/api-client/cart-api';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import { useTabsUIStateContext } from 'src/lib/contexts/tabs-ui-state-context';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { SetSomniAppCookies } from 'src/lib/utils/app-utils';
/* eslint-disable @typescript-eslint/no-var-requires */
const lottieSrc = require('../../assets/lotties/stapleRevealWordmark.json');

type Props = {
    src: string | undefined;
    title: string;
    id: string;
    setNavigaion?: (data: string) => void;
    height?: string;
    iframeRef?: React.LegacyRef<HTMLIFrameElement> | null;
};

const useStyles = makeStyles({
    iframeContainer: {
        position: 'relative',
    },
    iframe: {
        width: '100vw',
        height: 'calc(100vh - 155px)',
        border: 'none',
    },
    spinnerContainer: {
        position: 'absolute',
        top: '0',
        bottom: '0',
        width: '100vw',
        height: '86vh',
        background: COLORS.primaryWhite,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            maxWidth: '6rem',
            maxHeight: '6rem',
        },
    },
});

const BrandIframe = ({
    src,
    title,
    id,
    setNavigaion,
    height,
    iframeRef = null,
}: Props) => {
    const classes = useStyles();
    const { updateIframeId, updateCartCount, isWebViewLoading, cartCount } =
        useCartCountContext();
    const [isLoading, setIsLoading] = useState(true);
    const { location, push, pathname } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const [currentIframeUrl, setCurrentIframeUrl] = useState('');
    const { setGuestModal } = useUserUpdateContext();

    const pointHistoryUrl = `${process.env.REACT_APP_DOTCOM_URL}/grs/rewards/sr/pointhistory`;
    const drawerUrl =
        'grs/rewards/sr/loyaltycenter?openrecycledrawer=yes&closebutton=no';

    let isInitialPointHistoryUrl = false;
    let urlChangeToCart = false;
    let urlChangeToOcp = false;
    let urlChangeToItemPage = false;
    let urlChangeToHome = false;
    let urlChangeToPrint = false;

    const postMessageForAnalytics = [
        'view_item',
        'add_payment_info',
        'add_shipping_info',
        'add_to_wishlist',
        'begin_checkout',
        'remove_from_cart',
        'view_promotion',
    ]
    const {
        setOcpPage,
        handleSetCartPage,
        handleSetHomePage,
        setPrintPage,
        setPointHistoryPage,
        setIframeLoading,
        setItemPage,
        setOpenPrintDrawer,
    } = useTabsUIStateContext();
    const msgHandler = (e: any) => {
        console.log('IframeMessage', e?.data?.message, e?.data);
        if (e?.data?.message === 'cart-items-count') {
            if (e?.data?.value) {
                if ('vibrate' in navigator && e?.data?.value > cartCount) {
                    navigator.vibrate(100);
                }
                updateCartCount(e?.data?.value);
            } else {
                updateCartCount(0);
                if (pathname !== ROUTES.home) {
                    push(ROUTES.emptyCart, setPreviousPath(location));
                }
            }
        } else if (e?.data?.message === 'cart-items-count-ocp') {
            updateCartCount(0);
            const dcnNumber = Cookies.get('DirectCustomerNumber');
            if (!dcnNumber) {
                getDCNNumber(SessionManager.access_token).then(
                    (directCustomerNumber) => {
                        if (directCustomerNumber) {
                            Cookies.set(
                                'DirectCustomerNumber',
                                directCustomerNumber
                            );
                        }
                    }
                );
            }
            if (setNavigaion) {
                setNavigaion('home');
            }
        } else if (e?.data?.message === 'add_to_cart') {
            analyticsCustomEvent(eventMetricsMap.add_to_cart, e?.data?.params);
        } else if (
            e?.data?.message === 'purchase' ||
            e?.data?.message === 'page_view'
        ) {
            analyticsCustomEvent(e?.data?.message, e?.data?.params);
        } else if (e?.data === 'config') {
            analyticsCustomEvent(e?.data);
        } else if (e?.data?.message === 'tech_services') {
            analyticsCustomEvent(e?.data?.message, e?.data);
        } else if (postMessageForAnalytics.includes(e?.data?.message)) {
            analyticsCustomEvent(e?.data?.message, e?.data?.params);
        } else if (e?.data?.message === 'navigation') {
            if (e?.data?.route === 'easy_rewards') {
                push(ROUTES.rewardsWallet);
            } else if (e?.data?.route === 'shipping') {
                push(ROUTES.shippingServices);
            } else if (e?.data?.route === 'sign_in') {
                setGuestModal({
                    open: true,
                    closeBehavior: 'go-to-cart',
                    guestCheckout: e?.data?.signinType === 'checkout',
                });
            }
        }
    };
    const hideBreadcrumb = () => {
        const iframe = (
            document?.getElementById(
                'point-history'
            ) as HTMLIFrameElement as any
        )?.contentWindow;
        if (iframe.document.querySelector('[aria-label="Breadcrumb"]')) {
            iframe.document.querySelector(
                '[aria-label="Breadcrumb"]'
            ).style.display = 'none';
        }

        if (iframe.document.querySelector('.OrderDetails__pageHeader')) {
            iframe.document.querySelector(
                '.OrderDetails__pageHeader'
            ).style.display = 'none';
        }
    };

    useEffect(() => {
        updateIframeId(id);
        window.addEventListener('message', msgHandler);
        if (window.location.host === 'localhost:3000') return undefined;
        const interval = setInterval(() => {
            const dataDocument: any = document?.getElementById?.(id);
            if (dataDocument && dataDocument?.contentWindow) {
                const urlData = dataDocument?.contentWindow?.location?.href;
                const isPathnameHome =
                    dataDocument?.contentWindow?.location?.pathname === '/';
                const productName = dataDocument?.contentWindow?.productName;
                if (id === 'shopping-online-screen-iframe') {
                    const printCard = dataDocument?.contentWindow?.document?.querySelector(
                        '.picture__img_resp.IElazySizesClass'
                    );
                    printCard?.addEventListener('click', () => {
                        setOpenPrintDrawer({
                            src: `${process.env.REACT_APP_DOT_COM_URL}/services/printing/`,
                            show: true,
                        });
                    });

                    if (!urlChangeToHome && isPathnameHome) {
                        urlChangeToHome = true;
                        handleSetHomePage(true);
                    } else if (urlChangeToHome && !isPathnameHome) {
                        urlChangeToHome = false;
                        handleSetHomePage(false);
                    }
                }
                if (
                    !urlChangeToPrint &&
                    urlData?.includes('/services/printing')
                ) {
                    urlChangeToPrint = true;
                    setPrintPage(true);
                } else if (
                    urlChangeToPrint &&
                    !urlData?.includes('/services/printing')
                ) {
                    urlChangeToPrint = false;
                    setPrintPage(false);
                } else if (!urlChangeToCart && urlData?.includes('/cart')) {
                    urlChangeToCart = true;
                    handleSetCartPage(true);
                } else if (urlChangeToCart && !urlData?.includes('/cart')) {
                    urlChangeToCart = false;
                    handleSetCartPage(false);
                } else if (!urlChangeToOcp && urlData?.includes('/ocp')) {
                    urlChangeToOcp = true;
                    setOcpPage(true);
                } else if (urlChangeToOcp && !urlData?.includes('/ocp')) {
                    urlChangeToOcp = false;
                    setOcpPage(false);
                } else if (
                    !isInitialPointHistoryUrl &&
                    pointHistoryUrl === urlData
                ) {
                    isInitialPointHistoryUrl = true;
                    setPointHistoryPage(true);
                } else if (
                    isInitialPointHistoryUrl &&
                    pointHistoryUrl !== urlData
                ) {
                    isInitialPointHistoryUrl = false;
                    setPointHistoryPage(false);
                } else if (
                    !urlChangeToItemPage &&
                    productName &&
                    productName !== ''
                ) {
                    urlChangeToItemPage = true;
                    setItemPage(true);
                } else if (urlChangeToItemPage && productName === '') {
                    urlChangeToItemPage = false;
                    setItemPage(false);
                }

                if (id === 'point-history' && currentIframeUrl !== urlData) {
                    hideBreadcrumb();
                    setCurrentIframeUrl(urlData);
                }
                if (
                    urlData?.includes('/grs/rewards/sr/loyaltycenter') &&
                    !urlData?.includes(drawerUrl)
                ) {
                    push(ROUTES.rewardsWallet);
                }
                if (urlData?.includes('/grs/rewards/getcoupons')) {
                    localStorage.setItem('viewcoupon', 'true');
                    push(ROUTES.rewardsWallet);
                }
                if (urlData?.includes('deals/Staples-Daily-Deals')) {
                    localStorage.setItem(
                        'optimovePushNotificationData',
                        JSON.stringify({
                            url: urlData,
                        })
                    );
                    push(ROUTES.shop);
                }
            }
        }, 1000);

        return () => {
            window.removeEventListener('message', msgHandler);
            updateIframeId('');
            clearInterval(interval);
            setPointHistoryPage(false);
            setIframeLoading(true);
            urlChangeToCart = false;
            urlChangeToPrint = false;
            urlChangeToOcp = false;
            isInitialPointHistoryUrl = false;
            setOcpPage(false);
            handleSetCartPage(false);
            setItemPage(false);
            urlChangeToItemPage = false;
            urlChangeToHome = false;
        };
    }, []);

    const handleOnLoadFn = () => {
        SetSomniAppCookies();
        try {
            const iframe = (
                document?.getElementById(id) as HTMLIFrameElement as any
            )?.contentWindow;
            if (
                iframe.document.querySelector(
                    '.breadcrumbs-ux2dot0__breadcrumbs_container'
                )
            ) {
                iframe.document.querySelector(
                    '.breadcrumbs-ux2dot0__breadcrumbs_container'
                ).style.display = 'none';
            }
            setIsLoading(false);
            setIframeLoading(false);
        } catch (error) {
            setIsLoading(false);
            setIframeLoading(false);
        }
    };

    return (
        <div className={classes.iframeContainer}>
            {isLoading && (
                <div className={classes.spinnerContainer}>
                    <Player autoplay loop src={lottieSrc} />
                </div>
            )}
            <iframe
                title={title}
                id={id}
                className={classes.iframe}
                style={{ height: height ?? height }}
                src={src}
                onLoad={handleOnLoadFn}
                sandbox="allow-scripts allow-same-origin allow-forms allow-popups"
                ref={iframeRef}
            />
        </div>
    );
};

export default BrandIframe;
