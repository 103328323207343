import React from 'react';
import clsx from 'clsx';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Typography, makeStyles, ButtonBase } from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import { getStyle } from 'src/lib/utils/draggable-list-utils';
import FullWidthCard from 'src/components/cards/full-width-card';
import { buildDraggableItem } from 'src/components/draggable-reorderable-list';
import COLORS from 'src/lib/colors';

export const LIST_OFFSET = 20;

export type ListInteractionType = 'edit-item' | 'select-item' | 'delete-item';

const useItemStyles = makeStyles({
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1.85rem 0 1.85rem 0',
        minHeight: '7rem',
    },
    wrapperButton: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1.85rem 0 1.85rem 0',
        maxHeight: 120,
    },
    iconArrangeList: {
        marginRight: '1rem',
        marginLeft: '1rem',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 120,
        minWidth: 120,
        marginRight: 20,
    },
    featuredIcon: {
        height: 120,
        width: 120,
        objectFit: 'cover',
    },
    sharedIcon: {
        height: 120,
        width: 120,
        objectFit: 'fill',
    },
    iconList: {
        height: 75,
        width: 75,
    },
    iconChevron: {
        marginRight: '1rem',
    },
    iconEdit: { marginRight: '1rem' },
    editButton: {
        paddingLeft: '1rem',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '-1rem',
        marginBottom: '-1rem',
    },
    centerContent: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        width: '50%',
        marginRight: '1rem',
    },
    sharedListIndicatorContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    sharedListIndicatorIcon: {
        marginRight: '0.25rem',
    },
    sharedListIndicatorText: {
        fontWeight: 'bold',
        fontSize: '0.5rem',
    },
    staplesConnectLogo: {
        height: 10,
        marginBottom: '2px',
        alignSelf: 'flex-start',
    },
    listName: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '0.94rem',
        display: '-webkit-box',
        lineHeight: 'normal',
        WebkitBoxOrient: 'vertical',
        boxOrient: 'vertical',
        textOverflow: 'ellipsis',
        marginBottom: '0.25rem',
        overflow: 'hidden',
    },
    listDescription: {
        textAlign: 'left',
        fontSize: 11,
        lineHeight: 'normal',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        boxOrient: 'vertical',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    twoLineClamp: {
        WebkitLineClamp: 2,
    },
    threeLineClamp: {
        WebkitLineClamp: 3,
    },
});

const sharedByName = makeStyles({
    sharedByContainer: {
        height: '21px',
        minWidth: '120px',
        padding: '0 0.7rem',
        backgroundColor: COLORS.brandCyan,
        position: 'absolute',
        right: '25px',
        top: '0px',
        display: 'flex',
        alignItems: 'center',
        borderBottomRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        justifyContent: 'center',
    },
    nameByShared: {
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: 450,
        lineHeight: '13px',
        textAlign: 'center',
        color: COLORS.primaryWhite,
    },
});

/** commmon wrapper around the product lists  */
function CommonItem({ index, provided, style, isDragging, children }: any) {
    return (
        <div
            id={`list-item-${index}-row`}
            {...provided?.draggableProps}
            {...provided?.dragHandleProps}
            ref={provided?.innerRef}
            style={getStyle({ provided, style, isDragging })}
        >
            <FullWidthCard
                hasManualShadow
                noPadding
                style={{
                    marginTop: `${LIST_OFFSET}px`,
                    marginLeft: 0,
                    marginRight: 0,
                }}
            >
                {children}
            </FullWidthCard>
        </div>
    );
}

export function ReadOnlyItem({
    provided,
    index,
    item,
    style,
    isDragging,
}: any) {
    const classes = useItemStyles();
    const classesShared = sharedByName();
    const { push: goto } = useHistory();
    const { url } = useRouteMatch();
    const { analyticsCustomEvent } = useEventsReporter();
    function onListRowTapped() {
        let uri = `${url}/${item.id}`;
        analyticsCustomEvent(eventMetricsMap.lists, {
            click_text: item.description,
            element_location: 'featured lists',
        });
        optimoveEvents(eventMetricsMap.scm_lists, {
            click_text: item.description,
            element_location: 'featured lists',
        });
        if (item.type) {
            uri = `${uri}?type=${item.type}`;
        }
        goto(uri);
    }
    const isSharedItem = item.type === 'saved-shared-list';
    const featuredItem = item.type === 'featured';
    const isSharedByUser = item.type === 'shared-by-user';
    return (
        <CommonItem
            index={index}
            provided={provided}
            style={style}
            isDragging={isDragging}
        >
            <ButtonBase
                onClick={onListRowTapped}
                className={classes.wrapperButton}
                id={`list-item-${index}-row-select-area`}
            >
                {isSharedItem ? (
                    <div
                        className={classesShared.sharedByContainer}
                        style={{
                            backgroundColor:
                                item?.sharedByRole === 'Supporter'
                                    ? COLORS.brandCyan
                                    : COLORS.rewardsYellow,
                        }}
                        id={`sharedByContainer-${index}`}
                    >
                        <span
                            className={classesShared.nameByShared}
                            style={{
                                color:
                                    item?.sharedByRole === 'Supporter'
                                        ? COLORS.primaryWhite
                                        : COLORS.textBlack,
                            }}
                        >
                            Shared by{' '}
                            {item?.sharedByRole === 'Educator'
                                ? 'teacher, '
                                : ''}
                            <b>{item?.sharedBy} </b>
                        </span>
                    </div>
                ) : null}
                <div
                    className={classes.iconContainer}
                    id={`iconContainer-${index}`}
                >
                    <img
                        className={(() => {
                            if (isSharedItem) {
                                return classes.sharedIcon;
                            }
                            if (featuredItem) {
                                return classes.featuredIcon;
                            }
                            return classes.iconList;
                        })()}
                        src={
                            item.imageUrl ||
                            require('../../assets/icons/iconLists.svg')
                        }
                        alt="product list"
                    />
                </div>
                <div
                    className={classes.centerContent}
                    id={`centerContent-${index}`}
                >
                    {isSharedByUser && (
                        <div
                            className={classes.sharedListIndicatorContainer}
                            id={`list-item-shared-indicator-${index}`}
                        >
                            <img
                                className={classes.sharedListIndicatorIcon}
                                src={require('../../assets/icons/iconUserListShared.svg')}
                                alt="List shared"
                            />
                            <span className={classes.sharedListIndicatorText}>
                                LIST SHARED
                            </span>
                        </div>
                    )}
                    <Typography
                        className={clsx(classes.listName, classes.twoLineClamp)}
                        id={`list-item-${index}-name-text`}
                    >
                        {item.productListName}
                    </Typography>
                    <Typography
                        className={clsx(
                            classes.listDescription,
                            classes.threeLineClamp
                        )}
                        id={`list-item-${index}-description-text`}
                    >
                        {item.description}
                    </Typography>
                </div>
                <div id={`list-item-image-${index}`}>
                    <img
                        className={classes.iconChevron}
                        src={require('../../assets/icons/iconChevron.svg')}
                        alt="chevron"
                        id={`list-item-${index}-detail-button`}
                    />
                </div>
            </ButtonBase>
        </CommonItem>
    );
}
export function EditableItem({
    provided,
    index,
    item,
    itemProps,
    style,
    isDragging,
}: any) {
    const classes = useItemStyles();
    function onListItemEdit() {
        if (itemProps?.itemInteractionHandler) {
            itemProps.itemInteractionHandler(item, 'edit-item');
        }
    }
    function onListItemDelete() {
        if (itemProps?.itemInteractionHandler) {
            itemProps.itemInteractionHandler(item, 'delete-item');
        }
    }

    return (
        <CommonItem
            index={index}
            provided={provided}
            style={style}
            isDragging={isDragging}
        >
            <div className={classes.wrapper}>
                <img
                    className={classes.iconArrangeList}
                    src={require('../../assets/icons/iconArrange.svg')}
                    alt="arrange list"
                />
                <div className={classes.centerContent}>
                    <Typography
                        className={clsx(classes.listName, classes.twoLineClamp)}
                        id={`list-item-${index}-name-text`}
                    >
                        {item.productListName}
                    </Typography>
                    <Typography
                        className={clsx(
                            classes.listDescription,
                            classes.threeLineClamp
                        )}
                        id={`list-item-${index}-description-text`}
                    >
                        {item.description}
                    </Typography>
                </div>
                <div className={classes.buttons}>
                    <ButtonBase
                        className={classes.editButton}
                        onClick={onListItemEdit}
                        id={`list-item-${index}-edit-button`}
                    >
                        <img
                            className={classes.iconEdit}
                            src={require('../../assets/icons/iconEdit.svg')}
                            alt="edit"
                        />
                    </ButtonBase>

                    <ButtonBase
                        className={classes.editButton}
                        onClick={onListItemDelete}
                        id={`list-item-${index}-delete-button`}
                    >
                        <img
                            className={classes.iconEdit}
                            src={require('../../assets/icons/iconDeleteRed.svg')}
                            alt="delete"
                        />
                    </ButtonBase>
                </div>
            </div>
        </CommonItem>
    );
}

export const EditableDraggableItem = buildDraggableItem({
    disabled: false,
    Item: EditableItem,
});
export const ReadOnlyDraggableItem = buildDraggableItem({
    disabled: true,
    Item: ReadOnlyItem,
});
