/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable arrow-body-style */
import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Button as MUIButton,
    makeStyles,
    Typography,
    ButtonBase,
    SwipeableDrawer,
} from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { useRouter } from 'src/hooks';
import bgImage from 'src/assets/bgs/bgRedUserProfile.svg';
import { optimoveEvents } from 'src/screens/optimove';
import NavTop from 'src/components/nav-top';
import MainStoreCard from 'src/components/cards/main-store-card';
import COLORS from 'src/lib/colors';
import ScrollableRow from 'src/components/scrollable-row';
import ScrollableCard from 'src/components/cards/scrollable-card';
import MapPreview, { MapOverlay } from 'src/components/map-preview';
import FullWidthCard from 'src/components/cards/full-width-card';
import { GUTTER_WIDTH } from 'src/theme';
import { storeCommunity } from 'src/lib/utils/store-utils';
import StoreSearchModal from 'src/components/store-search-modal';
import ViewWeeklyAd from 'src/components/view-weekly-ad';
import SearchForProduct from 'src/components/search-for-product';
import useStoreData from 'src/hooks/useStoreData';
import { Store } from 'src/services/types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import RecycleEveryDay from 'src/components/recycle-every-day';
import LocalImpact from 'src/components/local-impact';
import axiosInstance from 'src/lib/api-client/custom-axios';
import useUserInfo from 'src/hooks/useUserInfo';
import InkTonerRecycle from 'src/components/ink-toner-recycle';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import Header from 'src/components/header';
import IconPenEditVector from 'src/components/Icons/IconPenEditVector';
import { Heading, Button, Body } from 'src/components/UI';
import BrandIframe from 'src/components/BrandIframe';
import { mergeClasses } from 'src/stylesheets/utils';
import layout_classes from 'src/stylesheets/layout_classes.module.scss';
import { useTabsUIStateContext } from 'src/lib/contexts/tabs-ui-state-context';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import ROUTES from 'src/lib/routes';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';
import ViewAllDigitalCoupons from 'src/components/view-all-digital-coupons';
import StoreServiceCard from 'src/components/cards/store-service-card';
import TsaPreCheck from 'src/components/tsa-pre-check/tsa-pre-check';
import ShippingServicesHome from 'src/components/shipping-services/shipping-services-list/shipping-services-home';
import CreditCelebrationDialog from 'src/components/credit-celebration-dialog';
import CreateShippingContainer from 'src/components/shipping-services/creating-shipment/creating-shipping-container';
import { guestMode } from 'src/lib/utils/app-utils';
import ShippingServicesContainer from 'src/components/shipping-services/shipping-services-list/shipping-services-container';
import ShippingQuoteEstimatorContainer from 'src/components/shipping-services/shipping-get-quote/shipping-quote-estimator-container';
import { useQuery } from 'react-query';
import { getStaplesRewardsData } from 'src/lib/api-client/wallet-activities';
import { format } from 'date-fns';
import styles from 'src/stylesheets/utilities.module.scss';
import ShippingRewards from 'src/components/shipping-services/shipping-rewards';
import ShippingCard from '../components/shipping-card';
import ProtectionPlans from '../components/protection-plans/protection-plans';
import { CACHE_ONLY, NO_REFETCH } from '../lib/utils/react-query-utils';


const useStyles = makeStyles((theme) => ({
    screen: {
        flexGrow: 1,
        background: COLORS.whiteSmoke,
    },
    darkContent: {
        color: COLORS.primaryWhite,
        marginTop: '0',
        marginBottom: '7.5rem',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    storeHeaderTextContainer: {
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        height: '153.2px',
        [theme.breakpoints.down(520)]: {
            padding: '24px 24px 14px',
            height: '110px',
        },
    },
    communityTitle: {
        textAlign: 'center',
        fontSize: '1.25rem',
        [theme.breakpoints.down(520)]: {
            fontSize: '0.8rem',
        },
    },
    container: {
        minHeight: '9.75rem',
    },
    noStoresContainer: {
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    noStoresIconContainer: {
        textAlign: 'center',
        display: 'block',
    },
    noStoresText: {
        textAlign: 'center',
        fontSize: '1.0625rem',
        fontWeight: 500,
    },
    yourStoreContainer: {
        backgroundSize: "200px",
        backgroundImage: `url(${bgImage})`,
        height: '30vh',
        backgroundColor: COLORS.brandRed,
        userSelect: 'none',
    },
    yourStoreSubContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 15px',
    },
    locationIcon: {
        [theme.breakpoints.down(370)]: {
            width: '1.5rem',
        },
    },
    yourStoreContainerTitle: {
        fontSize: '1.125rem',
        lineHeight: '15px',
        letterSpacing: '-0.37px',
        color: COLORS.primaryWhite,
        padding: '15px 0px 8px 15px',
        [theme.breakpoints.down(370)]: {
            fontSize: '1rem',
            paddingLeft: '18px',
        },
    },
    storeLocation: {
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'center',
    },
    storeLocationText: {
        // fontWeight: 'bold',
        fontSize: '1.5rem !important',
        paddingLeft: '.25rem',
        lineHeight: '28px',
        letterSpacing: '0.0411429px',
        color: COLORS.primaryWhite,
        [theme.breakpoints.down(500)]: {
            fontSize: '1.3rem !important',
            paddingLeft: '.18rem',
        },
        [theme.breakpoints.down(370)]: {
            fontSize: '1.1rem !important',
            paddingLeft: '.12rem',
        },
    },
    blueButton: {
        color: COLORS.primaryWhite,
        fontWeight: 700,
        fontSize: '0.875rem',
        lineHeight: '17px',
        letterSpacing: '-0.336px',
        textDecorationLine: 'underline',
        [theme.breakpoints.down(370)]: {
            fontSize: '0.7rem',
        },
    },
    sectionTitle: {
        fontWeight: 'bold',
        marginBottom: '.85rem',
        marginTop: '1.4212rem',
        fontSize: '1.416rem',
        marginLeft: GUTTER_WIDTH,
    },
    nearByStoreTitle: {
        fontWeight: 500,
        marginBottom: '.85rem',
        marginTop: '1.4212rem',
        fontSize: '1.0625rem',
        marginLeft: GUTTER_WIDTH,
    },
    localStaplesCTA: {
        marginTop: '1rem',
        height: '7rem',
        marginLeft: GUTTER_WIDTH,
        marginRight: GUTTER_WIDTH,
    },
    localStaplesCTALeftContent: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    localStaplesCTATopText: {
        marginTop: '1.188rem',
        marginBottom: '.25rem',
        alignSelf: 'center',
        fontWeight: 'bold',
        fontSize: '.5rem',
        color: COLORS.secondaryNeutralCharcoal,
    },
    localStaplesCTALogo: {
        alignSelf: 'center',
    },
    localStaplesCTABottomText: {
        alignSelf: 'center',
        fontWeight: 'bold',
        marginTop: '.55rem',
        marginBottom: '.55rem',
        color: COLORS.brandCyan,
    },
    localStaplesCTARightImage: {
        objectFit: 'cover',
        width: '7rem',
    },
    nearbyStoreCard: {
        minHeight: '15.3824rem',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'start',
    },
    nearbyStoreAddress: {
        paddingLeft: '1.3376rem',
        paddingRight: '1.3376rem',
        fontSize: '1.1666rem',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        marginTop: '1.5rem',
    },
    selectStore: {
        color: COLORS.brandCyan,
        fontSize: '1.0625rem',
        fontWeight: 500,
        textTransform: 'none',
        paddingLeft: '1.3376rem',
        textAlign: 'left',
    },
    searchForProductWrapper: {
        display: 'flex',
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        width: '100%',
    },
    backButton: {
        right: '1rem',
        position: 'relative',
    },
    nearbyContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    nearbyStoreMainContainerExpandedMode: {
        background: '#ffffff',
        margin: '-9rem 2rem 0rem',
        borderRadius: '15px',
        paddingBottom: '2rem',
        [theme.breakpoints.down(400)]: {
            marginTop: '-9rem',
        },
        [theme.breakpoints.down(380)]: {
            marginTop: '-7rem',
        },
    },
    nearbyStoreMainContainer: {
        background: '#ffffff',
        margin: '-10rem 1rem 0px ',
        borderRadius: '15px',
        [theme.breakpoints.down(400)]: {
            marginTop: '-9rem',
        },
        [theme.breakpoints.down(380)]: {
            marginTop: '-7rem',
        },
    },
    closeBtn: {
        width: '14px',
        height: '14px',
        color: '#858585',
        background: 'none !important',
    },
    iconContainer: {
        display: 'flex',
        gap: '10px',
        padding: '0px 1.3376rem 1rem',
    },
    closeButon: {
        marginRight: '1.25rem',
    },
    indicator: {
        backgroundColor: 'lightgray',
        height: '7px',
        width: '20%',
        margin: '0 auto',
        borderRadius: '2rem',
        position: 'relative',
        top: '0px',
    },
    infoWrapper: {
        padding: '0.625rem 1.625rem 2.063rem 1.625rem',
    },
    infoHeading: {
        // fontSize: '1.625rem',
        // fontWeight: 700,
        lineHeight: 'normal',
        marginTop: '20px',
        marginBottom: '10px',
    },
    infoDrawerText: {
        fontSize: '1rem',
        fontWeight: 400,
        marginBottom: '20px',
    },
    recycleButton: {
        alignSelf: 'center',
        backgroundColor: 'black',
        color: 'white',
        lineHeight: '2.5rem',
        borderRadius: '2rem',
        width: '100%',
        fontWeight: 500,
        // margin: '1.75rem 0',
        fontSize: '0.95rem',
        letterSpacing: '0',
    },
    btnAlign: {
        width: '100%',
    },
    cartHeader: {
        display: 'flex',
        width: '100%',
        padding: '25px 20px',
    },
    backIcon: {
        fontSize: '1.2rem',
        color: COLORS.whiteSmoke,
    },
    title: {
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '22px',
        letterSpacing: '-0.2px',
        color: '#ffffff',
        margin: 'auto',
    },
    storeCredit: {
        margin: '16px',
        padding: '0',
        boxShadow: 'none',
        '& .MuiCardContent-root': {
            padding: '0px',
        },
    },
    storeCreditContainer: {
        padding: '10px 20px',
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
    },
    icon: {
        width: '1.2rem',
        height: '1.2rem',
        marginTop: '0.3rem',
        marginLeft: 'auto',
    },
    superScript: {
        fontSize: '12px',
    },
    infoText: {
        color: COLORS.fordGray,
        fontSize: '14px',
        margin: '0',
    },
    promoText: {
        fontSize: '14px',
    },

}));

function Stores() {
    const {
        mainStore,
        additionalStores,
        placeholderStoresData,
        updateMainStore,
        isFetching,
    } = useStoreData();

    const classes = useStyles();
    const location = useLocation();
    const rewardcenterURL = `${process.env.REACT_APP_DOTCOM_URL}/grs/rewards/sr/loyaltycenter?openrecycledrawer=yes&closebutton=no`;
    const { customEvent, analyticsCustomEvent, analyticsScreenCustomNames } =
        useEventsReporter();
    const [searchModalOpen, setSearchModalOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [recycleData, setRecycleData] = useState();
    const [localShow, setLocalShow] = useState(false);
    const { userInfo } = useUserInfo();
    const isGuestModeOn = guestMode();
    const openInfo = false;
    const [state, setState] = React.useState(openInfo);
    const [joinFree, setJoinFree] = React.useState(false);
    const [storeServicesExternalLink, setStoreServicesExternalLink] =
        useState(false);
    const recycleRef = useRef<HTMLDivElement | null>(null);
    const {
        storeRecycleLink,
        setStoreRecycleLink,
        setDotComUrl,
        storeServicesLink,
        setStoreServicesLink,
        storeLinkInNative,
        setStoreLinkInNative,
        homeLinkInNative,
        setHomeLinkInNative,
        printPage,
    } = useTabsUIStateContext();
    const { navBottomActiveTab, setNavBottomActiveTab } =
        useUserUpdateContext();
    const { fromPath, setFromPath } = useProductBrowseContext();

    const toggleDrawer = (open: boolean) => {
        setState(open);
        // currentMode(open);
    };
    const toggleJoinFree = (open: boolean) => {
        setJoinFree(open);
        // currentMode(open);
    };
    const handleClickLocal = (open: boolean) => {
        setLocalShow(open);
    };

    useEffect(() => {
        toggleDrawer(openInfo);
    }, [openInfo]);
    const [storeCreditData, setStoreCreditData] = useState<any>();
    const { push } = useRouter();

    const { data: staplesRewardsDetail } = useQuery(
        ['details', userInfo?.rewardsNumber],
        () => getStaplesRewardsData(userInfo?.rewardsNumber),
        {
            ...CACHE_ONLY,
        }
    );

    useEffect(() => {
        const storeCredit = staplesRewardsDetail?.data?.couponDataList?.filter(
            (data: any) =>
                data?.couponInfo?.couponClass === 'StoreCredit'
        );
        if (storeCredit?.length) {
            setStoreCreditData(storeCredit[0]);
        }

    }, [staplesRewardsDetail]);
    useEffect(() => {
        if (
            navBottomActiveTab === ROUTES.stores &&
            fromPath === ROUTES.stores
        ) {
            setStoreRecycleLink('');
            setNavBottomActiveTab('');
        }
    }, [navBottomActiveTab]);

    const handleStoreCreditNavigation = (coupon: any) => {
        push(`${ROUTES.coupons}?couponNumber=${coupon}&deepLink=false`)
        analyticsCustomEvent(eventMetricsMap.stores, {
            click_text: `${storeCreditData.couponInfo.couponValue} promo card is ready to redeem!`,
            element_location: 'promo card',
        });
    }

    const showSearchModal = () => {
        analyticsCustomEvent(eventMetricsMap.stores, {
            click_text: 'change location',
            element_location: 'your store information',
            store_details: `${mainStore?.address?.city}-${mainStore?.storeNumber}`,
        });
        optimoveEvents(eventMetricsMap.scm_stores, {
            click_text: 'change location',
            element_location: 'your store information',
            store_details: `${mainStore?.address?.city}-${mainStore?.storeNumber}`,
        });
        setSearchModalOpen(true);
    };
    const handleSearchModalClose = () => {
        setSearchModalOpen(false);
    };

    const getRecycleData = async (pId: string) => {
        // `${userInfo?.preferredStoreId}`
        const response = await axiosInstance.get(
            `${process.env.REACT_APP_STORE}`.replace(
                '%storeIdentifier%',
                `${pId}`
            )
        );
        if (response.data.recycling) {
            setRecycleData(response.data.recycling);
            if (location.hash) {
                const elem = document.getElementById(location.hash.slice(1));
                if (elem) {
                    elem.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    };
    function onStoreSelected(store: Store) {
        updateMainStore(store);
        if (store?.storeNumber) {
            getRecycleData(store?.storeNumber);
        }
        setSearchModalOpen(false);
    }
    useEffect(() => {
        if (userInfo?.preferredStoreId) {
            getRecycleData(userInfo?.preferredStoreId);
        }
    }, [userInfo?.preferredStoreId]);

    useEffect(() => {
        if (userInfo?.rewardsNumber) {
            analyticsScreenCustomNames({
                rewards_number: userInfo?.rewardsNumber,
            });
        } else {
            analyticsScreenCustomNames({
                rewards_number: '',
            });
        }
        if (userInfo?.preferredStoreId) {
            analyticsScreenCustomNames({
                store_id: userInfo?.preferredStoreId,
            });
        } else {
            analyticsScreenCustomNames({
                store_id: '',
            });
        }
    }, []);

    function getOnSelectStoreClick(store: Store) {
        return () => {
            updateMainStore(store);
            customEvent(eventMetricsMap.store_selected);
        };
    }
    const handleExpandedClick = () => {
        customEvent(eventMetricsMap.store_nearby);
        analyticsCustomEvent(eventMetricsMap.stores, {
            click_text: 'nearby store',
            element_location: 'your store information',
            store_details: `${mainStore?.address?.city}-${mainStore?.storeNumber}`,
        });
        optimoveEvents(eventMetricsMap.scm_stores, {
            click_text: 'nearby store',
            element_location: 'your store information',
            store_details: `${mainStore?.address?.city}-${mainStore?.storeNumber}`,
        });
        setIsExpanded(!isExpanded);
    };
    const checkStoreServices = (nearbyStoreData: any) => {
        if (!nearbyStoreData?.storeServices) return [];
        const imageData: any = [];
        nearbyStoreData?.storeServices?.forEach((x: any) => {
            if (
                x.id === 'recyclingServices' ||
                x.id === 'techServices' ||
                x.id === 'printMarketingServices'
            ) {
                imageData.push(x.id);
            }
        });
        return (
            <>
                {imageData.includes('recyclingServices') ? (
                    <div aria-label='This store offers recycling'>
                        <img
                            id="recycled-icon"
                            src={require('../assets/icons/recycled.svg')}
                            alt="This store offers recycling"
                            aria-hidden="true"
                        />
                    </div>
                ) : null}
                {imageData.includes('techServices') ? (
                    <div aria-label='This store offers tech services'>
                        <img
                            id="tech-icon-img"
                            src={require('../assets/icons/techIcon.svg')}
                            alt="This store offers tech services"
                            aria-hidden="true"
                        />
                    </div>
                ) : null}
                {imageData.includes('printMarketingServices') ? (
                    <div aria-label='This store offers print and marketing services'>
                        <img
                            id="print-icon-img"
                            src={require('../assets/icons/printIcon.svg')}
                            alt="This store offers print and marketing services"
                            aria-hidden="true"
                        />
                    </div>
                ) : null}
            </>
        );
    };

    const handleClickOnBackBtnRecycleOnline = () => {
        setStoreRecycleLink('');
    };

    const handleClickOnBackBtnOnline = () => {
        if (storeServicesLink) {
            setStoreServicesLink('');
        }
    };

    if (homeLinkInNative.show) {
        return (
            <Box className={classes.screen}>
                <Header
                    backButton
                    onClickHandler={() =>
                        setHomeLinkInNative({ src: '', show: false })
                    }
                />
                <BrandIframe
                    src={homeLinkInNative.src}
                    title="home-page"
                    id="home-screen"
                />
            </Box>
        );
    }

    if (storeRecycleLink) {
        return (
            <Box className={classes.screen}>
                <NavTop
                    withTopScroll
                    bgColor={COLORS.brandRed}
                    bgImageSrc={require('../assets/bgs/header-bg.svg')}
                    noBorderRadius
                    minHeight="0"
                >
                    <Box className={classes.cartHeader}>
                        <ButtonBase onClick={handleClickOnBackBtnRecycleOnline}>
                            <ArrowBackIosRoundedIcon
                                className={classes.backIcon}
                            />
                        </ButtonBase>
                        <Typography className={classes.title}>
                            Store Services
                        </Typography>
                    </Box>
                </NavTop>
                <BrandIframe
                    title="store-recycle"
                    id="store-recycle-iframe"
                    src={storeRecycleLink}
                />
            </Box>
        );
    }
    if (storeLinkInNative.show) {
        return (
            <Box className={classes.screen}>
                {storeLinkInNative.src.includes('/stores/services/tsa') ||
                    storeLinkInNative.src.includes('/stores/tech/pcsupport') ||
                    storeLinkInNative.src.includes('/stores/services/tsa#participating-locations') ?
                    (<NavTop
                        withTopScroll
                        bgColor={COLORS.brandRed}
                        bgImageSrc={require('../assets/bgs/header-bg.svg')}
                        noBorderRadius
                        minHeight="0"
                    >
                        <Box className={classes.cartHeader}>
                            <ButtonBase onClick={() => setStoreLinkInNative({ src: '', show: false })}>
                                <ArrowBackIosRoundedIcon
                                    className={classes.backIcon}
                                />
                            </ButtonBase>
                            <Typography className={classes.title}>
                                Store Services
                            </Typography>
                        </Box>
                    </NavTop>
                    ) : (
                        <Header
                            backButton
                            onClickHandler={() =>
                                setStoreLinkInNative({ src: '', show: false })
                            }
                        />
                    )}
                <BrandIframe
                    src={storeLinkInNative.src}
                    title="store-page"
                    id="store-screen"
                />
            </Box>
        );
    }
    if (storeServicesLink !== '' && !storeServicesExternalLink) {
        return (
            <Box className={classes.screen}>
                {storeServicesLink.includes('printing') ? (
                    <NavTop
                        withTopScroll
                        bgColor={COLORS.brandRed}
                        bgImageSrc={require('../assets/bgs/header-bg.svg')}
                        noBorderRadius
                        minHeight="0"
                    >
                        <Box className={classes.cartHeader}>
                            <ButtonBase onClick={handleClickOnBackBtnOnline}>
                                <ArrowBackIosRoundedIcon
                                    className={classes.backIcon}
                                />
                            </ButtonBase>
                            <Typography className={classes.title}>
                                Print Shop
                            </Typography>
                        </Box>
                    </NavTop>
                ) : (
                    <NavTop
                        withTopScroll
                        bgColor={COLORS.brandRed}
                        bgImageSrc={require('../assets/bgs/header-bg.svg')}
                        noBorderRadius
                        minHeight="0"
                    >
                        <Box className={classes.cartHeader}>
                            <ButtonBase onClick={handleClickOnBackBtnOnline}>
                                <ArrowBackIosRoundedIcon
                                    className={classes.backIcon}
                                />
                            </ButtonBase>
                            <Typography className={classes.title}>
                                Store Services
                            </Typography>
                        </Box>
                    </NavTop>
                )}

                <BrandIframe
                    title="store-services"
                    id="store-services-iframe"
                    src={storeServicesLink}
                    height={printPage ? 'calc(100vh - 72px)' : 'calc(100vh - 145px)'}
                />
            </Box>
        );
    }

    return (
        <Box className={mergeClasses(classes.screen)}>
            <Header />
            <Box className={classes.yourStoreContainer}>
                <Typography className={classes.yourStoreContainerTitle}>
                    Your store
                </Typography>
                <Box className={classes.yourStoreSubContainer}>
                    <Box className={classes.storeLocation}>
                        <Body
                            className={classes.storeLocationText}
                            weight='bold'
                            id="YourStoreLocation"
                        >
                            {storeCommunity(mainStore) || 'Unknown'}
                        </Body>
                    </Box>
                    <MUIButton
                        aria-label='Edit Change your store'
                        id="store-screen-store-change-button"
                        onClick={showSearchModal}
                        className={classes.blueButton}
                    >
                        <IconPenEditVector />
                    </MUIButton>
                </Box>
            </Box>
            <div
                className={
                    isExpanded
                        ? classes.nearbyStoreMainContainerExpandedMode
                        : classes.nearbyStoreMainContainer
                }
            >
                <MainStoreCard
                    id="main-store-card"
                    storeData={mainStore}
                    isLoadingStore={isFetching}
                    onClick={handleExpandedClick}
                    isExpanded={isExpanded}
                    storeServices={checkStoreServices}
                />
                {isExpanded ? (
                    <div id="near-by-store-container">
                        <div className={classes.nearbyContainer}>
                            <Typography className={classes.nearByStoreTitle}>
                                Nearby Stores
                            </Typography>
                            <ButtonBase
                                aria-label='close'
                                className={classes.closeButon}
                                onClick={handleExpandedClick}
                            >
                                <img
                                    aria-hidden="true"
                                    id="print-icon-img"
                                    src={require('../assets/icons/closeNearByStore.svg')}
                                    alt="close icon"
                                />
                            </ButtonBase>
                        </div>
                        {!isFetching && !additionalStores?.length && (
                            <FullWidthCard
                                cardContainerClassName={classes.container}
                                noPadding
                                hasManualShadow
                                id="community-card"
                            >
                                <div className={classes.noStoresContainer}>
                                    <span
                                        className={
                                            classes.noStoresIconContainer
                                        }
                                    >
                                        <img
                                            src={require('../assets/icons/iconNoStore.svg')}
                                            alt="no stores icon"
                                        />
                                    </span>

                                    <Typography
                                        className={classes.noStoresText}
                                    >
                                        There are no nearby stores.
                                    </Typography>
                                </div>
                            </FullWidthCard>
                        )}
                        <ScrollableRow id="nearby-stores-scrollable-row">
                            {isFetching
                                ? placeholderStoresData.map(
                                    (s: any, i: number) => (
                                        <ScrollableCard
                                            minCardWidth="13.3760rem"
                                            key={s.id}
                                            noPadding
                                            hasManualShadow
                                            loading
                                            id={`nearby-store-card-${i}`}
                                        >
                                            <div
                                                className={
                                                    classes.nearbyStoreCard
                                                }
                                            >
                                                &nbsp;
                                            </div>
                                        </ScrollableCard>
                                    )
                                )
                                : additionalStores.map(
                                    (s: Store, i: number) => (
                                        <ScrollableCard
                                            minCardWidth="13.3760rem"
                                            key={s.id}
                                            noPadding
                                            hasManualShadow
                                            id={`nearby-store-card-${i}`}
                                        >
                                            <div
                                                className={
                                                    classes.nearbyStoreCard
                                                }
                                            >
                                                <MapOverlay>
                                                    <MapPreview
                                                        id={`nearby-store-map-${i}`}
                                                        mapHeight={160}
                                                        withBorderRadius
                                                        center={{
                                                            latitude:
                                                                s.latitude,
                                                            longitude:
                                                                s.longitude,
                                                        }}
                                                    />
                                                </MapOverlay>
                                                <div
                                                    className={
                                                        classes.nearbyStoreAddress
                                                    }
                                                >
                                                    <Typography
                                                        id={`nearby-store-address-${i}-street-text`}
                                                    >
                                                        {
                                                            s?.address
                                                                ?.address_1
                                                        }
                                                    </Typography>
                                                    <Typography
                                                        id={`nearby-store-address-${i}-city-text`}
                                                    >
                                                        {s?.address?.city},{' '}
                                                        {s?.address?.region}{' '}
                                                        {
                                                            s?.address
                                                                ?.postal_code
                                                        }
                                                    </Typography>
                                                </div>
                                                <MUIButton
                                                    id={`nearby-store-select-store-${i}-button`}
                                                    className={
                                                        classes.selectStore
                                                    }
                                                    onClick={getOnSelectStoreClick(
                                                        s
                                                    )}
                                                >
                                                    Select store
                                                </MUIButton>
                                            </div>
                                            <div
                                                className={
                                                    classes.iconContainer
                                                }
                                            >
                                                {checkStoreServices(s)}
                                            </div>
                                        </ScrollableCard>
                                    )
                                )}
                        </ScrollableRow>
                    </div>
                ) : null}
            </div>

            {storeCreditData?.couponInfo?.couponValue && (
                <FullWidthCard
                    cardContainerClassName={classes.storeCredit}
                    hasManualShadow
                >
                    <Box className={classes.storeCreditContainer} role='button' onClick={() => handleStoreCreditNavigation(storeCreditData?.couponNumber)}>
                        <Heading size={26} weight="bold" type="h2" ariaLabel={`Your ${storeCreditData.couponInfo.couponValue} $`}>
                            <sup className={classes.superScript}>$</sup>{storeCreditData.couponInfo.couponValue}
                        </Heading>
                        <div className={classes.promoText}>
                            <strong>promo card </strong>is now available!

                            <p className={classes.infoText}>In store only. <span aria-label='Expires'>Exp&nbsp;</span>
                                {format(
                                    new Date(storeCreditData.couponInfo.expirationDate),
                                    'M/d/yy'
                                )}</p>


                        </div>

                        <div style={{ marginLeft: 'auto' }}>
                            <button className={styles.ariaButton} type='button' aria-label='Go to promo card'>
                                <img aria-hidden="true"
                                    className={classes.icon}
                                    src={require('../assets/icons/iconChevron.svg')}
                                    alt="chevron"
                                />
                            </button>
                        </div>
                    </Box>
                </FullWidthCard>
            )}

            <StoreServiceCard
                storeData={mainStore}
                recycleRef={recycleRef}
                openStoreSearch={() => setSearchModalOpen(true)}
                storeServicesLink={storeServicesLink}
                setStoreServicesLink={setStoreServicesLink}
                storeServicesExternalLink={storeServicesExternalLink}
                setStoreServicesExternalLink={setStoreServicesExternalLink}
            />
            <ViewAllDigitalCoupons
                userInfo={userInfo}
                setDotComUrl={setDotComUrl}
                setStoreLinkInNative={setStoreServicesLink}
            />
            {/* {recycleData && ( */}
            <div ref={recycleRef} id="recycleData">
                <RecycleEveryDay
                    recycleData={recycleData}
                    toggleDrawer={toggleDrawer}
                    setStoreRecycleLink={setStoreRecycleLink}
                    toggleLocal={handleClickLocal}
                    toggleJoinFree={toggleJoinFree}
                />
                {localShow && <LocalImpact recycleData={recycleData} />}
            </div>
            {/* )} */}
            <TsaPreCheck />
            {/* to remove ups box from screen */}
            {!isGuestModeOn && <ShippingCard />}

            <ShippingRewards />
            <ProtectionPlans />
            <SwipeableDrawer
                anchor="bottom"
                open={state}
                onClose={() => toggleDrawer(false)}
                onOpen={() => toggleDrawer(true)}
            >
                <Box className={classes.infoWrapper}>
                    <div className={classes.indicator}> </div>
                    <Heading
                        type="h2"
                        weight="bold"
                        size={26}
                        className={classes.infoHeading}
                    >
                        Mail-back recycling program
                    </Heading>
                    {/* <Typography className={classes.infoHeading}>
                        Mail-back recycling program
                    </Typography> */}
                    <Typography className={classes.infoDrawerText}>
                        We'll create a shipping label for you to ship your ink
                        and toner cartridges out to be recycled at no cost.
                    </Typography>
                    <Button
                        variant="contained-rounded"
                        bgColor="bgBlack"
                        color="textWhite"
                        onClick={() => setStoreRecycleLink(rewardcenterURL)}
                        className={classes.btnAlign}
                    >
                        Get Started
                    </Button>
                </Box>
            </SwipeableDrawer>

            <StoreSearchModal
                open={searchModalOpen}
                handleClose={handleSearchModalClose}
                nearbyStores={additionalStores}
                mainStore={mainStore}
                onStoreSelected={onStoreSelected}
            />
        </Box>
    );
}

const StoresBaseRouter = () => {
    const match = useRouteMatch();
    const router = useRouter();
    const goBack = () => router.push(ROUTES.stores);

    return (
        <Switch>
            <Route
                path={`${match.path}/shippingServices/createShippingInformation`}
            >
                <CreateShippingContainer />
            </Route>
            <Route path={`${match.path}/shippingServices/labelShipment`}>
                <ShippingServicesContainer />
            </Route>
            <Route
                path={`${match.path}/shippingServices/quoteShippingInformation`}
            >
                <ShippingQuoteEstimatorContainer />
            </Route>
            <Route path={`${match.path}/shippingServices`}>
                <ShippingServicesHome goBack={goBack} />
            </Route>
            <Route path={match.path}>
                <Stores />
            </Route>
        </Switch>
    );
};

export default StoresBaseRouter;
