import React, { useCallback, useState } from 'react';
import { Body, Heading } from 'src/components/UI';
import { eventMetricsMap, useEventsReporter } from 'src/hooks';
import { ActivateButton } from 'src/screens/EasyRewards/OfferList/OfferCard';
import { useAnimationControls } from 'framer-motion';
import { IconSmallCheckmark } from 'src/components/Icons';
import styles from './styles.module.scss';
import '../../../assets/animate/animate.css';

const YourOfferScreen = ({ handleNext }: any) => {
    const [lottieRef, setLottieRef] = useState<any>(null);
    const activateButtonAnimationControls = useAnimationControls();
    const [buttonContent, setButtonContent] = useState(<span>Activate</span>);
    const animationClass =
        'animate__animated animate__fadeIn animate__delay-0.8s';

    const { analyticsCustomEvent } = useEventsReporter();

    const handleActivateAnimation = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            analyticsCustomEvent(eventMetricsMap.coachmarks, {
                click_text: `activate`,
            });
            if (lottieRef) {
                lottieRef.play();
            }
            activateButtonAnimationControls.start({
                backgroundColor: '#ffffff',
                color: '#5b0069',
                animationDuration: '3s',
            });
            setButtonContent(
                <span className={styles.activatedButtonContent}>
                    <IconSmallCheckmark /> Activated
                </span>
            );
            setTimeout(() => {
                handleNext(6);
            }, 1000);
        },
        [lottieRef]
    );
    return (
        <div className={`${styles.mainConatiner} ${animationClass}`}>
            <div className={styles.alignImg}>
                <img
                    src={
                        'https://de-app.staplesconnect.com/images/CoachMarks/cm_img_offers_sm.png'
                    }
                    alt="img"
                />
            </div>
            <div className={styles.absContainer}>
                <div className={styles.boxConatiner}>
                    <Body
                        size={12}
                        className={styles.alignCount}
                        weight="light"
                        lineHeight={16}
                    >
                        5/5
                    </Body>
                    <Heading
                        type="h2"
                        weight="bold"
                        size={26}
                        className={styles.alignheaderDsc}
                    >
                        Activate your offers
                    </Heading>
                    <Body weight="light">
                        {
                            'Earn even more — give it a shot by activating your first offer.'
                        }
                    </Body>
                </div>
                <div className={styles.boxConatiner2}>
                    {/* <img src={featuredOffers?.imagepng} alt="img" /> */}
                    <img
                        src={require('../../../assets/icons/staples-icon.svg')}
                        className={styles.mainIcon}
                        alt="img"
                    />
                    <img
                        src={require('../../Icons/info-icon.svg')}
                        className={styles.infoIcon}
                        alt="img"
                    />

                    <div>
                        <Heading
                            type="h2"
                            size={26}
                            className={styles.alignheaderDsc}
                        >
                            So many points
                        </Heading>
                        <Body size={14} margin="0 0 0 0">
                            on your purchases <br />
                            in store and online.
                        </Body>
                    </div>
                    <ActivateButton
                        buttonContent={buttonContent}
                        activateButtonAnimationControls={
                            activateButtonAnimationControls
                        }
                        setLottieRef={setLottieRef}
                        onClick={handleActivateAnimation}
                    />
                </div>
            </div>
        </div>
    );
};

export default YourOfferScreen;
