/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import SwipeableViews from 'react-swipeable-views';
import { Container, Button, Box, makeStyles } from '@material-ui/core';
import PaginationDots from 'src/components/pagination-dots';
import useRouter from 'src/hooks/useRouter';
import useLocalUserInfo from 'src/hooks/useLocalUserInfo';
import COLORS from 'src/lib/colors';
import { useIsMobileWidth } from 'src/hooks/useWidth';
import OptimalDeviceController from 'src/controllers/optimal-device-controller';
import { LOGIN_ROUTES } from 'src/routers/routes';

type StyleProps = { isMobileWidth: boolean };

const useStyles = makeStyles({
    full: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        maxWidth: 'unset',
        background: COLORS.secondaryNeutralBlack,
        color: COLORS.onboardingTextGray,
    },
    mainWrapper: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& #onboarding-swipeable': {
            overflowY: 'hidden',
            flexGrow: 1,
        },
        '& .react-swipeable-view-container': {
            flexGrow: 1,
            height: '100%',
        },
    },
    dotsAndSkip: {
        display: 'flex',
        width: '100%',
        height: '3.5rem',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    slidesWrapper: ({ isMobileWidth }: StyleProps) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flexGrow: 1,
        overflow: 'hidden',
        maxHeight: isMobileWidth ? '100%' : 'calc(100vh - 7rem - 3.5rem)',
    }),
    slideItem: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexFlow: 'column',
        flexGrow: 1,
        height: '100%',
        overflowY: 'hidden',
    },
    slideTitle: {
        fontSize: '2.75rem',
        letterSpacing: '0.14pt',
        fontWeight: 'bold',
        marginTop: '1.08rem',
        marginBottom: '1.25rem',
        padding: '0px .5rem 0',
    },
    slideImage: {
        maxWidth: '100%',
        objectFit: 'contain',
        objectPosition: 'center bottom',
        marginTop: '1rem',
    },
    slideText: {
        padding: '0px .5rem 0',
        fontSize: '1rem',
        textAlign: 'start',
    },
    bottomWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '2rem 0',
        minHeight: '7rem',
    },
    // TODO: STP-430 move button style declarations into theme
    nextButton: {
        height: '2.8rem',
        borderRadius: '1.2rem',
        width: '92%',
        background: COLORS.brandRed,
        color: COLORS.primaryWhite,
        textTransform: 'none',
        fontSize: '1.4rem',
        '&:hover': {
            backgroundColor: COLORS.brandRed,
        },
        '&:active': {
            backgroundColor: COLORS.darkRed,
        },
    },
    skipLink: {
        textTransform: 'none',
        color: COLORS.primaryWhite,
        minHeight: '3.33rem',
        fontSize: '1rem',
        fontWeight: 100,
        flexShrink: 1,
    },
    balance: {
        minWidth: '4rem',
        flexShrink: 1,
    },
});

const slides = [
    {
        title: 'Shop',
        image: 'screenshots/6.5_shop.png',
        description:
            'Create a shopping list or search for your school supplies list and check it off as you browse your local store.',
    },
    {
        title: 'Earn',
        image: 'screenshots/6.5_earn.png',
        description:
            'Earn Staples Rewards and Classroom Rewards on your in-store purchases, plus it’s free to join.',
    },
    {
        title: 'Redeem',
        image: 'screenshots/6.5_redeem.png',
        description:
            'Open your Wallet to access any earned rewards and bonuses from purchases and ink recycling.',
    },
    {
        title: 'Give',
        image: 'screenshots/6.5_give.png',
        description:
            'View and donate your earned Classroom Rewards to the teachers or schools of your choice.',
    },
    {
        title: 'Save',
        image: 'screenshots/6.5_save.png',
        description:
            'Access exclusive digital coupons and save them to your wallet to make the most of every purchase.',
    },
    {
        title: 'Share',
        image: 'screenshots/6.5_share.png',
        description:
            'Supporting your local Education Community feels awesome, so tell your friends!',
    },
    {
        title: 'Visit',
        image: 'screenshots/6.5_visit.png',
        description:
            'Explore more at your local Staples- The Working and Learning Store.',
        requestPermissionsOnNext: true,
    },
];

function OnboardingScreens() {
    const { replace } = useRouter();
    const isMobileWidth = useIsMobileWidth();
    const classes = useStyles({ isMobileWidth });

    const { userInfo, patchLocalUserInfo } = useLocalUserInfo();
    const { hasFinishedOnboarding } = userInfo;
    const [continueButtonLabel, setContinueButtonLabel] = useState('Next');

    const [activeIndex, setActiveIndex] = React.useState(0);
    const maxSteps = slides.length;
    const getContinueButtonLabel = (index: number) =>
        index === slides.length - 1 ? 'Sign-Up' : 'Next';

    const goToNextSlide = () => {
        let index = activeIndex;

        if (index === maxSteps - 1) {
            patchLocalUserInfo({ hasFinishedOnboarding: true });
        } else {
            index += 1;
        }

        setActiveIndex(index);
        setContinueButtonLabel(getContinueButtonLabel(index));
    };

    const handleChangeIndex = (index: number) => {
        setActiveIndex(index);
        setContinueButtonLabel(getContinueButtonLabel(index));
    };

    function onSkip() {
        patchLocalUserInfo({ hasFinishedOnboarding: true });
    }

    useEffect(() => {
        if (hasFinishedOnboarding) {
            replace(LOGIN_ROUTES.lookup);
        }
    }, [hasFinishedOnboarding]);

    return (
        <>
            <Helmet>
                <meta
                    name="apple-itunes-app"
                    // eslint-disable-next-line max-len
                    content="app-id=1570643833, app-argument=https://apps.apple.com/us/app/staples-connect/id1570643833?itsct=apps_box_link&itscg=30200"
                />
            </Helmet>
            <Container className={classes.full}>
                <div className={classes.mainWrapper}>
                    <Container className={classes.slidesWrapper} maxWidth="xs">
                        <div className={classes.dotsAndSkip}>
                            <div className={classes.balance}>&nbsp;</div>
                            <PaginationDots
                                dots={slides.length}
                                index={activeIndex}
                                onChangeIndex={handleChangeIndex}
                            />
                            <Button
                                id="skip-button"
                                onClick={onSkip}
                                classes={{
                                    root: classes.skipLink,
                                }}
                            >
                                {activeIndex === slides.length - 1
                                    ? 'Sign up'
                                    : 'Skip'}
                            </Button>
                        </div>
                        <SwipeableViews
                            id="onboarding-swipeable"
                            enableMouseEvents
                            index={activeIndex}
                            onChangeIndex={handleChangeIndex}
                        >
                            {slides.map((item, index) => (
                                <Box
                                    className={classes.slideItem}
                                    key={item.title}
                                    id={`onboarding-swipeable-box-${index}`}
                                >
                                    <img
                                        className={classes.slideImage}
                                        id={`onboarding-swipeable-elem-image-${index}`}
                                        src={item.image}
                                        alt="onboarding introduction"
                                    />
                                </Box>
                            ))}
                        </SwipeableViews>
                    </Container>
                    {!isMobileWidth && (
                        <Container
                            className={classes.bottomWrapper}
                            maxWidth="xs"
                        >
                            <Button
                                id="next-button"
                                variant="contained"
                                onClick={goToNextSlide}
                                classes={{
                                    root: classes.nextButton,
                                }}
                            >
                                {continueButtonLabel}
                            </Button>
                        </Container>
                    )}
                </div>
            </Container>
            <OptimalDeviceController />
        </>
    );
}

/**
 * Contains the main bottom nav bar and the five core screens
 */
export default OnboardingScreens;
