/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence, useScroll } from 'framer-motion';
import Button from 'src/components/UI/Button';
import {
    IconEasyRewardsHorizontal,
    DigitalWallet,
    DownChevron,
} from 'src/components/Icons';
import { getLoyaltyAccountDetails } from 'src/services/easyRewardsServices';
import {
    useUserInfo,
    useRouter,
    useEventsReporter,
    eventMetricsMap,
} from 'src/hooks';
import { Body } from 'src/components/UI/Typography';
import { REWARDS_ENROLLMENT_ROUTES } from 'src/routers/routes';
import { mergeClasses } from 'src/stylesheets/utils';
import { Player } from '@lottiefiles/react-lottie-player';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { guestMode, numberWithCommas } from 'src/lib/utils/app-utils';
import UserRewardsDigitalCardDrawer from '../UserRewardsDigitalCardDrawer';
import styles from './styles.module.scss';
import PointHistoryDrawer from '../PointHistoryDrawer';
import layout_classes from '../../../stylesheets/layout_classes.module.scss';
import axiosInstance from '../../../lib/api-client/custom-axios';
import { rewardsPostLoyaltyAccountURL } from '../../../services/urls';

// TODO Define props type
function Header({ activeTabIndex, toggleIndex, containerRef }: any) {
    const { userInfo } = useUserInfo();
    const { push } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const [availablePoints, setAvailablePoints] = useState<number>(0);
    const [lifeTimePoints, setLifeTimePoints] = useState<number>(0);
    const [isPointInfoDrawerOpen, setIsPointDrawerOpen] = useState(false);
    const [rewardstiercode, setRewardstiercode] = useState('');
    const [isVariantTypePointInfo, setVariantTypePointInfo] = useState(false);
    const [currentAnimationStep, setCurrentAnimationStep] = useState(0);
    const [loyaltyData, setLoyaltyData] = useState<any>({});
    const isGuestModeOn = guestMode();

    const [
        isUserRewardsDigitalCardDrawerOpen,
        toggleIsUserRewardsDigitalCardDrawerOpen,
    ] = useState(false);
    const { isPointHistoryDrawerOpen, setPointHistoryDrawer } =
        useUserUpdateContext();

    getLoyaltyAccountDetails({
        config: {
            enabled: !!userInfo?.rewardsNumber,
            onSuccess: (res) => {
                setAvailablePoints(
                    res?.accounts[0]?.walletinfo?.balances?.usable
                );
                setLifeTimePoints(
                    res?.accounts[0]?.walletinfo?.balances?.lifetime
                );
                setRewardstiercode(res?.accounts[0]?.rewardstiercode);
            },
        },
        rewardsNumber: userInfo?.rewardsNumber,
    });

    useEffect(() => {
        if (isPointHistoryDrawerOpen) {
            setIsPointDrawerOpen(true);
            setPointHistoryDrawer(false);
        }
    }, [isPointHistoryDrawerOpen]);

    useEffect(() => {
        if (userInfo) {
            const loyaltyPayload = {
                accountIdentifier: userInfo?.rewardsNumber,
                identifierType: 'REWARDS',
            };
            axiosInstance
                .post(`${rewardsPostLoyaltyAccountURL}`, loyaltyPayload)
                .then((loyaltyRes: any) => {
                    const accounts = loyaltyRes?.data?.accounts;
                    setLoyaltyData(
                        accounts && accounts[0].walletinfo?.balances
                    );
                })
                .catch((error) => {
                    setLoyaltyData({});
                });
        }
    }, [userInfo]);

    const handlePointsBtnClick = () => {
        if (userInfo && !userInfo.rewardsNumber) {
            push({
                pathname: REWARDS_ENROLLMENT_ROUTES.welcome,
                state: {
                    isNonMigrated: true,
                },
            });
        } else {
            setIsPointDrawerOpen(true);
        }
        analyticsCustomEvent(eventMetricsMap.easy_rewards, {
            click_text: 'rewards info',
            element_location: 'rewards_navigation',
            event_rewards_number: userInfo?.rewardsNumber || '',
        });
        analyticsCustomEvent(eventMetricsMap.screen_view, {
            firebase_screen: `app_easy rewards`,
            firebase_screen_class: `app_easy rewards`,
        });
    };

    const animationElements = [
        <motion.span
            className={mergeClasses([
                layout_classes.flex,
                layout_classes.colGap2,
                layout_classes.alignCenter,
            ])}
            key={0}
            initial={{ opacity: 1 }}
            animate={{
                opacity: 1,
                transition: { duration: 3 },
            }}
            onAnimationComplete={() => setCurrentAnimationStep(1)}
        >
            Hi {userInfo?.firstName}
        </motion.span>,
        <motion.span
            className={mergeClasses([
                layout_classes.flex,
                layout_classes.colGap2,
                layout_classes.alignCenter,
            ])}
            key={1}
            initial={{ opacity: 0 }}
            animate={{
                opacity: 1,
                transition: { duration: 0.5 },
            }}
        >
            {availablePoints && numberWithCommas?.(availablePoints?.toString())}{' '}
            pts
            <Player
                onEvent={(event) => {
                    if (event === 'complete') {
                        setCurrentAnimationStep(2);
                    }
                }}
                autoplay
                src={require('../../../assets/lotties/header_icon_coin_animated.json')}
                className={styles.coinIcon}
            />
        </motion.span>,
        <motion.span
            className={mergeClasses([
                layout_classes.flex,
                layout_classes.colGap2,
                layout_classes.alignCenter,
            ])}
            key={2}
            initial={{ opacity: 0 }}
            animate={{
                opacity: 1,
                transition: { duration: 0.5 },
            }}
        >
            <span style={{ color: 'lightgreen' }}>
                ${loyaltyData?.usablevalue}
            </span>

            <Player
                onEvent={(event) => {
                    if (event === 'complete') {
                        setCurrentAnimationStep(1);
                    }
                }}
                autoplay
                src={require('../../../assets/lotties/header_icon_coin_animated.json')}
                className={styles.coinIcon}
            />
        </motion.span>,
    ];

    const hanRewardsWorks = (bool: boolean) => {
        setVariantTypePointInfo(bool);
    };

    return (
        <>
            <AnimatePresence>
                <div className={styles.logoContainer}>
                    <div className={styles.logoWrapper}>
                        <IconEasyRewardsHorizontal
                            width={160}
                            fillColor="fillWhite"
                        />
                        {userInfo?.rewardsNumber && (
                            <>
                                <motion.button
                                    onClick={handlePointsBtnClick}
                                    layout
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: '#3e0047',
                                        borderRadius: '800px',
                                        padding: '6px',
                                        border: 'none',
                                        fontFamily: 'StaplesNormsMedium',
                                        color: 'white',
                                        height: 34,
                                        fontSize: 15,
                                    }}
                                    aria-label={`Hi, ${userInfo?.firstName}. You currently have ${availablePoints && numberWithCommas?.(availablePoints?.toString())} Easy Rewards points which is the same as ${loyaltyData?.usablevalue} dollars.`}
                                >
                                    {animationElements[currentAnimationStep]}
                                </motion.button>
                            </>
                        )}
                    </div>
                </div>
            </AnimatePresence>
            {!isGuestModeOn ? <div className={styles.message}>
                <motion.div
                    initial={{ opacity: 0, height: 0, overflow: 'hidden' }}
                    whileInView={{
                        opacity: 1,
                        height: 'auto',
                    }}
                    viewport={{ amount: 'all', margin: '-15px 0px 0px 0px' }}
                    transition={{ duration: 0.5 }}
                >
                    {lifeTimePoints === 0 ? (
                        <Body
                            className={styles.underlineText}
                            size={12}
                            weight="light"
                            color="primaryWhite"
                        >
                            <span onClick={handlePointsBtnClick}>
                                <img
                                    src={require('../../../assets/icons/IconInformationcircle.svg')}
                                    className={styles.iconImg}
                                    alt="IconInformationcircle"
                                />
                                How it works?
                            </span>
                        </Body>
                    ) : (
                        <Body
                            className={styles.underlineText}
                            size={12}
                            weight="light"
                            color="primaryWhite"
                        >
                            <span onClick={handlePointsBtnClick}>
                                <img
                                    src={require('../../../assets/icons/IconPointsHistory.svg')}
                                    className={styles.iconImg}
                                    alt="IconPointsHistory"
                                />{' '}
                                Points History
                            </span>
                        </Body>
                    )}
                </motion.div>
            </div> : null}
            {!isGuestModeOn ? <div className={styles.headerWrapper}>
                {/* switch button start */}
                <motion.div
                    initial={{ opacity: 0, height: 0, overflow: 'hidden' }}
                    whileInView={{
                        opacity: 1,
                        height: 'auto',
                    }}
                    viewport={{ amount: 'all', margin: '-15px 0px 0px 0px' }}
                    transition={{ duration: 0.5 }}
                    className={styles.wrapper}
                >
                    <div
                        className={`${styles.leftBtnWrapper} ${activeTabIndex === 1 && styles.leftActive
                            }`}
                    >
                        <Button
                            variant="text"
                            role='tab'
                            className={`${activeTabIndex === 1 ? styles.leftActive : styles.textBlack}`}
                            onClick={() => {
                                analyticsCustomEvent(
                                    eventMetricsMap.easy_rewards,
                                    {
                                        click_text: 'Earn points',
                                        element_location: 'rewards_navigation',
                                        event_rewards_number: userInfo?.rewardsNumber || '',
                                    }
                                );
                                toggleIndex(1);
                            }}
                        >
                            <Body size={16} textAlign="textCenter" weight="bold">
                                <span
                                    tabIndex={0}
                                    aria-selected={activeTabIndex === 1}
                                    id="earn_point_id"
                                    aria-label="Earn points, View all Easy Rewards offers here tab"
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' || e.key === ' ') {
                                            e.preventDefault();
                                            analyticsCustomEvent(
                                                eventMetricsMap.easy_rewards,
                                                {
                                                    click_text: 'Earn points',
                                                    element_location: 'rewards_navigation',
                                                    event_rewards_number: userInfo?.rewardsNumber || '',
                                                }
                                            );
                                            toggleIndex(1);
                                        }
                                    }}
                                >
                                    Earn points
                                </span>
                            </Body>
                        </Button>

                    </div>
                    <div
                        className={`${styles.rightBtnWrapper} ${activeTabIndex === 2 && styles.rightActive
                            }`}
                    >
                        <Button
                            variant="text"
                            role='tab'
                            className={`${activeTabIndex === 2 ? styles.rightActive : styles.textBlack}`}
                            onClick={() => {
                                analyticsCustomEvent(
                                    eventMetricsMap.easy_rewards,
                                    {
                                        click_text: 'Store coupons',
                                        element_location: 'rewards_navigation',
                                        event_rewards_number: userInfo?.rewardsNumber || '',
                                    }
                                );
                                toggleIndex(2);
                            }}
                        >
                            <Body size={16} textAlign="textCenter" weight="bold">
                                <span
                                    tabIndex={0}
                                    aria-selected={activeTabIndex === 2}
                                    id="store_coupons_id"
                                    aria-label="Store coupons, View savings on all your favorite products and services tab"
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' || e.key === ' ') {
                                            e.preventDefault();
                                            analyticsCustomEvent(
                                                eventMetricsMap.easy_rewards,
                                                {
                                                    click_text: 'Store coupons',
                                                    element_location: 'rewards_navigation',
                                                    event_rewards_number: userInfo?.rewardsNumber || '',
                                                }
                                            );
                                            toggleIndex(2);
                                        }
                                    }}
                                >
                                    Store coupons
                                </span>
                            </Body>
                        </Button>

                    </div>
                </motion.div>
                {/* switch button end */}
            </div> : null}
            {userInfo?.rewardsNumber && (
                <motion.div
                    initial={{ opacity: 0, height: 0, overflow: 'hidden' }}
                    whileInView={{
                        opacity: 1,
                        height: 'auto',
                    }}
                    viewport={{ amount: 'all', margin: '-15px 0px 0px 0px' }}
                    transition={{ duration: 0.5 }}
                    onClick={() => {
                        analyticsCustomEvent(eventMetricsMap.easy_rewards, {
                            click_text: 'show my card',
                            element_location:
                                activeTabIndex === 1
                                    ? 'earn points'
                                    : 'get coupons',
                            event_rewards_number: userInfo?.rewardsNumber || '',
                        });
                        analyticsCustomEvent(eventMetricsMap.screen_view, {
                            firebase_screen: `app_easy rewards`,
                            firebase_screen_class: `app_easy rewards`,
                        });
                        toggleIsUserRewardsDigitalCardDrawerOpen(true);
                    }}
                    className={styles.digitalCard}
                    role="button"
                    aria-label="Scan my Easy Rewards number, Brings up your Easy Rewards number to scan in a store"
                    tabIndex={0}
                >
                    <DigitalWallet />
                    <Body className={styles.scancode}>
                        Scan my
                        <span className={styles.scanIcon}>
                            <IconEasyRewardsHorizontal
                                width={99}
                                fillColor="fillPurple"
                            />
                        </span>
                        number{' '}
                        <span className={styles.scanIconLast}>
                            <DownChevron />
                        </span>
                    </Body>
                </motion.div>

            )}
            <div className={styles.lineSeparator} />
            <UserRewardsDigitalCardDrawer
                open={isUserRewardsDigitalCardDrawerOpen}
                toggleOpen={toggleIsUserRewardsDigitalCardDrawerOpen}
                rewardstiercode={rewardstiercode}
            />
            {isPointInfoDrawerOpen ? <PointHistoryDrawer
                open={isPointInfoDrawerOpen}
                setOpen={setIsPointDrawerOpen}
                variant={
                    lifeTimePoints > 0 && !isVariantTypePointInfo
                        ? 'points-history'
                        : 'points-info'
                }
                hanRewardsWorks={hanRewardsWorks}
            /> : null}
        </>
    );
}
export default Header;
