/* eslint-disable max-len */
import React from 'react';

function IconVector() {
    return (
        <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17 1L7.18827 15L1 9.23811"
                stroke="#81C671"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default IconVector;
