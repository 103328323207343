/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';
import platform from 'platform-detect';
import 'src/index.css';
import reportWebVitals from 'src/report-web-vitals';
import Log from 'src/lib/logging';
import * as serviceWorkerRegistration from 'src/service-worker-registration';
import App from './app';

declare global {
    interface Window {
        Analytics: {
            traffic: {
                pagename: any;
            };
            global: {
                visitor: any;
            };
        };
    }
}

// we need to add a listener here because we can't afford to wait for rendering to finish
window.addEventListener('beforeinstallprompt', (e: any) => {
    e.preventDefault(); // Prevents prompt display
    window.globalCapturedInstallPromptEvent = e;
    Log.serviceWorker(
        'window event captured',
        window.globalCapturedInstallPromptEvent
    );
});

// prevents the load button from appearing if the browser says that it's not mobile
window.addEventListener('load', () => {
    console.log("hello test index.tsx")
    if (!platform?.android && !platform?.ios) {
        Log.serviceWorker(
            'non-mobile browser detected. removing option to install'
        );
        if (document.querySelector('link[rel="manifest"]')) {
            document.querySelector('link[rel="manifest"]')?.remove();
        }
    }
});

window?.optimoveSDK?.API?.setPushOpenedListener((push: any) => {
    console.log("🚀 ~ window?.optimoveSDK.API.setPushOpenedListener ~ push:", push);
    if (push?.data) {
        const notification = JSON.stringify(push.data)
        console.log("🚀 ~ window?.optimoveSDK.API.setPushOpenedListener ~ push:", notification);
        localStorage.setItem('optimovePushNotificationData', notification);
    }
})
console.log("hello test index.tsx")
ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
