/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import {
    Box,
    ButtonBase,
    Button,
    FormControl,
    makeStyles,
    Typography,
    ListItem,
    ListItemText,
    List,
    Drawer,
    Link,
} from '@material-ui/core';
import COLORS from 'src/lib/colors';
import ROUTES from 'src/lib/routes';
import useRouter from 'src/hooks/useRouter';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import LoadingButton from 'src/components/loading-button';
import { SessionManager } from 'src/lib/api-client/sessionManager';
import { hideEmail } from 'src/lib/utils/hide-email';
import { LOGIN_ROUTES } from 'src/routers/routes';
import { useUserState } from 'src/hooks';
import AppLink from '../link';
import { sendEmailToUser } from './pwd-reset';
import LoadingOverlay from '../loading-overlay';
import FullWidthCard from '../cards/full-width-card';
import NavTop from '../nav-top';
import CommonDialog from '../common-dialog';

const useStyles = makeStyles({
    screenload: {
        flexGrow: 1,
        background: COLORS.whiteSmoke,
        '& #pwd-screen': {
            display: 'flex',
            alignItems: 'baseline',
            borderRadius: 0,
            height: '17px',
        },
    },
    container: {
        minHeight: '19.5rem',
        borderRadius: '15px',
        padding: '24px',
        margin: '-5rem 1.5rem 0px',
    },
    headerSubTitleFound: {
        width: '265px',
        color: COLORS.textBlack,
        fontSize: '17px',
        lineHeight: '22px',
        letterspacing: '-0.22499999403953552px',
        textAlign: 'center',
    },
    headerTitle: {
        color: COLORS.whiteSmoke,
        fontSize: '24px',
        fontWeight: 700,
        marginBottom: '100px',
        lineHeight: '22px',
        letterspacing: '-0.22499999403953552px',
        textAlign: 'center',
    },
    contentContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
    },
    backIcon: {
        marginLeft: '24px',
        marginTop: '2.4rem',
        marginBottom: '1.8rem',
        alignSelf: 'self-start',
        color: '#ffffff',
    },
    backButton: {
        alignSelf: 'start',
    },
    backToLogin: {
        fontSize: '14px',
        margin: '3rem 0 1rem 0',
        color: COLORS.brandCyan,
    },
    ctaBtn: {
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
        fontWeight: 500,
    },
    helperText: {
        color: '#585858',
        fontSize: '14px',
        background: '#fff',
        borderRadius: '15px 15px 0 0',
        textAlign: 'center',
        fontWeight: 'bold',
        marginTop: '15px',
        padding: '15px 0 15px 0',
        borderBottom: '1px solid #d9d9d6',
    },
    emailLink: {
        width: '100%',
        color: COLORS.brandCyan,
        '&:hover': {
            textDecoration: 'none',
        },
    },
});

const PasswordResetEmail = ({ showInModal, setNextScreen }: any) => {
    const classes = useStyles();
    const { history, query, push } = useRouter();
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [userState, setUserState] = useUserState();

    const { analyticsCustomEvent } = useEventsReporter();
    const sendEmail = async () => {
        setIsLoading(true);
        analyticsCustomEvent(eventMetricsMap.password_reset, {
            click_text: 'Re-send',
            element_location: 'password reset request',
        });
        const res = await sendEmailToUser(SessionManager.userName);
        if (res?.message?.message === 'received user details') {
            setShowConfirmation(true);
        }
        setIsLoading(false);
    };
    const [drawerState, setDrawerState] = useState({
        bottom: false,
    });
    const toggleDrawer = (anchor: any, open: boolean) => () => {
        setDrawerState({ ...drawerState, [anchor]: open });
    };
    const backToLogin = () => {
        if (userState?.email) {
            setUserState({
                ...userState,
                email: userState?.email,
            });
            if (!showInModal) {
                push(LOGIN_ROUTES.login);
            }
            else {
                setNextScreen(LOGIN_ROUTES.login)
            }

        } else if (!showInModal) {
            push(LOGIN_ROUTES.lookup);
        }
        else {
            setNextScreen(LOGIN_ROUTES.lookup)
        }
        analyticsCustomEvent(eventMetricsMap.password_reset, {
            click_text: 'Back to Sign in',
            element_location: 'password reset request',
        });
    };
    const openEmail = () => {
        setDrawerState({ ...drawerState, bottom: true });
    };
    const goBack = () => {
        if (SessionManager.isForgotPassword) {
            history.push(ROUTES.forgotPassword);
        } else {
            history.push(ROUTES.passwordReset);
        }
    };

    const emailList = [
        {
            displayText: 'Gmail',
            target: 'https://gmail.com',
        },
        {
            displayText: 'Yahoo',
            target: 'https://yahoomail.com',
        },
        {
            displayText: 'Outlook',
            target: 'https://outlook.com',
        },
        {
            displayText: 'iCloud',
            target: 'https://icloud.com',
        },
        {
            displayText: 'Aol',
            target: 'https://login.aol.com',
        },
    ];
    const list = (anchor: any) => (
        <div
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Typography className={classes.helperText}>Choose email</Typography>

            <List style={{ background: '#fff', borderRadius: '0 0 15px 15px' }}>
                {emailList.map(({ displayText, target }, index) => (
                    <ListItem
                        button
                        key={displayText}
                        style={{
                            borderBottom:
                                index === emailList.length - 1
                                    ? '0px none'
                                    : '1px solid #d9d9d6',
                            paddingTop: index === 0 ? '0px' : '',
                            paddingBottom:
                                index === emailList.length - 1 ? '0px' : '',
                            textAlign: 'center',
                        }}
                    >
                        <AppLink
                            id="email-button"
                            href={target}
                            className={classes.emailLink}
                            target="_blank"
                        >
                            <ListItemText primary={displayText} />
                        </AppLink>
                    </ListItem>
                ))}
            </List>
            <List
                style={{
                    background: '#fff',
                    borderRadius: '15px',
                    marginTop: '25px',
                    marginBottom: '15px',
                }}
            >
                <ListItem
                    style={{
                        textAlign: 'center',
                        paddingBottom: '0px',
                        paddingTop: '0px',
                    }}
                >
                    <AppLink
                        id="cancel-button"
                        href="#"
                        className={classes.emailLink}
                    >
                        <ListItemText primary="Cancel" />
                    </AppLink>
                </ListItem>
            </List>
        </div>
    );

    return (
        <div style={{ height: showInModal ? '85vh' : 'auto' }}>
            {isLoading ? (
                <LoadingOverlay
                    variant="wordmark"
                    theme="transparent-light"
                    open={isLoading}
                />
            ) : null}
            <NavTop
                minHeight="17rem"
                bgImageSrc={require('../../assets/bgs/loginBg.svg')}
                id="pwd-screen"
            >
                <ButtonBase
                    tabIndex={7}
                    role='button'
                    aria-label='Go back'
                    onClick={() => goBack()}
                    className={classes.backButton}
                >
                    <img
                        className={classes.backIcon}
                        src={require('../../assets/icons/iconBackWhite.svg')}
                        alt="staples connect logo"
                    />
                </ButtonBase>

                <Typography tabIndex={1} aria-label="Check your inbox!" className={classes.headerTitle}>
                    Check your inbox!
                </Typography>
                <FullWidthCard
                    cardContainerClassName={classes.container}
                    noPadding
                    hasManualShadow
                    id="password-reset-card"
                >
                    <div
                        className={classes.contentContainer}
                        id="content-container"
                    >
                        <Typography
                            tabIndex={2}
                            aria-label="We just sent instructions to reset your password to your email."
                            className={classes.headerSubTitleFound}
                            style={{ padding: '1rem' }}
                        >
                            {query?.type === 'easySignOn'
                                ? `We just sent instructions on creating your easy
                            single login to ${hideEmail(
                                    SessionManager?.userName
                                )}.`
                                : `We’ve just sent instructions to reset your password at
                            ${hideEmail(SessionManager?.userName)}.`}
                        </Typography>
                        <Typography
                            tabIndex={3}
                            aria-label="Didn’t receive an email?"
                            className={classes.headerSubTitleFound}
                            style={{ fontSize: '14px', marginTop: '48px' }}
                        >
                            Didn’t receive an email?{' '}
                            <Link
                                tabIndex={4}
                                aria-label="Resend"
                                aria-selected
                                onClick={sendEmail}
                                style={{ color: COLORS.brandCyan }}
                            >
                                Re-send
                            </Link>
                        </Typography>
                        <LoadingButton
                            tabIndex={5}
                            aria-selected
                            aria-label="Open email"
                            fullWidth
                            id="step2-next-button"
                            variant="contained"
                            classes={{
                                root: classes.ctaBtn,
                            }}
                            type="submit"
                            loading={false}
                            onClick={openEmail}
                            style={{ marginTop: '10px' }}
                        >
                            Open email
                        </LoadingButton>
                        <Link
                            tabIndex={6}
                            aria-selected
                            aria-label="Back to Sign in"
                            onClick={backToLogin}
                            className={classes.backToLogin}
                            style={{ margin: '16px 0' }}
                        >
                            Back to Sign in
                        </Link>
                    </div>
                    <div id="drawer-container" style={{ position: 'relative' }}>
                        <Button onClick={toggleDrawer('bottom', true)}>
                            bottom
                        </Button>
                        <Drawer
                            PaperProps={{
                                style: {
                                    position: 'absolute',
                                    margin: '0 24px',
                                    borderRadius: '15px',
                                    background: 'transparent',
                                    boxShadow: 'none',
                                },
                            }}
                            ModalProps={{
                                container:
                                    document.getElementById('drawer-container'),
                                keepMounted: true,
                            }}
                            anchor="bottom"
                            open={drawerState.bottom}
                            onClose={toggleDrawer('bottom', false)}
                        >
                            {list('bottom')}
                        </Drawer>
                    </div>
                </FullWidthCard>
            </NavTop>

            {showConfirmation ? (
                <CommonDialog
                    open={showConfirmation}
                    onClose={() => setShowConfirmation(false)}
                    message={
                        'Please check your email for the password reset link'
                    }
                    title={'Success'}
                />
            ) : null}
        </div>
    );
};
export default PasswordResetEmail;
