import React, { useMemo, useState, useEffect } from 'react';
import { Tabs, Tab as MUITab, withStyles } from '@material-ui/core';
import { Player } from '@lottiefiles/react-lottie-player';
import SwipeableViews from 'react-swipeable-views';
import CarouselPagination from 'src/components/Carousel/CarouselPagination';
import { Heading, Body, Button, SwipeableDrawer } from 'src/components/UI';
import { IconEasyRewardsHorizontal } from 'src/components/Icons';
import COLORS from 'src/lib/colors';
import { mergeClasses } from 'src/stylesheets/utils';
import {
    nouns_rewards_enrollment_bottom_right_button,
    page_rewards_enrollment_welcome_center_text,
} from 'src/utils/stringConstants';
import { eventMetricsMap, useEventsReporter, useUserInfo } from 'src/hooks';
import styles from './styles.module.scss';

const Tab = withStyles(() => ({
    root: {
        fontSize: 18,
        textTransform: 'none',
        fontFamily: 'StaplesNormsRegular',
        '&$selected': {
            fontFamily: 'StaplesNormsBold',
        },
    },
    selected: {},
}))((props: { label: string }) => <MUITab {...props} />);

interface IntroCarouselProps {
    variant: 'base' | 'non-migrated-onboarding' | 'non-migrated-rewards-center';
    primaryCta: () => void;
    secondaryCta?: () => void;
}

function IntroCarousel({
    variant,
    primaryCta,
    secondaryCta,
}: IntroCarouselProps) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [tosTabIndex, setTosTabIndex] = useState(0);
    const [tosSource, setTosSource] = useState<string | null>(null);
    const [showTabs, setShowTabs] = useState(false);
    const { analyticsCustomEvent } = useEventsReporter();
    const { userInfo } = useUserInfo();

    const handleChangeIndex = (index: number) => {
        setActiveIndex(index);
    };

    const [contentTop, contentMiddle, contentBottom] = useMemo(
        () => [
            [
                <Player
                    loop
                    autoplay
                    src={require('../../../assets/lotties/rewards-enrollment/benefits-points-1.json')}
                    style={{
                        height:
                            variant === 'non-migrated-rewards-center'
                                ? 150
                                : 190,
                    }}
                />,
                <Player
                    loop
                    autoplay
                    src={require('../../../assets/lotties/rewards-enrollment/benefits-points-2.json')}
                    style={{
                        height:
                            variant === 'non-migrated-rewards-center'
                                ? 150
                                : 190,
                    }}
                />,
                <Player
                    loop
                    autoplay
                    src={require('../../../assets/lotties/rewards-enrollment/benefits-points-3.json')}
                    style={{
                        height:
                            variant === 'non-migrated-rewards-center'
                                ? 150
                                : 190,
                    }}
                />,
            ],
            [
                <div className={styles.carouselContentContainer}>
                    <Heading type="h3" size={26} textAlign="textCenter">
                        Earn
                        <Body
                            display="inline"
                            color="textPurple"
                            weight="bold"
                            size={26}
                        >
                            {' '}
                            1 point per $1 spent.
                        </Body>
                        * In store, online and in the app.
                    </Heading>
                </div>,
                <div className={styles.carouselContentContainer}>
                    <Heading type="h3" size={26} textAlign="textCenter">
                        Plus earn
                        <Body
                            display="inline"
                            color="textPurple"
                            weight="bold"
                            size={26}
                        >
                            {' '}
                            double or more{' '}
                        </Body>
                        points on 3 bonus points categories you choose
                    </Heading>
                </div>,
                <div className={styles.carouselContentContainer}>
                    <Heading type="h3" size={26} textAlign="textCenter">
                        <Body
                            display="inline"
                            color="textPurple"
                            weight="bold"
                            size={26}
                        >
                            Points never expire{' '}
                        </Body>
                        for active members*
                    </Heading>
                </div>,
            ],
            [
                <Body size={12} textAlign="textCenter" weight="light">
                    *Excludes gift cards, phone cards, postage stamps, clearance
                    items, cash, taxes, shipping fees/charges,
                    protection/service plans, travel services, promotional
                    products and certain special orders and online marketplace
                    purchases. For full details, visit staples.com/easy.
                </Body>,
                null,
                <Body size={12} textAlign="textCenter" weight="light">
                    *Members must earn or redeem at least 1 point in 18 months
                    to remain active.
                </Body>,
            ],
        ],
        []
    );

    const handleSecondaryCta = () => {
        secondaryCta?.();
        analyticsCustomEvent(eventMetricsMap.easy_rewards_enroll, {
            click_text: `I'll do this later`,
            element_location: 'introducing easy rewards',
        });
    };

    const handleNotInterested = () => {
        secondaryCta?.();
        analyticsCustomEvent(eventMetricsMap.easy_rewards_enroll, {
            click_text: `i'm not interested`,
            element_location: 'enroll benefits',
        });
    }

    const handleNextBtn = () => {
        primaryCta();
        analyticsCustomEvent(eventMetricsMap.easy_rewards_enroll, {
            click_text: `next`,
            element_location: 'introducing easy rewards',
        });
    };

    useEffect(() => {
        if (variant !== 'non-migrated-rewards-center') {
            analyticsCustomEvent(eventMetricsMap.easy_rewards_enroll, {
                click_text: `accept`,
                element_location: 'accept terms & conditions',
                event_rewards_number: userInfo?.rewardsNumber || '',
            });
        }

        if (variant === 'non-migrated-rewards-center') {
            // @Screen name enrollflow_nonmigrated easy rewards enroll
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `easyrewards tab_ enrollment nonmigrated`,
                firebase_screen_class: `easyrewards tab_ enrollment nonmigrated`,
            });
        } else if (variant === 'non-migrated-onboarding') {
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `enrollflow_nonmigrated easy rewards enroll`,
                firebase_screen_class: `enrollflow_nonmigrated easy rewards enroll`,
            });
            analyticsCustomEvent(eventMetricsMap.login_flow, {
                click_text: `sign in`,
                element_location: `non-migrated flow`,
            });
        }
    }, [variant]);

    return (
        <>
            {variant !== 'non-migrated-rewards-center' && (
                <div
                    className={mergeClasses([
                        styles.flex,
                        styles.flexCol,
                        styles.alignCenter,
                    ])}
                >
                    <Heading type="h3" size={18}>
                        {page_rewards_enrollment_welcome_center_text}
                    </Heading>
                    <div>
                        <IconEasyRewardsHorizontal />
                    </div>
                </div>
            )}
            <div
                className={mergeClasses(
                    styles.flex,
                    styles.flexCol,
                    variant === 'non-migrated-rewards-center'
                        ? styles.rowGap2
                        : styles.rowGap4
                )}
            >
                <div className={styles.carouselContentContainer}>
                    <Heading type="h4" size={18} weight="bold">
                        Introducing
                    </Heading>
                    <div>
                        <IconEasyRewardsHorizontal />
                    </div>
                </div>
                <SwipeableViews
                    id="onboarding-swipeable"
                    enableMouseEvents
                    index={activeIndex}
                    // animateHeight
                    onChangeIndex={handleChangeIndex}
                >
                    {contentTop}
                </SwipeableViews>
                <CarouselPagination
                    type={'dot'}
                    dots={contentTop.length}
                    index={activeIndex}
                    activeColor={COLORS.purple}
                    onChangeIndex={handleChangeIndex}
                />
                <SwipeableViews
                    id="onboarding-swipeable"
                    enableMouseEvents
                    index={activeIndex}
                    // animateHeight
                    onChangeIndex={handleChangeIndex}
                >
                    {contentMiddle}
                </SwipeableViews>
                {variant.includes('non-migrated') && (
                    <>
                        <Body
                            textAlign="textCenter"
                            size={12}
                            weight="light"
                            className={styles.textAlign}
                        >
                            By enrolling in Easy Rewards, you agree to Staples
                            and Easy Rewards
                            <Button
                                variant="text"
                                onClick={() =>
                                    setTosSource(
                                        `${process.env.REACT_APP_DOTCOM_URL}/lp/easy-rewards-terms-conditions`
                                    )
                                }
                                className={styles.linkAlign}
                                fontSize={12}
                            >
                                &nbsp;Terms & Conditions,
                            </Button>
                            <Button
                                variant="text"
                                onClick={() => {
                                    setShowTabs(false);
                                    setTosSource(
                                        `${process.env.REACT_APP_DOTCOM_URL}/hc?id=dbb94c10-973c-478b-a078-00e58f66ba32`
                                    );
                                }}
                                className={styles.linkAlign}
                                fontSize={12}
                            >
                                &nbsp;Privacy Notice
                            </Button>{' '}
                            and
                            <Button
                                variant="text"
                                onClick={() => {
                                    setShowTabs(false);
                                    setTosSource(
                                        `${process.env.REACT_APP_DOTCOM_URL}/hc?id=d0641e76-dd9f-4d69-80d3-ff148ddcd188`
                                    );
                                }}
                                className={styles.linkAlign}
                                fontSize={12}
                            >
                                &nbsp;California Notice.
                            </Button>{' '}
                            You also agree to receive Staples promotional
                            communications. You may unsubscribe at any time.
                        </Body>
                        <Button
                            variant="contained-rounded"
                            bgColor={
                                variant === 'non-migrated-onboarding'
                                    ? 'bgBlack'
                                    : 'bgPurple'
                            }
                            color="textWhite"
                            onClick={primaryCta}
                            align="alignSelfCenter"
                            className={styles.joinbtn}
                        >
                            {variant === 'non-migrated-rewards-center'
                                ? 'Join for free'
                                : 'Enroll in easy rewards'}
                        </Button>
                        {secondaryCta && (
                            <Button
                                variant="text"
                                color="textLinkBlue"
                                onClick={handleNotInterested}
                                align="alignSelfCenter"
                            >
                                I'm not interested
                            </Button>
                        )}
                    </>
                )}
                <SwipeableViews
                    id="onboarding-swipeable"
                    enableMouseEvents
                    index={activeIndex}
                    // animateHeight
                    onChangeIndex={handleChangeIndex}
                >
                    {contentBottom}
                </SwipeableViews>
            </div>
            {variant === 'base' && (
                <div
                    className={mergeClasses([
                        styles.flex,
                        styles.flexCol,
                        styles.rowGap4,
                    ])}
                >
                    <div
                        className={mergeClasses(
                            styles.flex,
                            styles.flexRow,
                            styles.alignCenter,
                            styles.justifySpaceBetween,
                            styles.fullWidth
                        )}
                    >
                        {secondaryCta && (
                            <Button
                                variant="text"
                                color="textPurple"
                                onClick={handleSecondaryCta}
                            >
                                I'll do this later
                            </Button>
                        )}
                        <Button
                            variant="contained-rounded"
                            onClick={handleNextBtn}
                            align="alignSelfEnd"
                        >
                            {nouns_rewards_enrollment_bottom_right_button}
                        </Button>
                    </div>
                </div>
            )}
            <SwipeableDrawer
                anchor="bottom"
                open={!!tosSource}
                onClose={() => {
                    setTosSource(null);
                    setShowTabs(false);
                }}
                onOpen={() => console.log('open')}
            >
                {showTabs && (
                    <Tabs
                        className={styles.tosTabs}
                        value={tosTabIndex}
                        onChange={(_e, value) => setTosTabIndex(value)}
                        aria-label="simple tabs example"
                    >
                        <Tab label="Staples" />
                        <Tab label="Easy Rewards" />
                    </Tabs>
                )}
                {tosTabIndex === 0 && (
                    <iframe
                        id="privacy-link"
                        title="privacy link iFrame"
                        style={{ height: '90vh', width: '100%', marginTop: 16 }}
                        src={tosSource || '#'}
                    />
                )}
                {tosTabIndex === 1 && (
                    <iframe
                        id="privacy-link"
                        title="privacy link iFrame"
                        style={{ height: '90vh', width: '100%', marginTop: 16 }}
                        src={tosSource || '#'}
                    />
                )}
            </SwipeableDrawer>
        </>
    );
}

export default IntroCarousel;
