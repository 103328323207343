//  eslint-disable-next-line max-len
import React, { useState, useEffect } from 'react';
import { ButtonBase, Box, makeStyles, Typography } from '@material-ui/core';
import { useQuery } from 'react-query';
import ROUTES from 'src/lib/routes';
import useRouter from 'src/hooks/useRouter';
import { suggestions } from 'src/lib/api-client/suggestions';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';
import { storeToLocalStorage } from 'src/lib/utils/store-data-to-local-storage';
import { setPreviousPath } from 'src/lib/utils/setPreviousPath';
import { makeSearchString } from 'src/lib/utils/url-utils';

const useStyles = makeStyles({
    container: {
        padding: '15px 30px',
    },
    querySuggestionRow: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 0px 10px 5px',
        width: '100%',
    },
    querySuggestionText: {
        fontWeight: 300,
        fontSize: '14px',
    },
    icon: {
        width: '15px',
    },
});

type MyCallbackParams = {
    q?: string;
    dq?: string;
};

export default function AutoCompeleteList() {
    const { push, location, pathname, history, query } = useRouter();
    const { fromPath, setFromPath, searchString, end, goToPage, setFilters } = useProductBrowseContext();

    const classes = useStyles();
    const { data: items, refetch } = useQuery(['suggestions'], () =>
        suggestions(searchString)
    );

    function onCategoryRowClick(row: any) {
        setFilters([]);
        goToPage({ type: 'search-results', pageDetails: { category: row } });
    }

    const handleRowClick = (item: any) => {
        if (pathname && pathname.includes(ROUTES.lists)) {
            onCategoryRowClick(item);
        } else {
            storeToLocalStorage('searchHistory', item);
            setFromPath(ROUTES.productDetailsPage === location.pathname ? fromPath : location.pathname)
            push(`${ROUTES.productDetailsPage}?searchString=${item}`, setPreviousPath(location, fromPath));
            end()
        }

    }
    useEffect(() => {
        if (searchString !== "") {
            refetch()
        }
    }, [searchString]);

    return (
        <Box className={classes.container}>
            {items?.response?.suggestions?.map((item: MyCallbackParams) => (
                <ButtonBase
                    className={classes.querySuggestionRow}
                    onClick={() => handleRowClick(item?.q)}
                >
                    <Typography className={classes.querySuggestionText}>
                        {item.q}
                    </Typography>
                    <img
                        className={classes.icon}
                        src={require('../assets/icons/iconArrow.svg')}
                        alt="search"
                    />
                </ButtonBase>
            ))}
        </Box>
    );
}
