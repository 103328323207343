/* eslint-disable import/prefer-default-export */

const hideEmailId = (email: string) => {
    const regexEmailHide = /(.{2})(.*)(?=@)/;
    return email?.replace(regexEmailHide,
        (gp1: string, gp2: string, gp3: string) => {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < gp3.length; i++) {
                gp2 += "*";
            } return gp2;
        });
};

export const hideEmail = (inputString: string) => {
    const email = hideEmailId(inputString);
    return email;
}

