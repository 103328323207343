import React from 'react';
import { Dialog } from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import { useScreenOrderContext } from 'src/lib/contexts/screen-order-context';
import { SessionManager } from 'src/lib/api-client/sessionManager';
import Cookies from 'js-cookie';
import {
    eventMetricsMap,
    LOCAL_STORAGE_KEYS,
    useEventsReporter,
    useLocalStorage,
    useLocalUserInfo,
} from 'src/hooks';
import { precache, removeSomniAppCookies } from 'src/lib/utils/app-utils';
import { mergeClasses } from 'src/stylesheets/utils';
import { Body, Button, Heading } from 'src/components/UI';
import styles from './styles.module.scss';

interface RewardsEnrollErrorDialogProps {
    error: {
        errorCode: string;
        errorDialogMessage: string;
    } | null;
    setError: React.Dispatch<
        React.SetStateAction<{
            errorCode: string;
            errorDialogMessage: string;
        } | null>
    >;
}
function RewardsEnrollErrorDialog({
    error,
    setError,
}: RewardsEnrollErrorDialogProps) {
    const { analyticsCustomEvent, analyticsScreenCustomNames } =
        useEventsReporter();
    const { userInfo: localUserInfo, patchLocalUserInfo } = useLocalUserInfo();
    const { prepareForLogout } = useScreenOrderContext();
    const [sawInstallData] = useLocalStorage<string | null>(
        LOCAL_STORAGE_KEYS.INSTALL_PROMPT,
        null
    );
    const handleLogout = () => {
        const { isUserMigrated, userName } = SessionManager;
        const pwaUpdateApp = sessionStorage.getItem('pwaUpdateApp');
        let userMigratedData = {
            isUserMigrated: false,
            userName: SessionManager.userName,
        };
        if (isUserMigrated) {
            userMigratedData = {
                isUserMigrated,
                userName,
            };
        }
        analyticsCustomEvent(eventMetricsMap.account_info, {
            click_text: 'sign out',
            element_location: 'user profile',
        });
        optimoveEvents(eventMetricsMap.scm_account_info, {
            click_text: 'sign out',
            element_location: 'user profile',
        });
        analyticsScreenCustomNames({
            logged_in: false,
        });
        const localStorageKeys = [];
        for (
            let keyIndex = 0, len = localStorage.length;
            keyIndex < len;
            keyIndex += 1
        ) {
            localStorageKeys.push(localStorage.key(keyIndex));
        }
        localStorageKeys.forEach((key) => {
            if (key && !key.includes('persistent')) {
                localStorage.removeItem(key);
            }
        });
        sessionStorage.clear();

        if (sawInstallData) {
            localStorage.setItem(
                'app.staplesconnect.INSTALL_PROMPT',
                sawInstallData
            );
        }
        if (pwaUpdateApp) {
            sessionStorage.setItem('pwaUpdateApp', pwaUpdateApp);
        }

        Cookies.set('DirectCustomerNumber', '');
        SessionManager.session?.killSession();
        SessionManager.isLoggedIn = false;

        window.location.replace('/somniapp/lookup');
        if (localUserInfo?.hasFinishedOnboarding) {
            patchLocalUserInfo({ hasFinishedOnboarding: true, zipCode: '' });
        }
        if (isUserMigrated) {
            SessionManager.setIsUserMigrated(userMigratedData);
        }
        removeSomniAppCookies();
        prepareForLogout();
        precache(false);
    };

    return (
        <Dialog open={!!error?.errorCode}>
            <div
                className={mergeClasses(
                    styles.p4,
                    styles.flex,
                    styles.flexCol,
                    styles.rowGap4,
                    styles.alignCenter
                )}
            >
                <div>
                    <Heading type="h3" weight="bold">
                        We’re unable to enroll you in Easy Rewards
                    </Heading>
                    <Body>{error?.errorDialogMessage}</Body>
                </div>
                <div
                    className={mergeClasses(
                        styles.flex,
                        styles.colGap4,
                        styles.alignCenter
                    )}
                >
                    {error?.errorCode === 'GRS_ERROR_EMAIL_ADDRESS_EXISIS' ? (
                        <>
                            <Button bgColor="bgBlack" onClick={handleLogout}>
                                Sign out
                            </Button>
                            <Button
                                bgColor="bgBlack"
                                onClick={() => setError(null)}
                            >
                                Skip
                            </Button>
                        </>
                    ) : (
                        <Button
                            bgColor="bgBlack"
                            onClick={() => setError(null)}
                        >
                            Got it
                        </Button>
                    )}
                </div>
            </div>
        </Dialog>
    );
}

export default RewardsEnrollErrorDialog;
