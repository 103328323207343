import React, { useState } from 'react';
import { useFirebaseMessagingContext } from 'src/lib/contexts/firebase-messaging-context';

type NotificationPermissionStatus =
    | 'denied'
    | 'granted'
    | 'default'
    | 'not_supported';

function useNotifications() {
    const { subscribe } = useFirebaseMessagingContext();
    const hasNotificationSupport: boolean = 'Notification' in window;
    const [currentNotificationPermission, setCurrentNotificationPermission] =
        useState(
            hasNotificationSupport ? Notification.permission : 'not_supported'
        );

    async function askNotificationPermission(): Promise<NotificationPermissionStatus> {
        console.log('273 asking for notification permission');
        if (!hasNotificationSupport) {
            console.log('273 no notification support');
            return 'not_supported';
        }
        const permission = await Notification.requestPermission();
        setCurrentNotificationPermission(permission);
        subscribe();
        console.log('273 permission', permission);
        return permission;
    }

    const areNotificationsAllowed = (): boolean =>
        currentNotificationPermission === 'granted';

    return {
        areNotificationsAllowed,
        askNotificationPermission,
        hasNotificationSupport,
        currentNotificationPermission,
    };
}

export default useNotifications;
