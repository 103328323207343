import React from 'react';
import { Button, ButtonBase, Typography, Box } from '@material-ui/core';
import { useHistory } from 'react-router';
import { optimoveEvents } from 'src/screens/optimove';
import NavTop from 'src/components/nav-top';
import { useIsMobileWidth } from 'src/hooks/useWidth';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import InfoButton, { COMMON_INFO_TEXT } from 'src/components/info-button';
import FullWidthCard from 'src/components/cards/full-width-card';
import { IconButton } from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import useStyles from './use-styles';

interface ListNavProps {
    disableModeToggle: boolean;
    importList: () => void;
    createNewList: () => void;
    editMode?: boolean;
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    noListsView: boolean;
    totalListLength: number;
    userListLength: number;
}

const ListNavTop = ({
    createNewList,
    importList,
    disableModeToggle,
    editMode,
    setEditMode,
    noListsView,
    totalListLength,
    userListLength,
}: ListNavProps) => {
    const isMobileWidth = useIsMobileWidth();
    const classes = useStyles({ isMobileWidth });
    const { goBack, push } = useHistory();
    const { analyticsCustomEvent } = useEventsReporter();
    function toggleEdit() {
        setEditMode((oldVal: boolean) => !oldVal);
    }

    // if we not already in edit mode, then clicking import list should not trigger the toggleEdit
    function closeEditMode() {
        if (editMode) {
            toggleEdit();
        }
    }

    let listAvailableText;

    switch (totalListLength) {
        case 0:
            listAvailableText = 'No personal lists';
            break;
        case 1:
            listAvailableText = 'Personal list';
            break;
        default:
            listAvailableText = 'Personal lists';
    }

    return (
        <Box>
            <NavTop
                withTopScroll
                marginBottom={
                    userListLength || noListsView ? '-2rem' : undefined
                }
            >
                <div className={classes.topContent}>
                    <div className={classes.editButtonWrapper}>
                        <IconButton
                            onClick={() => push('/user-profile')}
                            size="small"
                            className={classes.backButton}
                        >
                            <ArrowBackIosRoundedIcon
                                className={classes.backIcon}
                            />
                        </IconButton>
                        {totalListLength !== 0 ? (
                            <Button
                                onClick={toggleEdit}
                                className={classes.editButton}
                                id="edit-mode-toggle-button"
                                disabled={disableModeToggle}
                            >
                                {editMode ? 'Done' : 'Edit'}
                            </Button>
                        ) : null}
                    </div>

                    <div className={classes.paddedContent}>
                        <Typography
                            className={classes.listsAmountNumber}
                            id="list-available-amount-text"
                        >
                            {totalListLength}
                        </Typography>
                        <Typography
                            className={classes.listsAmountLabel}
                            id="list-available-text"
                        >
                            {listAvailableText}
                        </Typography>
                        <Button
                            onClick={() => {
                                analyticsCustomEvent(eventMetricsMap.lists, {
                                    click_text: 'create new list',
                                    element_location: 'top banner',
                                });
                                optimoveEvents(eventMetricsMap.scm_lists, {
                                    click_text: 'create new list',
                                    element_location: 'top banner',
                                });
                                toggleEdit();
                                createNewList();
                            }}
                            className={classes.createButton}
                            id="create-new-list-button"
                        >
                            Create new list
                        </Button>
                        <div className={classes.findSupplyListWrapper}>
                            <ButtonBase
                                onClick={() => {
                                    analyticsCustomEvent(
                                        eventMetricsMap.lists,
                                        {
                                            click_text:
                                                'find your school supply list',
                                            element_location: 'top banner',
                                        }
                                    );
                                    optimoveEvents(eventMetricsMap.scm_lists, {
                                        click_text:
                                            'find your school supply list',
                                        element_location: 'top banner',
                                    });
                                    closeEditMode();
                                    importList();
                                }}
                                className={classes.importButton}
                                id="import-from-teacherlists-button"
                            >
                                Find your school supply list
                            </ButtonBase>
                            <InfoButton
                                buttonId="find-your-school-supply-list-info-button"
                                tooltipTitle={
                                    COMMON_INFO_TEXT.findYourSupplyList
                                }
                                tooltipPlacement="top-end"
                                buttonSize="1.2rem"
                                primaryTooltipOnDarkBackground
                            />
                        </div>
                    </div>
                </div>
            </NavTop>

            {noListsView && (
                <Box>
                    <FullWidthCard
                        hasManualShadow
                        id="empty-list-disclaimer-card"
                    >
                        <Box className={classes.disclaimerContent}>
                            <img
                                className={classes.disclaimerIcon}
                                src={require('../../assets/icons/iconNolist.svg')}
                                alt="no lists"
                            />
                            <Typography
                                className={classes.disclaimerText}
                                id="empty-list-disclaimer-text"
                            >
                                There are no available lists to view.
                                <br />
                                Create a new list or import a list to get
                                started.
                            </Typography>
                        </Box>
                    </FullWidthCard>
                </Box>
            )}
        </Box>
    );
};

export default ListNavTop;
