/* eslint-disable no-nested-ternary */
import React, { ChangeEvent, useRef, useState } from 'react';
import { ButtonBase, InputBase, makeStyles } from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import COLORS from 'src/lib/colors';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import { storeToLocalStorage } from 'src/lib/utils/store-data-to-local-storage';
import ROUTES from 'src/lib/routes';
import { useRouter } from 'src/hooks';
import Brcd from './BarcodeScanner/Brcd';

const useStyles = makeStyles({
    form: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        position: 'relative',
        '& #product-search-input::-webkit-search-cancel-button': {
            display: 'none !important',
        },
        '& input::-webkit-input-placeholder': {
            color: '#757575 !important',
            opacity: 1,
        },
    },
    searchInput: {
        display: 'flex',
        width: '100%',
        flexGrow: 1,
        height: '38px',
        padding: '0.25rem 0',
        borderRadius: '0.625rem',
        backgroundColor: COLORS.primaryWhite,
        gap: '10px',
        color: '#000000',
    },
    searchIconBtn: {
        marginLeft: '.9rem',
    },
    searchIcon: {
        // width: '1.125rem',
    },
    clearIconBtn: {
        width: '1.75rem',
        height: '1.75rem',
        marginRight: '.5rem',
    },
    clearIcon: {
        width: '1.75rem',
        height: '1.75rem',
        color: COLORS.fordGray,
    },
    barcodeScan: {
        position: 'absolute',
        right: '10px',
        top: '10px',
    },
});

/** Search box for searching for products or starting the browse flow */
export default function SearchForProduct({
    /** if true, clicking the component will immediately begin the product browsing */
    decoy,
    // possibly have a separate handleSubmit here in the useProductBrowseContext
    handleSubmit,
    mainStore,
    autoFocus = false,
    ...rest
}: any) {
    const [updatingSearch, setUpdatingSearch] = useState(false)
    const { begin, searchString, setSearchString, formik } = useProductBrowseContext();
    const [isBarcodeScannerOpen, setIsBarcodeScannerOpen] = useState(false);
    const { customEvent, analyticsCustomEvent } = useEventsReporter();
    const classes = useStyles();
    const ref = useRef();
    const { location } = useRouter();

    function onSubmit(event: any) {
        if (event) {
            event.preventDefault();
        }
        setUpdatingSearch(false)
        // condition added to prevent searching when search icon is clicked or enter is pressed with empty input
        if (searchString !== '') {
            customEvent(eventMetricsMap.searched_product);
            analyticsCustomEvent(eventMetricsMap.product_search, {
                click_text: 'search clicked',
                element_location: 'search bar',
                search_term: searchString,
            });
            optimoveEvents(eventMetricsMap.product_search, {
                click_text: 'search clicked',
                element_location: 'search bar',
                search_term: searchString,
            });
            localStorage.setItem('searchedQuery', searchString);
            handleSubmit(searchString);
            storeToLocalStorage('searchHistory', searchString);
        }
    }

    function handleDecoy(event: any, eventCall = false) {
        if (eventCall) {
            analyticsCustomEvent(eventMetricsMap.home, {
                click_text: 'search clicked',
                element_location: 'search bar',
            });
        }

        if (decoy) {
            if (event) {
                event.preventDefault();
            }
            if (ref?.current) {
                (ref as any).current.blur();
            }
            begin();
        }
    }
    function onUpdateSearch(
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) {
        setUpdatingSearch(true)
        setSearchString(event.target.value);
    }
    function clearSearch() {
        setSearchString('');
    }

    if (!mainStore) {
        return null;
    }

    return (
        <form
            className={classes.form}
            onSubmit={onSubmit}
            id="product-search-input-container"
        >
            <InputBase
                tabIndex={decoy ? -1 : undefined}
                id="product-search-input"
                className={classes.searchInput}
                placeholder="Search"
                onChange={onUpdateSearch}
                onClick={handleDecoy}
                onFocus={(e) => handleDecoy(e, true)}
                autoFocus={autoFocus}
                onSubmit={onSubmit}
                inputRef={ref}
                inputMode="search"
                type="search"
                value={decoy ? (location.pathname === ROUTES.productDetailsPage
                    ? searchString : undefined) : searchString}
                inputProps={{ tabIndex: decoy ? -1 : undefined, "data-testid": "searchInputBox" }}
                error={
                    formik?.touched?.searchString &&
                    Boolean(formik?.errors?.searchString)
                }
                helpertext={
                    formik?.touched?.searchString &&
                    formik?.errors?.searchString
                }
                startAdornment={
                    <ButtonBase
                        tabIndex={decoy ? -1 : undefined}
                        onClick={decoy ? handleDecoy : onSubmit}
                        id="product-search-button"
                        className={classes.searchIconBtn}
                    >
                        <img
                            id="product-search-icon-img"
                            src={require('../assets/icons/Search_icon_home.svg')}
                            className={classes.searchIcon}
                            alt="search icon"
                        />
                    </ButtonBase>
                }
                endAdornment={
                    !decoy && !!searchString ? (
                        <ButtonBase
                            tabIndex={decoy ? -1 : undefined}
                            onClick={decoy ? handleDecoy : clearSearch}
                            id="product-search-clear-button"
                            className={classes.clearIconBtn}
                        >
                            <img
                                id="product-search-clear-img"
                                src={require('../assets/icons/close-icon.svg')}
                                alt="Clear search icon"
                            />
                        </ButtonBase>
                    ) : undefined
                }
                {...rest}
            />
            {!updatingSearch ? (
                <ButtonBase
                    className={classes.barcodeScan}
                    onClick={() => {
                        setIsBarcodeScannerOpen(true);
                        analyticsCustomEvent(eventMetricsMap.scan, {
                            click_text: 'scan icon',
                        });
                    }}
                    role='button'
                aria-label='Scan'
                >
                    <img
                        src={require('../assets/icons/barcode-scanner.svg')}
                        alt="Scan"
                        style={{ height: '18px', marginLeft: '1.5rem' }}
                    />
                </ButtonBase>
            ) : undefined}
            {isBarcodeScannerOpen && (
                <Brcd
                    isScannerOpen={isBarcodeScannerOpen}
                    onClose={() => setIsBarcodeScannerOpen(false)}
                />
            )}
        </form>
    );
}
