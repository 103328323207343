import React, { useLayoutEffect, useState } from 'react';
import { TextField, Dialog } from '@material-ui/core';
import SelectableCard from 'src/components/SelectableCard';
import ProgressBar from 'src/components/UI/ProgressBar';
import { Button, Heading, Body } from 'src/components/UI';
import {
    useRouter,
    useLocalStorage,
    LOCAL_STORAGE_KEYS,
    useEventsReporter,
    eventMetricsMap,
} from 'src/hooks';
import { REWARDS_ENROLLMENT_ROUTES } from 'src/routers/routes';
import { mergeClasses } from 'src/stylesheets/utils';
import {
    page_personalize_points_card1_body,
    page_personalize_points_card1_title,
    page_personalize_points_card2_body,
    page_personalize_points_card2_title,
    page_personalize_points_card3_title,
    page_personalize_points_card3_body,
    nouns_rewards_enrollment_top_right_button,
    page_personalize_points_title_part1,
    page_personalize_points_title_part2_bold,
    page_personalize_points_title_part3,
    page_personalize_points_subtitle,
    nouns_rewards_enrollment_bottom_right_button,
} from 'src/utils/stringConstants';
import ROUTES from 'src/lib/routes';
import { updateUserAccountType } from 'src/services/userServices';
import { REWARDS_ENROLLMENT_TRACKER } from '../types';
import styles from './styles.module.scss';

function PersonalizePointsScreen() {
    const [organizationName, setOrganizationNamee] = useState('');
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [customerType, setCustomerType] = useState<
        'consumer' | 'business' | 'teacher' | null
    >(null);
    const { push } = useRouter();
    const { analyticsScreenCustomNames, analyticsCustomEvent } =
        useEventsReporter();
    const {
        mutateAsync: mutateUserAccountType,
        isLoading: isMutatingUserAccountType,
    } = updateUserAccountType();
    const [
        { completedEnrollmentSteps, skippedEnrollmentSteps },
        setEnrollmentSteps,
    ] = useLocalStorage<REWARDS_ENROLLMENT_TRACKER>(
        LOCAL_STORAGE_KEYS.ENROLLMENT_TRACKER,
        {
            completedEnrollmentSteps: {},
            skippedEnrollmentSteps: {},
        }
    );
    const cards = [
        {
            value: 'consumer',
            heading: page_personalize_points_card1_title,
            body: page_personalize_points_card1_body,
        },
        {
            value: 'business',
            heading: page_personalize_points_card2_title,
            body: page_personalize_points_card2_body,
        },
        {
            value: 'teacher',
            heading: page_personalize_points_card3_title,
            body: page_personalize_points_card3_body,
        },
    ];

    const getClickText: any = {
        consumer: 'Myself',
        business: 'Business',
        teacher: 'School',
    }

    useLayoutEffect(() => {
        if (completedEnrollmentSteps[REWARDS_ENROLLMENT_ROUTES.peronalize]) {
            push(REWARDS_ENROLLMENT_ROUTES.finish);
        }
    }, []);

    return (
        <>
            <div className={styles.container}>
                <div>
                    <div className={styles.py4}>
                        <Heading
                            type="h3"
                            color="textBlack"
                            size={30}
                            weight="light"
                            lineHeight={34}
                        >
                            {page_personalize_points_title_part1}
                            <Body
                                display="inline"
                                size={30}
                                color="textPurple"
                                weight="bold"
                            >
                                {page_personalize_points_title_part2_bold}
                            </Body>
                            {page_personalize_points_title_part3}
                        </Heading>
                        <p className={styles.subheading}>
                            {page_personalize_points_subtitle}
                        </p>
                        <div className={styles.cardGroupContainer}>
                            {cards.map((card) => (
                                <SelectableCard
                                    key={card.value}
                                    selected={customerType === card.value}
                                    onSelect={() =>
                                        setCustomerType(
                                            card.value as typeof customerType
                                        )
                                    }
                                    containerClassname={styles.cardContainer}
                                >
                                    <Heading
                                        type="h6"
                                        size={22}
                                        weight="bold"
                                        color="textPurple"
                                    >
                                        {card.heading}
                                    </Heading>
                                    <Body
                                        weight="light"
                                        size={14}
                                        className={styles.cardSubheading}
                                    >
                                        {card.body}
                                    </Body>
                                    {customerType === card.value &&
                                        customerType === 'business' && (
                                            <TextField
                                                classes={{
                                                    root: styles.textfieldUnderline,
                                                }}
                                                className={styles.mt4}
                                                variant="standard"
                                                placeholder="Company name *"
                                                value={organizationName}
                                                onChange={(e) =>
                                                    setOrganizationNamee(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        )}
                                </SelectableCard>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={styles.footer}>
                    <Button
                        fullWidth
                        disabled={
                            !customerType ||
                            (customerType === 'business' && !organizationName)
                        }
                        loading={isMutatingUserAccountType}
                        onClick={() => {
                            if (customerType) {
                                mutateUserAccountType({
                                    customerType,
                                    organizationName,
                                })
                                    .then(() => {
                                        delete skippedEnrollmentSteps[
                                            REWARDS_ENROLLMENT_ROUTES.peronalize
                                        ];
                                        setEnrollmentSteps({
                                            skippedEnrollmentSteps: {
                                                ...skippedEnrollmentSteps,
                                            },
                                            completedEnrollmentSteps: {
                                                ...completedEnrollmentSteps,
                                                [REWARDS_ENROLLMENT_ROUTES.peronalize]:
                                                    true,
                                            },
                                        });
                                        push(REWARDS_ENROLLMENT_ROUTES.finish);
                                        analyticsScreenCustomNames({
                                            user_type: customerType,
                                        });
                                        analyticsCustomEvent(
                                            eventMetricsMap.easy_rewards_enroll,
                                            {
                                                click_text: getClickText[customerType],
                                                element_location:
                                                    'personalize rewards',
                                            }
                                        );
                                    })
                                    .catch(() => setShowErrorDialog(true));
                            }
                        }}
                    >
                        {nouns_rewards_enrollment_bottom_right_button}
                    </Button>
                </div>
            </div>
            <Dialog open={showErrorDialog}>
                <div
                    className={mergeClasses(
                        styles.p4,
                        styles.flex,
                        styles.flexCol,
                        styles.rowGap4,
                        styles.alignCenter
                    )}
                >
                    <div>
                        <Heading type="h3" weight="bold">
                            Sorry, we are not able to save right now.
                        </Heading>
                        <Body>
                            Try confirming your personalization again or skip
                            for now.
                        </Body>
                    </div>
                    <Button
                        bgColor="bgBlack"
                        onClick={() => setShowErrorDialog(false)}
                    >
                        Got it
                    </Button>
                </div>
            </Dialog>
        </>
    );
}

export default PersonalizePointsScreen;
