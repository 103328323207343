import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Player } from '@lottiefiles/react-lottie-player';
import styles from './styles.module.scss';

interface PullToRefreshProps {
    children: React.ReactNode;
    onRefresh: () => Promise<any>;
    bgColor?: string;
    setShowBackToTop?: (v: boolean) => void,
    showBackToTop?: boolean,
}

function PullToRefresh({
    children,
    onRefresh,
    bgColor = 'bgWhite',
    setShowBackToTop,
    showBackToTop,
}: PullToRefreshProps) {
    const [startPoint, setStartPoint] = React.useState(0);
    const [pullChange, setPullChange] = React.useState<number>(0);
    const [loading, setLoading] = React.useState(false);
    const [lottie, setLottie] = React.useState<any>();
    const containerRef = React.useRef<HTMLDivElement>(null);

    const initLoading = () => {
        lottie?.play();
        setLoading(true);
        onRefresh()
            .then(() => {
                setLoading(false);
                lottie?.stop();
            })
            .catch(() => {
                setLoading(false);
                lottie?.stop();
            });
    };

    const pullStart = (e: any) => {
        const { screenY } = e.targetTouches[0];
        setStartPoint(screenY);
    };

    const pull = (e: any) => {
        const touch = e.targetTouches[0];
        const { screenY } = touch;
        const pullLength =
            startPoint < screenY ? Math.abs(screenY - startPoint) : 0;
        if (containerRef?.current?.scrollTop === 0) {
            setPullChange(pullLength);
        }
    };

    const endPull = () => {
        if (pullChange > 180) initLoading();
        setStartPoint(0);
        setPullChange(0);
    };

    React.useEffect(() => {
        containerRef?.current?.addEventListener('touchstart', pullStart);
        containerRef?.current?.addEventListener('touchmove', pull);
        containerRef?.current?.addEventListener('touchend', endPull);
        return () => {
            containerRef?.current?.removeEventListener('touchstart', pullStart);
            containerRef?.current?.removeEventListener('touchmove', pull);
            containerRef?.current?.removeEventListener('touchend', endPull);
        };
    });

    const screenScroll = () => {
        const bufferScroll = 250;
        if( containerRef?.current?.scrollTop && containerRef?.current?.scrollTop > bufferScroll ) {
            if (setShowBackToTop) {
                setShowBackToTop(true)
            }
        }
        else if (setShowBackToTop) {
            setShowBackToTop(false)
        }
    }
    useEffect(() => {
        containerRef?.current?.addEventListener('scroll',screenScroll)

            return () => {
                containerRef?.current?.removeEventListener('scroll', screenScroll)
            }

    },[])

    useEffect(() => {
        if(!showBackToTop) {
            containerRef?.current?.scroll({top: 0, left: 0, behavior: 'smooth'})
        }
    },[showBackToTop])

    return (
        <div ref={containerRef} className={styles.container}>
            <div className={styles[bgColor]}>
                <motion.div
                    className={styles.loaderContainer}
                    initial={false}
                    animate={{
                        height: loading ? 50 : pullChange / 3.118 || 0,
                    }}
                >
                    <Player
                        lottieRef={(ref) => setLottie(ref as any)}
                        loop
                        src={require('../../assets/lotties/stapleRevealWordmark.json')}
                        style={{ height: '100px', width: '100px' }}
                    />
                </motion.div>
            </div>
            <div>{children}</div>
        </div>
    );
}

export default PullToRefresh;
