import React, { useEffect, useLayoutEffect } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import Button from 'src/components/UI/Button';
import { Heading, Body } from 'src/components/UI/Typography';
import {
    LOCAL_STORAGE_KEYS,
    eventMetricsMap,
    useEventsReporter,
    useLocalStorage,
    useRouter,
    useUserInfo,
} from 'src/hooks';
import ROUTES from 'src/lib/routes';
import { mergeClasses } from 'src/stylesheets/utils';
import { mutateOnboardingDone } from 'src/services/userServices';
import { REWARDS_ENROLLMENT_ROUTES } from 'src/routers/routes';
import { REWARDS_ENROLLMENT_TRACKER } from '../types';
import styles from './styles.module.scss';

function EnrollmentCompleteScreen() {
    const { replace } = useRouter();
    const { mutate: postOnboardingDone } = mutateOnboardingDone();
    const { analyticsCustomEvent } = useEventsReporter();
    const { userInfo } = useUserInfo();

    const [
        { completedEnrollmentSteps, skippedEnrollmentSteps },
        setEnrollmentSteps,
    ] = useLocalStorage<REWARDS_ENROLLMENT_TRACKER>(
        LOCAL_STORAGE_KEYS.ENROLLMENT_TRACKER,
        {
            completedEnrollmentSteps: {},
            skippedEnrollmentSteps: {},
        }
    );
    const handleEnrollmentComplete = () => {
        setEnrollmentSteps({
            skippedEnrollmentSteps: {
                ...skippedEnrollmentSteps,
            },
            completedEnrollmentSteps: {
                ...completedEnrollmentSteps,
                [REWARDS_ENROLLMENT_ROUTES.finish]: true,
            },
        });
        if (Object.keys(skippedEnrollmentSteps).length === 0) {
            postOnboardingDone();
        }
        replace(ROUTES.rewardsWallet);
        analyticsCustomEvent(eventMetricsMap.easy_rewards_enroll, {
            click_text: 'all set',
            element_location: 'enrolment confirmation',
            event_rewards_number: userInfo?.rewardsNumber || '',
        });
    };

    useLayoutEffect(() => {
        if (completedEnrollmentSteps[REWARDS_ENROLLMENT_ROUTES.finish]) {
            handleEnrollmentComplete();
        }
    }, []);

    useEffect(() => {
        const exitTimeout = setTimeout(() => {
            handleEnrollmentComplete();
        }, 5000);
        return () => clearTimeout(exitTimeout);
    }, []);

    return (
        <div className={styles.container}>
            <div>
                <Player
                    loop
                    autoplay
                    src={require('../../../assets/lotties/rewards-enrollment/celebration.json')}
                />
                <Heading type="h3" size={30}>
                    You're{' '}
                    <Body
                        display="inline"
                        color="textPurple"
                        weight="bold"
                        size={26}
                    >
                        ready to earn!
                    </Body>
                </Heading>
                <p>
                    Check out your dashboard to see the latest offers, manage
                    your bonus points categories, tally those points and start
                    saving big.
                </p>
            </div>
        </div>
    );
}

export default EnrollmentCompleteScreen;
