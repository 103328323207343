/* eslint-disable max-len */
/* eslint-disable camelcase */

// ----Rewards Enrollment---- //

export const nouns_rewards_enrollment_top_right_button = 'Skip';
export const nouns_rewards_enrollment_bottom_right_button = 'All set';

// Rewards enrollment welcome Start

export const page_rewards_enrollment_welcome_center_text = 'Introducing';

export const page_rewards_enrollment_welcome_carousel1_title_part1_bold =
    '1 point per $1';
export const page_rewards_enrollment_welcome_carousel1_title_part2 =
    ' in store & online';

export const page_rewards_enrollment_welcome_carousel2_title_part1 = 'Even ';
export const page_rewards_enrollment_welcome_carousel2_title_part2_bold =
    'more points';
export const page_rewards_enrollment_welcome_carousel2_title_part3 =
    ' on what you buy most';

export const page_rewards_enrollment_welcome_carousel3_title_part1_bold =
    'Extras';
export const page_rewards_enrollment_welcome_carousel3_title_part2 =
    ' just for members';

// Rewards enrollment welcome End

// Personalize points Start

export const page_personalize_points_card1_title = 'Myself';
export const page_personalize_points_card1_body =
    'I work for myself, an employer or help support a household.';

export const page_personalize_points_card2_title = 'Business';
export const page_personalize_points_card2_body =
    'I own or help run a small business, community resource or organization.';

export const page_personalize_points_card3_title = 'School';
export const page_personalize_points_card3_body =
    'I work at a school, university, learning or enrichment facility.';

export const page_personalize_points_title_part1 = "Lets's ";
export const page_personalize_points_title_part2_bold = 'personalize';
export const page_personalize_points_title_part3 = ' how you earn points';

export const page_personalize_points_subtitle =
    'Tell us more about you and who you shop for most.';

// Personalize points End

// Favorite select Start

export const page_favorite_selection_title_part1 = 'Even ';
export const page_favorite_selection_title_part2_bold = 'more points';
export const page_favorite_selection_title_part3 = ' on what you buy most';

export const page_favorite_selection_subtitle_part1 = 'Start by choosing ';
export const page_favorite_selection_subtitle_part2_bold = 'categories.';
export const page_favorite_selection_subtitle_part3 =
    ' You can change them anytime and as often as you like.';

export const page_favorite_selection_no_category_selected_message =
    'Choose from the categories above';

export const page_favorite_selection_bottom_right_button = 'Confirm';

// Favorite select End

// Terms and condition mock data

export const TERMS_TITLE = 'Easy Rewards Terms and Conditions';

export const TERMS_TEXT_1 =
    'PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY. THESE TERMS AND CONDITIONS ("T&C") MAY HAVE CHANGED SINCE YOUR LAST VISIT TO THE SITE. BY USING THE SITE, YOU INDICATE YOUR ACCEPTANCE OF THESE T&C. IF YOU DO NOT ACCEPT THESE T&C, THEN DO NOT USE THE SITE.';

export const TERMS_TEXT_2 =
    'Staples.com ("Site") is owned by Staples, Inc. ("Staples") and is currently provided free of charge to users who agree to abide by these T&C. Staples reserves the right to change the nature of this relationship at any time, and to revise these T&C from time to time as Staples sees fit. Users who violate these T&C will have their access canceled and they may permanently be banned from using the Site. Notices to you (including notices of changes to these T&C) may be made via posting to the Site or by e-mail (including in each case via links), or by regular mail, or as otherwise agreed between you and Staples. Users should check these T&C periodically. By using the Site after we post changes to these T&C, you agree to accept those changes, whether or not you actually reviewed them. Staples.com ("Site") is owned by Staples, Inc. ("Staples") and is currently provided free of charge to users who agree to abide by these T&C. Staples reserves the right to change the nature of this relationship at any time, and to revise these T&C from time to time as Staples sees fit. Users who violate these T&C will have their access canceled and they may permanently be banned from using the Site. Notices to you (including notices of changes to these T&C) may be made via posting to the Site or by e-mail (including in each case via links), or by regular mail, or as otherwise agreed between you and Staples. Users should check these T&C periodically. By using the Site after we post changes to these T&C, you agree to accept those changes, whether or not you actually reviewed them.';

//
export const LOGIN_CREATE_NEW_USER = {
    BUSINESS: 'Business',
    CREATE_ACCOUNT: 'Create account',
    AGREE: 'I agree to the',
    TERM_CONDITION: 'Terms and Conditions',
    PRIVACY_POLICY: ' Privacy Notice',
    AND: 'and',
    CALIFORNIA: 'California Notice',
};

// -------------------------- //
