/* eslint-disable max-len */
// TODO pull all common types and move them to types.d.ts file
import { AxiosResponse } from 'axios';
import {
    UseMutateAsyncFunction,
    useMutation,
    UseMutationOptions,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query';
import ROUTES from 'src/lib/routes';
import axiosInstance from 'src/lib/api-client/custom-axios';
import { NO_REFETCH } from '../lib/utils/react-query-utils';
import {
    rewardsGetFavoritesOffersURL,
    rewardsManageFavoriteOffersURL,
    rewardsGetLoyaltyOffersURL,
    rewardsPostLoyaltyAccountURL,
    pointHistoryURL,
    rewardsEnrollURL,
    loyaltyRecyclingOfferURL,
    rewardsGetFeaturedLoyaltyOffersURL,
    rewardsActivateLoyaltyOffersURL,
} from './urls';
import queryClient from './queryClient';

export type FavoriteOffersList = {
    id: string;
    name: string;
    startdate: Date | null;
    enddate: Date | null;
    digitaldescription: string | null;
    digitalviewimage: string | null;
    subscribed: boolean;
    subscriptionid: string | null;
    recommended: boolean;
    offerdisclaimer: string | null;
    alternativedescription: string | null;
    imagepng: string | null;
    includedlist: string | null;
    subscriptionenddate: string | null;
}[];
export interface FavoriteOffers {
    favorites: FavoriteOffersList;
    status: string;
    message: string;
    issuccess: boolean;
}
type GetFavoriteOffersConfig =
    | Omit<
          UseQueryOptions<
              FavoriteOffersList,
              unknown,
              FavoriteOffersList,
              string[]
          >,
          'queryKey' | 'queryFn'
      >
    | undefined;
export function getFavoriteOffers(
    config?: GetFavoriteOffersConfig
): UseQueryResult<FavoriteOffersList> {
    const queryFunc = async (): Promise<FavoriteOffersList> =>
        (await axiosInstance.get<FavoriteOffers>(rewardsGetFavoritesOffersURL))
            .data?.favorites;
    return useQuery(['getFavoriteOffers'], queryFunc, config);
}

export interface LoyaltyOffer {
    name: string;
    offerdescription: string;
    offerdisclaimer: string;
    imagepng: string | null;
    iconname: string;
    destinationUrl:string;
    mode: string;
    type: string;
    channel: string;
    enddate: string;
    digitaldescription: string | null;
    digitalviewimage: string;
    activated: boolean;
    offertype: string;
    offerid: string;
    isfeatured: boolean;
    multiuse: boolean;
    continuitytransactiondesc: string;
    continuitytransactiontotal: string;
    countinuitytransactioncurrent: string;
    ranking: number;
}
export type LoyaltyOfferResponse = {
    offset: number;
    limit: number;
    total: number;
    offers: LoyaltyOffer[];
};
interface GetAllLoyaltyOffersParams {
    offerType?:
        | 'MASS'
        | 'TARGETED'
        | 'RECYCLING'
        | 'EVERGREEN-RECYCLING'
        | 'MASS/TARGETED'
        | 'MASS/TARGETED/RECYCLING'
        | 'MASS/TARGETED/RECYCLING/EVERGREEN-RECYCLING';
    config?:
        | Omit<
              UseQueryOptions<
                  LoyaltyOfferResponse,
                  unknown,
                  LoyaltyOfferResponse,
                  any[]
              >,
              'queryKey' | 'queryFn'
          >
        | undefined;
}
export function getLoyaltyOffers({
    offerType,
    config,
}: GetAllLoyaltyOffersParams): UseQueryResult<LoyaltyOfferResponse> {
    const queryFunc = async (): Promise<LoyaltyOfferResponse> =>
        (
            await axiosInstance.get(
                `${rewardsGetLoyaltyOffersURL}?offerType=${offerType}/RECYCLING`
            )
        ).data;
    return useQuery(['getLoyaltyOffers', offerType], queryFunc, config);
}

export function getFeaturedLoyaltyOffers({
    config,
}: GetAllLoyaltyOffersParams): UseQueryResult<LoyaltyOfferResponse> {
    const queryFunc = async (): Promise<LoyaltyOfferResponse> =>
        (await axiosInstance.get(`${rewardsGetFeaturedLoyaltyOffersURL}`)).data;
    return useQuery(['getLoyaltyOffers', 'FEATURED'], queryFunc, config);
}

interface UpdateLoyaltyOfferCacheParams {
    offerType: 'MASS' | 'TARGETED' | 'FEATURED';
    updater:
        | LoyaltyOfferResponse
        | ((oldData: LoyaltyOfferResponse | undefined) => LoyaltyOfferResponse);
}
export function updateLoyaltyOfferCache({
    offerType,
    updater,
}: UpdateLoyaltyOfferCacheParams) {
    queryClient.setQueryData(['getLoyaltyOffers', offerType], updater);
}

type MutateFavoriteOffersConfig =
    | Omit<
          UseMutationOptions<
              AxiosResponse<any>,
              unknown,
              { subscribeIdList: string[]; unsubscribeIdList: string[] },
              unknown
          >,
          'mutationFn'
      >
    | undefined;
export function mutateFavoriteOffers(config?: MutateFavoriteOffersConfig) {
    const mutationFunc = ({
        subscribeIdList,
        unsubscribeIdList,
    }: {
        subscribeIdList: string[];
        unsubscribeIdList: string[];
    }) =>
        axiosInstance.post(rewardsManageFavoriteOffersURL, {
            SubscribeFavoriteOfferIds: subscribeIdList,
            UnSubscribeFavoriteOfferIds: unsubscribeIdList,
        });
    return useMutation(mutationFunc, config);
}

interface LoyaltyAccountDetailsResponse {
    accounts: [
        {
            rewardsnumber: number | null;
            rewardsnumberstr: string | null;
            rewardstiercode: string;
            rewardstierdescription: string;
            status: string;
            membersince: string;
            contactinfo: {
                firstname: string | null;
                lastname: string | null;
                address: {
                    addid: string | null;
                    address1: string | null;
                    address2: null;
                    city: string | null;
                    countrycode: string | null;
                    statecode: string | null;
                    zipcode: string | null;
                };
                phonenumber: number | null;
                emailid: string | null;
            };
            termsandconditionaccepted: 'Y' | 'N';
            walletinfo: {
                status: string | null;
                state: string | null;
                walletfetchstatus: string | null;
                balances: {
                    current: number;
                    usable: number;
                    locked: number;
                    lifetime: number;
                    lifetimespend: number;
                    lifetimespendvalue: number;
                    available: number;
                    refundable: number;
                    usablevalue: number;
                };
            };
        }
    ];
}
type GetLoyaltyAccountDetails =
    | Omit<
          UseQueryOptions<
              LoyaltyAccountDetailsResponse,
              unknown,
              LoyaltyAccountDetailsResponse,
              (string | undefined | null)[]
          >,
          'queryKey' | 'queryFn'
      >
    | undefined;
export function getLoyaltyAccountDetails({
    rewardsNumber,
    config,
}: {
    rewardsNumber?: string | undefined | null;
    config?: GetLoyaltyAccountDetails;
}): UseQueryResult<LoyaltyAccountDetailsResponse> {
    const queryFunc = async (): Promise<LoyaltyAccountDetailsResponse> =>
        (
            await axiosInstance.post(rewardsPostLoyaltyAccountURL, {
                identifierType: 'REWARDS',
                accountIdentifier: rewardsNumber,
            })
        ).data;
    return useQuery(
        ['getLoyaltyAccountDetails', rewardsNumber],
        queryFunc,
        config
    );
}

interface EnrollInRewardsRequestBody {
    FirstName: string;
    LastName: string;
    PhoneNumber: string;
    EmailId: string;
}

type EnrollInRewardsConfig =
    | Omit<
          UseMutationOptions<
              AxiosResponse<any>,
              unknown,
              EnrollInRewardsRequestBody,
              unknown
          >,
          'mutationFn'
      >
    | undefined;

export function enrollInRewards(config?: EnrollInRewardsConfig) {
    const mutationFunc = (userData: EnrollInRewardsRequestBody) =>
        axiosInstance.post<any>(rewardsEnrollURL, userData);
    return useMutation(mutationFunc, config);
}

export type PointHistory = [
    {
        trasactioncount: number;
        totalpoints: number;
    }
];
type PointHistoryConfig =
    | Omit<
          UseQueryOptions<PointHistory, unknown, PointHistory, string[]>,
          'queryKey' | 'queryFn'
      >
    | undefined;
export function getPointHistory({
    rewardsNumber,
    config,
}: {
    rewardsNumber?: string | undefined | null;
    config?: PointHistoryConfig;
    data?: string;
}): UseQueryResult<PointHistory> {
    console.log({ data: window.location.pathname });
    const queryFunc = async (): Promise<PointHistory> =>
        (await axiosInstance.get<PointHistory>(pointHistoryURL)).data;
    return useQuery(['getPointHistory'], queryFunc, config);
}

type LoyaltyRewardPointsConfig =
    | Omit<
          UseQueryOptions<RewardPoints, unknown, RewardPoints, string[]>,
          'queryFn'
      >
    | undefined;

export type RewardPoints = [
    {
        results: [
            {
                maxnumberofcartridgesrecycled: string;
                points: string;
            }
        ];
    }
];
export function getLoyaltyRewardPoints(config?: LoyaltyRewardPointsConfig) {
    const queryFunc = async (): Promise<RewardPoints> =>
        (await axiosInstance.get<RewardPoints>(loyaltyRecyclingOfferURL)).data;
    return useQuery(['getLoyaltyRewardPoints'], queryFunc, config);
}

export interface ActivateOffer {
    message: string;
    success: boolean;
    activationid: string;
}
export type ActivateOfferResponse = [ActivateOffer];
export type ActivateOfferRequestBody = string[];
type ActivateOfferConfig =
    | Omit<
          UseMutationOptions<
              AxiosResponse<any>,
              unknown,
              ActivateOfferRequestBody,
              unknown
          >,
          'mutationFn'
      >
    | undefined;
export type ActivateOfferMutateAsyncFunc = UseMutateAsyncFunction<
    AxiosResponse<any>,
    unknown,
    ActivateOfferRequestBody,
    unknown
>;
export function activateOffer(config?: ActivateOfferConfig) {
    const mutationFunc = (offerIds: ActivateOfferRequestBody) =>
        axiosInstance.post<ActivateOfferResponse>(
            rewardsActivateLoyaltyOffersURL,
            {
                CampaignIds: [...offerIds],
            }
        );
    return useMutation(mutationFunc, config);
}
