/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { atRecommendation } from 'src/lib/api-client/adobe';
import { NO_REFETCH } from 'src/lib/utils/react-query-utils';
import {
    Card,
    CardContent,
    makeStyles,
    Typography,
    Button,
} from '@material-ui/core';
import useDragScroll from 'src/hooks/useDragScroll';
import { useIsMobileWidth } from 'src/hooks/useWidth';
import ScrollableRow from 'src/components/scrollable-row';
import useStoreData from 'src/hooks/useStoreData';
import ProductStarRating from 'src/components/product-star-rating';

const useStyles = makeStyles({
    topDealTitle: {
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '22px',
        color: '#343434',
        textTransform: 'uppercase',
    },
    scrollLocation: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        marginLeft: '4px',
        color: '#585858',
    },
    cardItem: {
        borderRadius: '15px',
        boxShadow: 'none',
        color: '#343434',
    },
    productDetail: {
        width: '125px',
        fontSize: '13px',
        margin: '10px 0 0 0',
        color: '#343434',
        lineHeight: '16px',
        overflowWrap: 'anywhere',
        display: '-webkit-box',
        maxWidth: '200px',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        fontWeight: 400,
        marginBottom: '7px',
    },
    buttonAdd: {
        width: '85px',
        height: '30px',
        color: '#FFFFFF',
        fontWeight: 700,
        boxshadow: '0px 2px 12px rgba(0, 0, 0, 0.15)',
        borderRadius: '28px',
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    productImages: {
        width: '115px',
        marginTop: '-10px',
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    imageMainCardContainer: {
        padding: '5px !important',
        position: 'relative',
    },
    shopDealContainer: {
        '& #top-deals-products': {
            padding: '0 !important',
        },
    },
    seeAllBtnAlign: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#086DD2',
    },
    priceContainer: {
        display: 'flex',
        gap: 4,
        margin: '6px 0px 10px',
    },
    originalPrice: {
        fontSize: '10px',
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '0px',
        color: '#757575',
        position: 'relative',
    },
    priceAlign: {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
        letterSpacing: '0px',
        color: '#343434',
    },
    strikethrough_line: {
        width: '100%',
        height: 0,
        margin: 0,
        outline: 'none',
        position: 'absolute',
        top: '9px',
        left: '0',
        transform: 'rotate(8deg)',
        border: 'none',
        borderTop: '1px solid #757575',
    },
    pickupMessageLocationAlign: {
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '12px',
        letterSpacing: '-0.5px',
    },
    oneOurPickUpContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        alignItems: 'center',
        height: '24px',
        marginBottom: '15px',
    },
    textCssClassName: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '12px',
        color: '#000000',
    },
    discountPercentagesAlign: {
        background: '#E0CCD9',
        borderRadius: '16px 1px 16px 0px',
        width: '53px',
        height: '28px',
        color: '#823267',
        fontWeight: 500,
        padding: '5px',
        textAlign: 'center',
        zIndex: 1,
        fontStyle: 'italic',
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    placeHolder: {
        width: '53px',
        height: '28px',
    },
});

interface IProduct {
    price: string;
    image: string;
    title: string;
    ratingUrl: string;
    url: string;
    handleNavigationShopDeatls: (url: string) => void;
    rating: number;
    originalPrice: string;
    pickupMessage: string;
    location: string | undefined;
    ratingCount: number;
    itemId: string;
    itemAddedId: boolean;
    cartStatus: boolean;
    addToCart: (data: any) => void;
    isOutOfStock: boolean;
    tabindex?: number | undefined;
}
interface IShopTopDeals {
    handleNavigationShopDeatls: (url: string) => void;
    addItemToCart: (data: any) => void;
    cartStatus: boolean;
}

const Product = ({
    price,
    image,
    title,
    ratingUrl,
    url,
    handleNavigationShopDeatls,
    rating,
    originalPrice,
    pickupMessage,
    location,
    ratingCount,
    itemId,
    itemAddedId,
    cartStatus,
    addToCart,
    isOutOfStock,
    tabindex,
}: IProduct) => {
    const classes = useStyles();

    const handleItemClick = (e: any) => {
        e?.preventDefault();
        handleNavigationShopDeatls(url);
    };

    const handleItemRatingClick = (e: any) => {
        e?.preventDefault();
        handleNavigationShopDeatls(ratingUrl);
    };
    const getDiscountPrice = () => {
        if (price && originalPrice) {
            const onlyPriceWithNumber = price?.replace('$', '');
            const onlyOriPriceWithNumber = originalPrice?.replace('$', '');
            const afterDiscount =
                (Number(onlyPriceWithNumber) / Number(onlyOriPriceWithNumber)) *
                100;
            return afterDiscount?.toFixed(0);
        }
        return null;
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: '5px',
                    padding: '5px',
                    alignItems: 'center',
                }}
                tabIndex={(tabindex && tabindex <=2) ? tabindex + 3 : 0 }
                aria-label={`${pickupMessage} in ${location}`}
            >
                <Card
                    variant="elevation"
                    className={classes.cardItem}
                    onClick={handleItemClick}
                >
                    <CardContent className={classes.imageMainCardContainer}>
                        <div className={classes.imageContainer}>
                            <div
                                className={
                                    price && originalPrice
                                        ? classes.discountPercentagesAlign
                                        : classes.placeHolder
                                }
                            >
                                {price && originalPrice
                                    ? `${getDiscountPrice()}% off`
                                    : null}
                            </div>
                            <img
                                className={classes.productImages}
                                src={image}
                                alt="first card bg"
                            />
                        </div>
                    </CardContent>
                </Card>
                <Typography className={classes.productDetail}>
                    {title}
                </Typography>
                <ProductStarRating
                    onClick={handleItemRatingClick}
                    totalReviews={ratingCount}
                    ratingValue={rating}
                    textCssClassName={classes.textCssClassName}
                />
                <div className={classes.priceContainer}>
                    <Typography className={classes.priceAlign} aria-label={`Current price is ${price}`}>
                        {price}
                    </Typography>
                    {originalPrice ? (
                        <Typography className={classes.originalPrice} aria-label={`Regular price ${originalPrice}`}>
                            {originalPrice}
                            <hr
                                aria-hidden="true"
                                className={classes.strikethrough_line}
                            />
                        </Typography>
                    ) : null}
                </div>
                <div className={classes.oneOurPickUpContainer}>
                    {pickupMessage ? (
                        <>
                            <Typography
                                className={classes.pickupMessageLocationAlign}
                                style={{ color: '#343434' }}
                            >
                                {pickupMessage} &nbsp;
                                <span>in</span>
                            </Typography>
                            <Typography
                                className={classes.pickupMessageLocationAlign}
                            >
                                {location}
                            </Typography>
                        </>
                    ) : null}
                </div>

                <div>
                    <Button
                        id={`add-${title}-button`}
                        variant="contained"
                        className={classes.buttonAdd}
                        onClick={() => addToCart(itemId)}
                        style={{
                            background:
                                itemAddedId && cartStatus
                                    ? '#000000'
                                    : '#CC0000',
                        }}
                    >
                        {itemAddedId && cartStatus && !isOutOfStock ? "Added" : "Add"}
                    </Button>
                </div>
            </div>
        </>
    );
};

const ShopTopDeals = ({
    handleNavigationShopDeatls,
    addItemToCart,
    cartStatus,
}: IShopTopDeals) => {
    const classes = useStyles();
    const scrollProps = useDragScroll();
    const { mainStore } = useStoreData();
    const seeAllLink = `/deals/Staples-Daily-Deals/SEGDD0000001`;

    const [selectedItem, setSelctedItem] = useState('');

    const { data: topDealsItems } = useQuery(
        ['topDealsItems', mainStore?.storeNumber],
        () =>
            atRecommendation(
                'CM_TOPDEALS_DOTCOM',
                mainStore?.storeNumber,
                mainStore?.address?.postal_code
            ),
        {
            enabled: !!mainStore?.storeNumber,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: Infinity,
            cacheTime: Infinity,
        }
    );
    const addToCart = (itemId: string) => {
        const dataBody = {
            items: [
                {
                    itemId,
                    qty: 1,
                    shippingInfo: {
                        deliveryType: 'STA',
                        pickStoreNumber: mainStore?.storeNumber,
                    },
                },
            ],
            yourStoreNo: mainStore?.storeNumber,
        };
        setSelctedItem(itemId);
        addItemToCart(dataBody);
    };
    const handleSeeLink = () => {
        handleNavigationShopDeatls(seeAllLink);
    };

    return (
        <>
        {topDealsItems?.length ? 
            <div className={classes.shopDealContainer}>
                <div className={classes.titleContainer}>
                    <Typography className={classes.topDealTitle} aria-label='Todays Top Deals' tabIndex={1} >
                        Todays Top Deals
                    </Typography>
                    <Typography
                        className={classes.seeAllBtnAlign}
                        onClick={handleSeeLink}
                        aria-label='See all of todays top deals' tabIndex={2}
                        role='link'
                    >
                        See all
                    </Typography>
                </div>
                <ScrollableRow id="top-deals-products" scrollProps={scrollProps}>
                    <div style={{ display: 'flex' }}>
                        {topDealsItems?.map((p: any, index:number) => (
                            <Product
                                tabindex={index}
                                price={p.price}
                                image={p.image}
                                title={p.title}
                                ratingUrl={p.ratingUrl}
                                url={p.url}
                                handleNavigationShopDeatls={
                                    handleNavigationShopDeatls
                                }
                                itemId={p.itemId}
                                rating={p.rating}
                                originalPrice={p.originalPrice}
                                pickupMessage={p.pickupMessage}
                                location={mainStore?.address?.city}
                                ratingCount={p.ratingCount}
                                itemAddedId={selectedItem === p.itemId}
                                cartStatus={cartStatus}
                                addToCart={addToCart}
                                isOutOfStock={p.isOutOfStock}
                            />
                        ))}
                    </div>
                </ScrollableRow>
            </div>
        : null }
    </>
    )
};

export default ShopTopDeals;
