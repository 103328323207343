import React from 'react'
import {
    makeStyles,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@material-ui/core';
import { openInNewTab } from 'src/lib/utils/nav-utils';
import { useTabsUIStateContext } from 'src/lib/contexts/tabs-ui-state-context';
import { Heading, Button } from './UI';

const useStyles = makeStyles((theme) => ({
paper: {
    borderRadius: '1rem',
    margin: '1rem',
    overflow: 'visible',
},
contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    margin: '1rem 0 0.8rem',
},
printerLogo: {
    height: '6rem',
    marginBottom: '0.9rem',
},
dialogTitle: {
    fontSize: '24px !important',
    fontWeight: 'bold',
    color: '#343434',
    textAlign: 'center',
    [theme.breakpoints.down(370)]: {
        fontSize: '20px !important',
    },
},
primaryButton: {
    width: '100%',
    textTransform: 'none',
    margin: ' 0.2rem 0.4rem',
},
smallLink: {
    fontSize: '.85rem',
    textDecoration: 'underline',
},
}));

export default function OpenPrintExternalDialog() {
    const classes = useStyles();
    const { openPrintDrawer, setOpenPrintDrawer } = useTabsUIStateContext()

  return (
    <Dialog
            open={openPrintDrawer.show}
            classes={{
                paper: classes.paper,
            }}
            onClose={() => setOpenPrintDrawer({ src: '', show: !openPrintDrawer.show})}
            scroll="body"
        >
            <div className={classes.contentWrapper}>
                <img
                    className={classes.printerLogo}
                    src={require('../assets/icons/printerIcon.svg')}
                    alt="Printer Icon"
                />
                <DialogTitle id="print-dialog-title" style={{padding: '0rem'}}>
                    <Heading className={classes.dialogTitle} type='h2'>
                        Continue to browser
                    </Heading>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        You'll leave the app experience to take advantage of our
                        Print & Marketing Services site.
                    </Typography>
                <DialogActions>
                    <Button
                        variant="contained-rounded"
                        onClick={()=> {
                            openInNewTab(openPrintDrawer.src)
                            setOpenPrintDrawer({ src: '', show: !openPrintDrawer.show})
                        }}
                        className={
                            classes.primaryButton
                        }
                        fontWeight="normal"
                        bgColor="bgRed"
                    >
                        Let's go
                    </Button>
                </DialogActions>
                    <Typography className={classes.smallLink} onClick={() => setOpenPrintDrawer({ src: '', show: !openPrintDrawer.show })}>
                        Stay in the app
                    </Typography>
                </DialogContent>
            </div>
        </Dialog>
  )
}

