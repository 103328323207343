/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { ListQueryResult } from 'src/services/types';
import DraggableReorderableVariableHeightList from 'src/components/draggable-reorderable-variable-height-list';
import { updateItemSequence } from 'src/lib/utils/draggable-list-utils';
import { getItemFromLocalStorage } from 'src/hooks/useLocalStorage';
import {
    EditableDraggableItem,
    ReadOnlyDraggableItem,
    EditableItem,
    ReadOnlyItem,
    ListInteractionType,
} from './list-items';

export interface ListsProps {
    lists: ListQueryResult;
    beginListEditing: (
        listId: number,
        listName: string,
        listDescription?: string
    ) => void;
    beginListDeleting: (listId: number, listName: string) => void;
    updateListSequence: (listId: number, sequence: number) => void;
    editMode: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function UserLists({
    beginListEditing,
    beginListDeleting,
    updateListSequence,
    lists,
    editMode,
}: ListsProps) {
    const sharedLists = getItemFromLocalStorage('shared_list_ids', []);
    const mappedLists = lists.map((item) => {
        if (sharedLists.includes(`${item.id}`)) {
            return {
                ...item,
                type: 'shared-by-user',
            };
        }
        return {
            ...item,
            type: 'user-list',
        };
    });
    const mutateDraggedItem = (
        originalItems: ListQueryResult,
        destinationIndex: number
    ) =>
        updateItemSequence(
            originalItems,
            destinationIndex,
            (item: any, newSequenceNumber: number) =>
                updateListSequence(item.id, newSequenceNumber)
        );
    function itemInteractionHandler(
        item: any,
        interactionType: ListInteractionType
    ) {
        if (interactionType === 'edit-item' && beginListEditing) {
            beginListEditing(item.id, item.productListName, item.description);
        } else if (interactionType === 'delete-item' && beginListDeleting) {
            beginListDeleting(item.id, item.productListName);
        }
    }

    return (
        <DraggableReorderableVariableHeightList
            items={mappedLists}
            disabled={!editMode}
            itemProps={{ itemInteractionHandler }}
            Item={editMode ? EditableItem : ReadOnlyItem}
            mutateDraggedItem={mutateDraggedItem}
            DraggableItem={
                editMode ? EditableDraggableItem : ReadOnlyDraggableItem
            }
            isUserList
        />
    );
}

export default UserLists;
