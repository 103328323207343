import React, { useState, useEffect } from 'react';
import { areEqual, FixedSizeList } from 'react-window';
import {
  DragDropContext,
  Droppable,
  DropResult,
  Draggable,
} from 'react-beautiful-dnd';
import { reorder } from 'src/lib/utils/draggable-list-utils';

// Recommended react-window performance optimisation: memoize the row render function
export function buildDraggableItem({
  disabled,
  Item,
}: {
  disabled: boolean;
  Item: any;
}) {
  return React.memo((props: any) => {
    const { data: itemData, index, style } = props;
    const item = itemData.items[index];

    return (
      <Draggable
        draggableId={item.id}
        index={index}
        key={item.id}
        isDragDisabled={disabled}
      >
        {(provided: any) => (
          <Item
            index={index}
            provided={provided}
            item={item}
            style={style}
            itemProps={itemData.itemProps}
          />
        )}
      </Draggable>
    );
  }, areEqual);
}

function DraggableReorderableList({
  disabled,
  totalHeight,
  rowHeight,
  items: itemsFromProps,
  Item,
  DraggableItem,
  itemProps,
  mutateDraggedItem,
  shouldUpdateItemsOnProps,
  sizeListStyle, // ?: React.CSSProperties | undefined
}: any) {
  const [items, setItems] = useState<any>(itemsFromProps);
  useEffect(() => {
    if (
      shouldUpdateItemsOnProps &&
      shouldUpdateItemsOnProps(items, itemsFromProps)
    ) {
      // only set new items if we're
      setItems(itemsFromProps);
    }
  }, [itemsFromProps]);
  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }
    if (result.source.index === result.destination.index) {
      return;
    }

    let newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    if (mutateDraggedItem) {
      newItems = mutateDraggedItem(newItems, result.destination.index);
    }
    setItems(newItems);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div>
        <Droppable
          mode="virtual"
          droppableId="droppable"
          isDropDisabled={disabled}
          renderClone={(
            provided: any,
            snapshot: { isDragging: any },
            rubric: { source: { index: string | number } }
          ) => (
            <Item
              provided={provided}
              isDragging={snapshot.isDragging}
              item={items[rubric.source.index]}
              itemProps={itemProps}
            />
          )}
        >
          {(provided: { innerRef: React.Ref<any> | undefined }) => (
            <FixedSizeList
              style={sizeListStyle}
              height={totalHeight}
              itemSize={rowHeight}
              itemCount={items.length}
              width="100%"
              outerRef={provided.innerRef}
              itemData={{ items, itemProps }}
            >
              {DraggableItem}
            </FixedSizeList>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
}

export default DraggableReorderableList;
