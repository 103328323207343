/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useCallback, useMemo, useState, useLayoutEffect, useEffect } from 'react';
import { Dialog } from '@material-ui/core';
import SelectableCard from 'src/components/SelectableCard';
import { Button, Heading, Body } from 'src/components/UI';
import { mergeClasses } from 'src/stylesheets/utils';
import Pill from 'src/components/UI/Pill';
import Carousel from 'src/components/Carousel';
import COLORS from 'src/lib/colors';
import {
    FavoriteOffersList,
    getFavoriteOffers,
    mutateFavoriteOffers,
} from 'src/services/easyRewardsServices';
import { page_favorite_selection_no_category_selected_message } from 'src/utils/stringConstants';
import { REWARDS_ENROLLMENT_ROUTES } from 'src/routers/routes';
import {
    eventMetricsMap,
    useEventsReporter,
    useRouter,
    useLocalStorage,
    LOCAL_STORAGE_KEYS,
    useUserInfo,
} from 'src/hooks';
import { IconClosePlain, IconInfoAlert } from 'src/components/Icons';
import { InfoDrawer } from 'src/components/EasyRewards';
import AppLink from 'src/components/link';
import ROUTES from 'src/lib/routes';
import FavoriteSelectionHeader from './FavoriteSelectionHeader';
import FavoriteSelectionFooter from './FavoriteSelectionFooter';
import { REWARDS_ENROLLMENT_TRACKER } from '../types';
import styles from './styles.module.scss';

interface FavoriteSelectionScreenProps {
    handleClose?: () => void;
}

interface FavoriteSelectionCarouselProps extends FavoriteSelectionScreenProps {
    variant: 'enrollment' | 'manage-favorites';
    selectionFromCoachSCreen?: boolean;
    fromEnrollmentScreen?: boolean;
}

export function FavoriteSelectionCarousel({
    variant,
    handleClose,
    selectionFromCoachSCreen,
    fromEnrollmentScreen,
}: FavoriteSelectionCarouselProps) {
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const { push } = useRouter();
    const { analyticsCustomEvent, analyticsScreenCustomNames } =
        useEventsReporter();
    const { userInfo } = useUserInfo();

    const [
        { completedEnrollmentSteps, skippedEnrollmentSteps },
        setEnrollmentSteps,
    ] = useLocalStorage<REWARDS_ENROLLMENT_TRACKER>(
        LOCAL_STORAGE_KEYS.ENROLLMENT_TRACKER,
        {
            completedEnrollmentSteps: {},
            skippedEnrollmentSteps: {},
        }
    );

    const [selectedCategories, setSelectedCategories] = useState<{
        [key: string]: FavoriteOffersList[number];
    }>({});
    const [isFavoriteSelectionDrawerOpen, setIsFavoriteSelectionDrawerOpen] =
        useState<boolean>(false);
    const toggleFavoriteSelectionDrawer = (value: boolean) =>
        setIsFavoriteSelectionDrawerOpen(value);

    const markCompletedAndSkipScreen = () => {
        delete skippedEnrollmentSteps[REWARDS_ENROLLMENT_ROUTES.favorites];
        setEnrollmentSteps({
            skippedEnrollmentSteps: {
                ...skippedEnrollmentSteps,
            },
            completedEnrollmentSteps: {
                ...completedEnrollmentSteps,
                [REWARDS_ENROLLMENT_ROUTES.favorites]: true,
            },
        });
        push(REWARDS_ENROLLMENT_ROUTES.peronalize);
    };

    const {
        data: offerList = [],
        isFetching,
        isSuccess,
        isError,
        refetch: refetchOffers,
    } = getFavoriteOffers({
        keepPreviousData: true,
        onSettled: (data) => {
            if (Object.keys(selectedCategories).length === 0) {
                setSelectedCategories(() => {
                    const newValues: typeof selectedCategories = {};
                    data?.forEach((offer) => {
                        if (offer.subscribed && !handleClose) {
                            markCompletedAndSkipScreen();
                        } else if (offer.subscribed || offer.recommended) {
                            newValues[offer.id] = offer;
                        }
                    });
                    return newValues;
                });
            }
        },
        select: (data) =>
            data.sort((offer1, offer2) => {
                if (
                    (offer1.subscribed || offer1.recommended) &&
                    !(offer2.subscribed || offer2.recommended)
                )
                    return -1;
                if (
                    !(offer1.subscribed || offer1.recommended) &&
                    (offer2.subscribed || offer2.recommended)
                )
                    return 1;
                return 0;
            }),
    });

    const {
        mutateAsync: updateFavoriteOffers,
        isLoading: isMutatingFavoriteOffers,
    } = mutateFavoriteOffers({
        onSuccess: (fvOffer: any) => {
            if (fvOffer?.data?.status === 'SUCCESS') {
                refetchOffers();
                if (handleClose) {
                    handleClose();
                } else {
                    markCompletedAndSkipScreen();
                }
            } else {
                setShowErrorDialog(true)
            }
        },
    });

    useEffect(() => {
        if (selectionFromCoachSCreen) {
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `app_easy rewards`,
                firebase_screen_class: `app_easy rewards`,
            });
        }
    }, [])

    const handleConfirm = useCallback(() => {
        setShowErrorDialog(false);
        if (fromEnrollmentScreen) {
            analyticsCustomEvent(eventMetricsMap.easy_rewards_enroll, {
                click_text: 'confirm',
                element_location: 'points categories',
                event_rewards_number: userInfo?.rewardsNumber || '',
            });
        } else {
            analyticsCustomEvent(eventMetricsMap.easy_rewards, {
                click_text: 'confirm',
                element_location: 'update points categories',
                event_rewards_number: userInfo?.rewardsNumber || '',
            });
        }
        // Offers already subscribed to (before the update call)
        const subscriptionIdMap: { [key: string]: string } = {};
        const subscribedIds = offerList
            .filter((offer) => offer.subscribed)
            .map((offer) => {
                subscriptionIdMap[offer.id] = offer.subscriptionid || '';
                return offer.id;
            });

        // Offers that users has selected but maybe not subscribed to
        const selectedIds = Object.keys(selectedCategories);

        // Gives us the new ids the user wants to subscribe to
        const newSubscribeIds = selectedIds.filter(
            (id) => !subscribedIds.includes(id)
        );

        // Filters out perviously subscribed ids which are no longer present in the new ones (we will unsubcribe to them)
        const newUnsubscribeIds = subscribedIds
            .filter((id) => !selectedIds.includes(id))
            .map((id) => subscriptionIdMap[id]);

        updateFavoriteOffers({
            subscribeIdList: newSubscribeIds,
            unsubscribeIdList: newUnsubscribeIds,
        }).catch(() => setShowErrorDialog(true));
        if (Object.keys?.(selectedCategories).length) {
            const selectedCategoriesName = Object.keys(selectedCategories)
                .map((category) =>
                    selectedCategories[category].name?.slice(0, 11)
                )
                .join(',');
            analyticsScreenCustomNames({
                user_point_categories: selectedCategoriesName,
            });
            if (selectionFromCoachSCreen) {
                analyticsCustomEvent(eventMetricsMap.screen_view, {
                    firebase_screen: `app_coachmarks no bpc selected`,
                    firebase_screen_class: `app_coachmarks no bpc selected`,
                });
            }
        }
    }, [selectedCategories]);

    const selectedCategoriesLength = Object.keys(selectedCategories).length;

    // Mapping favorite categories here
    const content = useMemo(() => {
        const mappedContent = [];
        for (let i = 0; i < offerList?.length; i += 4) {
            mappedContent.push(
                <div className={styles.cardGroupContainer}>
                    {offerList?.slice(i, i + 4).map((offer) => (
                        <SelectableCard
                            containerClassname={styles.cardContainer}
                            selected={!!selectedCategories[offer.id]}
                            onSelect={() => {
                                setSelectedCategories((currentValues) => {
                                    const newValues = { ...currentValues };
                                    if (offer.id in currentValues) {
                                        delete newValues[offer.id];
                                    } else if (selectedCategoriesLength < 3) {
                                        newValues[offer.id] = offer;
                                    }
                                    return newValues;
                                });
                            }}
                        >
                            <img
                                className={
                                    selectedCategories[offer.id]
                                        ? styles.cardImageSelected
                                        : styles.cardImage
                                }
                                src={offer.digitalviewimage || ''}
                                alt="category"
                            />
                            <div
                                className={mergeClasses(
                                    styles.flex,
                                    styles.flexCol,
                                    styles.alignCenter,
                                    styles.rowGap2
                                )}
                            >
                                <Heading
                                    textAlign="textCenter"
                                    weight="bold"
                                    type="h6"
                                    size={14}
                                    breakpoint={420}
                                >
                                    {offer.name}
                                </Heading>
                                <Body
                                    textAlign="textCenter"
                                    size={14}
                                    color="textBlack"
                                    weight="light"
                                    breakpoint={420}
                                >
                                    {offer.alternativedescription}
                                </Body>
                            </div>
                        </SelectableCard>
                    ))}
                </div>
            );
        }
        return mappedContent;
    }, [offerList, selectedCategoriesLength]);

    return (
        <>
            <div className={styles.selectionContainer}>
                <section className={styles.mb4}>
                    <div
                        className={mergeClasses(
                            styles.flex,
                            styles.flexCol,
                            styles.rowGap2,
                            styles.mb4
                        )}
                    >
                        <Heading
                            type="h3"
                            color="textDarkgray"
                            size={30}
                            weight="normal"
                            lineHeight={34}
                            breakpoint={420}
                        >
                            Earn
                            <b className={styles.textPurple}>
                                {' '}
                                2x or more points{' '}
                            </b>
                            on 3 categories
                        </Heading>
                        <Body
                            size={14}
                            color="textDarkgray"
                            weight="light"
                            className={styles.subheading}
                            breakpoint={420}
                        >
                            We've set you up with ones that'll maximize your
                            earnings, you can change them now or at any time.
                        </Body>
                        <Button
                            variant="text"
                            align="alignSelfStart"
                            color="textLinkBlue"
                            onClick={() => toggleFavoriteSelectionDrawer(true)}
                        >
                            What’s included in each category?
                        </Button>
                    </div>
                    {isError ? (
                        <section className={styles.warning}>
                            <div>
                                <IconInfoAlert />
                            </div>
                            <p className={styles.desText}>
                                We’re building your personalized bonus points
                                categories right now.
                            </p>
                            <p className={styles.desText}>
                                You can make your selections later on your Easy
                                Rewards dashboard.
                            </p>
                            <AppLink
                                underline="always"
                                onClick={() => {
                                    push(REWARDS_ENROLLMENT_ROUTES.peronalize);
                                    analyticsCustomEvent(
                                        eventMetricsMap.easy_rewards_enroll,
                                        {
                                            click_text: 'skip',
                                            element_location:
                                                'point categories',
                                        }
                                    );
                                }}
                            >
                                Skip for now
                            </AppLink>
                        </section>
                    ) : (
                        <>
                            <section className={styles.carouselSection}>
                                <Carousel
                                    animateHeight={false}
                                    activeColor={COLORS.purple}
                                    content={content}
                                    dots={Math.ceil(content.length)}
                                    scrollAnimation
                                />
                            </section>
                            <div
                                className={mergeClasses([
                                    styles.flex,
                                    styles.flexCol,
                                    styles.alignCenter,
                                ])}
                            >
                                <p>Selected ({selectedCategoriesLength}/3):</p>
                                {selectedCategoriesLength > 0 ? (
                                    <div
                                        className={mergeClasses(
                                            styles.flex,
                                            styles.flexRow,
                                            styles.justifyCenter,
                                            styles.colGap2,
                                            styles.rowGap2,
                                            styles.flexWrap
                                        )}
                                    >
                                        {Object.values(selectedCategories).map(
                                            (category) => (
                                                <Pill
                                                    className={
                                                        styles.categoryPill
                                                    }
                                                    key={category.id}
                                                >
                                                    {`${category.name.substring(
                                                        0,
                                                        13
                                                    )}${category.name.length >
                                                        13
                                                        ? '...'
                                                        : ''
                                                        }`}{' '}
                                                    <Button
                                                        variant="icon"
                                                        onClick={() => {
                                                            setSelectedCategories(
                                                                (
                                                                    currentValues
                                                                ) => {
                                                                    const newValues =
                                                                    {
                                                                        ...currentValues,
                                                                    };
                                                                    delete newValues[
                                                                        category
                                                                            .id
                                                                    ];
                                                                    return newValues;
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        <IconClosePlain />
                                                    </Button>
                                                </Pill>
                                            )
                                        )}
                                    </div>
                                ) : (
                                    <p className={styles.m0}>
                                        {
                                            page_favorite_selection_no_category_selected_message
                                        }
                                    </p>
                                )}
                            </div>
                        </>
                    )}
                </section>
                <FavoriteSelectionFooter
                    variant={variant}
                    selectedCategoriesLength={selectedCategoriesLength}
                    handleConfirm={handleConfirm}
                    isLoading={isMutatingFavoriteOffers}
                />
            </div>
            <InfoDrawer
                open={isFavoriteSelectionDrawerOpen}
                setIsOpen={toggleFavoriteSelectionDrawer}
                offerList={offerList as FavoriteOffersList}
            />
            <Dialog open={showErrorDialog} classes={{
                paperScrollPaper: styles.scrollPaper,
            }}>
                <div
                    className={mergeClasses(
                        styles.flex,
                        styles.flexCol,
                        styles.rowGap4,
                        styles.alignCenter
                    )}

                >
                    <div style={{ padding: '10px' }}>
                        <Heading type="h3" weight="bold" className={styles.headingAlign} size={16}>
                            We're unable to confirm your categories.
                        </Heading>
                        <Body className={styles.bodyTextAlign} size={14}>
                            Try confirming your categories again or skip for
                            now.
                        </Body>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        width: '100%', border: '1px solid grey',
                    }}>
                        <Button
                            bgColor="bgBlack"
                            onClick={() => {
                                handleClose?.();
                                setShowErrorDialog(false);
                                push(ROUTES.rewardsWallet);
                                analyticsCustomEvent(
                                    eventMetricsMap.easy_rewards_enroll,
                                    {
                                        click_text: 'skip',
                                        element_location:
                                            'point categories',
                                    }
                                );
                            }}
                            className={styles.btnAlign}
                        >
                            Skip
                        </Button>
                        <div style={{ borderLeft: '1px solid grey', height: '50px' }} />
                        <Button
                            bgColor="bgBlack"
                            onClick={handleConfirm}
                            className={styles.btnAlign}
                        >
                            Try again
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

function FavoriteSelectionScreen({
    handleClose,
}: FavoriteSelectionScreenProps) {
    const { push } = useRouter();

    const [{ completedEnrollmentSteps }] =
        useLocalStorage<REWARDS_ENROLLMENT_TRACKER>(
            LOCAL_STORAGE_KEYS.ENROLLMENT_TRACKER,
            {
                completedEnrollmentSteps: {},
                skippedEnrollmentSteps: {},
            }
        );

    useLayoutEffect(() => {
        if (completedEnrollmentSteps[REWARDS_ENROLLMENT_ROUTES.favorites]) {
            push(REWARDS_ENROLLMENT_ROUTES.peronalize);
        }
    }, []);

    return (
        <div className={styles.container}>
            <FavoriteSelectionCarousel
                variant="enrollment"
                handleClose={handleClose}
                fromEnrollmentScreen
            />
        </div>
    );
}

export default FavoriteSelectionScreen;
